var HelpDocument = (function (Utils, CKEDITOR) {

    var HelpDocument = function (link, options) {
        if (!link) {
            console.log('No help document link found.');
            return;
        }

        var defaults = {
            form: null,
            modes: {
                normal: {
                    toggleHtml: '<a class="btn btn-primary btn-sm"><i class="fas fa-book"></i> Help</a>'
                },
                preview: {
                    toggleHtml: '<a class="btn btn-primary"><i class="fas fa-book"></i> Preview Help</a>'
                }
            }
        };

        options = $.extend({}, defaults, options);

        var modeOptions = options.modes.normal;
        if (options.form) {
            modeOptions = options.modes.preview;
        }

        var $btn = $(modeOptions.toggleHtml);

        var $popover;
        $popover = $btn.popover({
            animation: false,
            container: 'body',
            content: '<div class="help_document__section">' + Utils.getSpinnerHtml() + '</div>',
            html: true,
            placement: 'bottom',
            template: `<div class="popover" role="tooltip">
            <h3 class="popover-title draggable grabbable"></h3>
            <div class="popover-content">
            </div></div>`,
            title: '',
            trigger: 'click'
        }).data('bs.popover')
            .tip()
            .addClass('help_document');

        var getContainment = function ($popover) {
            // Allow the popover to go past the window, but not so that it is lost
            var height = $popover.height();
            var width = $popover.width();
            var left = 0 - 0.75 * width;
            var top = $(window).scrollTop();

            // Full width would have the left side of the element at the right edge
            var right = $(window).width() - 0.25 * width;
            var bottom = $(window).scrollTop() + $(window).height() - 0.25 * height;

            return [left, top, right, bottom];
        };

        var containment = getContainment($popover);

        $popover.draggable({
            containment: containment,
            handle: '.draggable',
            scroll: false
        });

        $popover.resizable({
            handles: 'n, e, s, w, se'
        });

        var alignPopoverWithTrigger = function ($popover) {
            var startOffset = 15;
            $popover.css({
                left: $btn.offset().left - $popover.width() + $btn.outerWidth() + 'px',
                position: 'absolute', // Relative would make the popover take up room on the body element
                top: $btn.offset().top + $btn.outerHeight() / 2 + startOffset
            });
        };

        $btn.on('shown.bs.popover', function () {
            var $modal = $btn.closest('.modal.in');
            if ($modal.length) {
                $modal.on('hide.bs.modal', function () {
                    // There could be multiple modals, so must hide instead of destroy.
                    $btn.popover('hide');
                });
            }

            $(window).on('resize scroll', function () {
                $popover.draggable('option', 'containment', getContainment($popover));
                // Align the popover with the trigger again
                alignPopoverWithTrigger($popover);
            });

            // Align the popover with the trigger
            alignPopoverWithTrigger($popover);

            var $container = $popover.find('.popover-content');

            var defaultUrl = '/help-documents/' + link;

            var ajaxOptions = {
                url: defaultUrl,
                dataType: 'json',
                processData: false,
                contentType: false
            };

            var $form = options.form;

            if ($form) {
                var formData = new FormData($form[0]);

                // Always use POST for preview
                formData.set('_method', 'POST');
                formData.set('preview', true);

                $.each(Utils.getEditorData(CKEDITOR, $form), function (key, val) {
                    formData.set(key, val);
                });
                ajaxOptions.method = 'POST';
                ajaxOptions.data = formData;
            }

            $.ajax(ajaxOptions).then(function (response) {
                var $title = $popover.find('.popover-title');
                if (response.success) {
                    var data = response.data;
                    var content = $(data).html();
                    $container.html(content);
                    $title.html(`<div><i class="fas fa-times close" style="font-size: inherit;"></i><h5 class="ellipsis">${response.title}</h5></div>`).show();
                    $title.find('.close').on('click', function () {
                        if ($.contains(document, $btn[0])) {
                            $btn.popover('hide');
                        } else {
                            // In the event the modal is gone...
                            $popover.remove();
                        }
                    });
                } else {
                    return Promise.reject(response);
                }
            }).catch(err => {
                const errMsg = err.error_message ?? err.message ?? "There was an error communicating with the backend.";
                $container.html(errMsg);
            });

            // https://stackoverflow.com/questions/32581987/need-click-twice-after-hide-a-shown-bootstrap-popover/32640127
            $btn.on('hidden.bs.popover', function (e) {
                $(e.target).data('bs.popover').inState.click = false;
            });
        });

        return $btn;
    };

    return HelpDocument;
})(Utils, CKEDITOR);

window.HelpDocument = HelpDocument;
