root = './custom_scripts';

require(root + '/utils/base64');
require(root + '/utils/string-utils');
require(root + '/utils/utils');
require(root + '/custom-events');
require(root + '/session-manager');
require(root + '/ajax-container');
require(root + '/payment-form');
require(root + '/modal-footer');
require(root + '/help-document');
require(root + '/form-validator');
require(root + '/custom');
require(root + '/data-grid-table');
require(root + '/column-settings-dropdown');
require(root + '/server-side-data-grid-table');
require(root + '/dashboard-menu');
require(root + '/editor-manager');
require(root + '/forms');
require(root + '/direct-link');
require(root + '/mfa-config');
