function Utils() {}
;

Utils.isNullorUndefined = function (value) {
    return typeof value === 'undefined' || value === null;
};

Utils.isNumeric = function (value) {
    return !isNaN(value);
};

Utils.clearInput = function ($el) {
    $el.val('')
            .removeAttr('checked')
            .removeAttr('selected')
            .prop('selectedIndex', 0);
};

Utils.clearInputs = function ($root) {
    var $el = $root.find(':input,select')
            .not(':button, :submit, :reset, :hidden');
    this.clearInput($el);
};

Utils.getSpinnerHtml = function (options) {
    return '<i class="fas fa-spinner fa-spin fa-1x fa-fw"></i>';
};

Utils.getEditorData = function (CKEDITOR, $el) {
    var $editor = $el.find('textarea.ckeditor');
    var editorName = $editor.attr('name');

    // ckeditor now handled in views
    var editor = CKEDITOR.instances[$('textarea.ckeditor').attr('id')];
    var data = {};
    data[editorName] = editor.getData();

    return data;
};

Utils.loader = function (mode) {
    var $loader = $('#loader_holder');
    if (mode == 'hide') {
        $loader.removeClass('shown');
    } else {
        $loader.addClass('shown');
    }
}

Utils.serializeObject = function ($form) {
    var listArray = $form.serializeArray();
    var formData = {};

    listArray.map(function (item, i) {
        formData[item['name']] = item['value'];
    });

    return formData;
}

// Use for storing data in a data-encoded attribute
Utils.encodeData = function (data) {
    return Base64.encode(JSON.stringify(data));
}

// Use for decoding data stored in a data-encoded attribute
Utils.decodeData = function (string) {
    return JSON.parse(Base64.decode(string));
}

Utils.updateUrlQueryParam = function (uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
    if (value === undefined) {
        if (uri.match(re)) {
            return uri.replace(re, '$1$2');
        } else {
            return uri;
        }
    } else {
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            var hash = '';
            if (uri.indexOf('#') !== -1) {
                hash = uri.replace(/.*#/, '#');
                uri = uri.replace(/#.*/, '');
            }
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            return uri + separator + key + "=" + value + hash;
        }
    }
}

Utils.bytesToHumanReadable = function (bytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

    for (var i = 0; bytes > 1024; i++) {
        bytes /= 1024;
    }

    if (bytes) {
        return bytes.toFixed(2) + ' ' + units[i];
    }

    return null;
}

/**
 * This will open the url in a new tab when first called.
 * Subsequent calls will reuse that tab (instead of creating more new tabs).
 */
Utils.openLinkInTab = function (url) {
    const self = Utils.openLinkInTab;

    if (typeof self.tab === 'undefined' || self.tab.closed) {
        // If the tab doesn't exist or is closed, open a new one
        self.tab = window.open(url, '_blank');
    } else {
        // If the tab is still open, reuse it
        self.tab.location.href = url;
        self.tab.focus();
    }
}

window.Utils = Utils;
