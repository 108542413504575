// The manageForm will be a function that allows the caller to create a form with the specified methods.
// The addFieldCallbackFn should have the format:
// callbackFn(action, formSettings, keyPrefix, arrayIndex, $form, field, fieldTypes, extraData, record)

window.CALLBACK_ACTION_PRE = 'pre';
window.CALLBACK_ACTION_POST = 'post';
window.CALLBACK_ACTION_ARRAY_ITEM_PRE = 'array_item_pre';
window.CALLBACK_ACTION_ARRAY_ITEM_POST = 'array_item_post';

function manageForm(
    $form,
    formSettings,
    inputFields,
    fieldTypes,
    language,
    extraData,
    record,
    addFieldCallbackFn
) {
    let formReadOnly = arrayGet(formSettings, 'read_only', false);
    let $fieldSet = $(
        '<fieldset>',
        formReadOnly
            ? { class: 'soda-form-fieldset', disabled: 'disabled' }
            : { class: 'soda-form-fieldset' }
    );
    $form.append($fieldSet);

    for (let fieldIndex in inputFields) {
        let field = arrayGet(inputFields, fieldIndex, null);
        if (field) {
            addFieldToForm(
                inputFields,
                $fieldSet,
                formSettings,
                null, // Prefix
                null, // Array index
                field,
                fieldTypes,
                extraData,
                language,
                record,
                addFieldCallbackFn
            );
        }
    }

    site.tooltip();
}

function addFieldToForm(
    inputFields,
    $form,
    formSettings,
    keyPrefix,
    arrayIndex,
    field,
    fieldTypes,
    extraData,
    language,
    record,
    addFieldCallbackFn
) {
    if (field) {
        if (
            typeof addFieldCallbackFn === 'function' &&
            addFieldCallbackFn(
                CALLBACK_ACTION_PRE,
                formSettings,
                keyPrefix,
                arrayIndex,
                $form,
                field,
                fieldTypes,
                language,
                extraData,
                record
            )
        ) {
            // If the PRE action returns true, then the field was added there - so nothing to do
        } else {
            let type = arrayGet(field, 'type', null);
            let key = Form.getFieldKey(field, keyPrefix, arrayIndex);
            let recordKey = Form.getRecordKey(key);
            let groupClass = arrayGet(field, 'group_class', '');
            let groupSizeClass = Form.getGroupSizeClass(field, fieldTypes);
            let readOnly =
                arrayGet(field, 'read_only', false) || arrayGet(formSettings, 'read_only', false);

            // Add the form_group to the form so it can be easily searched while other elements are being added
            let $formGroup = $('<div>', {
                id: key ? key + '_form_group' : '',
                class: joinClasses(['soda-form-group', groupClass, groupSizeClass]),
            });
            $form.append($formGroup);

            if (type === 'subfield_array' || type === 'array' || type === 'contacts') {
                let arrayLabel = arrayGet(field, 'value.EN', '');
                let arrayContainerClass = arrayGet(field, 'container_class', '');
                let arrayUseTabs = arrayGet(field, 'use_tabs', false);
                let fieldsSelectorKey = arrayGet(field, 'fields_selector_key', null);
                let passKeyAsPrefix = arrayGet(field, 'pass_key_as_prefix', true);
                let arraySubFields = arrayGet(
                    extraData,
                    Form.getExtraDataKeyLookup(key) + '.__fields',
                    arrayGet(field, 'fields', null)
                );
                let fieldTypeInfo = arrayGet(fieldTypes, type, null);

                // Add in the heading for the array group
                if (!arrayLabel) {
                    $formGroup.append($('<div>', { class: 'soda-form-heading-container-empty' }));
                } else {
                    let $headingContainer = $('<div>', { class: 'soda-form-heading-container' });
                    $headingContainer.append(
                        `<h5 class="soda-array-group-heading">${arrayLabel}</h5>`
                    );

                    // Add in entry count and add min/max constraints if present.
                    if (type === 'array') {
                        const constraintNote = getEntryCountConstraintText(field);
                        const entryCount = arrayGet(record, recordKey, []).length;
                        const $entryCountNote = $(`
                            <span class="" data-field-key=${key}>
                                <span class="form_array_entry_count">Entries: <span class="current_entries">${entryCount} ${constraintNote}</span></span>
                            </span>
                        `);

                        $labelControlsDiv = $('<div>', { class: 'text-right' });
                        $labelControlsDiv.append($entryCountNote);

                        if (!readOnly) {
                            // This makes sure something gets sent when the array is empty
                            $baseArray = $('<input>', { type: 'hidden', name: key, value: '[]' });
                            $headingContainer.append($baseArray);

                            $arrayAdd = $('<a>', {
                                href: 'Javascript:void(0)',
                                class: 'form_array_add',
                            }).html('+ Entry');

                            $arrayAdd.on('click', function () {
                                let arrayIndex = 0;
                                let $containerDiv = $(
                                    Form.getFieldKeyLookup('#' + key) + '.soda-array-container'
                                );

                                let $lastArrayItem = $containerDiv
                                    .children('.form_array_item_container')
                                    .last();
                                if ($lastArrayItem.length) {
                                    arrayIndex = parseInt($lastArrayItem.data('array-index')) + 1;
                                }

                                let entriesCount = $containerDiv.children(
                                    '.form_array_item_container'
                                ).length;
                                let updatedText = `${entriesCount + 1} ${constraintNote}`; // +1: Add the new one in.

                                const limitReached = field.max && entriesCount === field.max;

                                if (!limitReached) {
                                    addArrayItem(
                                        $containerDiv,
                                        formSettings,
                                        passKeyAsPrefix ? key : null,
                                        arrayIndex,
                                        key,
                                        field,
                                        arraySubFields,
                                        fieldTypes,
                                        extraData,
                                        language,
                                        record,
                                        addFieldCallbackFn
                                    );
                                } else {
                                    updatedText = `<span class='text-danger'>maximum reached</span>`;
                                    $headingContainer.find('.form_array_add').hide();
                                }

                                $headingContainer.find('.current_entries').html(updatedText);
                            });

                            $labelControlsDiv.append($arrayAdd);
                        }

                        $headingContainer.append($labelControlsDiv);
                    }

                    Form.addFieldInfoMessage($headingContainer, keyPrefix, field, fieldTypes);
                    $formGroup.append($headingContainer);
                }

                // Add the container to the form so it can be easily searched while other elements are being added
                let baseContainerClass =
                    type === 'array'
                        ? 'soda-array-container'
                        : arrayUseTabs
                        ? ''
                        : 'soda-form-container';
                let $containerDiv = $('<div>', {
                    id: key,
                    class: joinClasses([baseContainerClass, arrayContainerClass]),
                    'data-validate': arrayGet(fieldTypeInfo, 'data-validate', ''),
                    'data-min-entries': field.min, // Needed for validation
                });
                $formGroup.append($containerDiv);

                //Is this an array with a rank field? Then make it sortable.
                const rankFieldExists = Array.isArray(field.fields)
                    ? field.fields?.find((field) => field.key === 'rank')
                    : false;

                if (rankFieldExists && type === 'array') {
                    $containerDiv.sortable({
                        handle: '.rankdragger',
                        update: function (e, ui) {
                            updateItemPositionData(field);
                        },
                        start: function (event, ui) {
                            // The below avoids an unexpected layout shift during drag and drop (49167). Take my word for it.
                            if (ui.item.css('display') === 'flex') {
                                ui.item.css('display', 'flex');
                            }
                        },
                    });
                }

                // Check to see if tabs should be used, if so create them first then populate them after
                if (arrayUseTabs) {
                    let tabData = [];

                    for (let arraySubFieldIndex in arraySubFields) {
                        let subField = arrayGet(arraySubFields, arraySubFieldIndex, null);

                        tabData.push({
                            id: Form.getTabKey(subField, key, arrayIndex),
                            active: arraySubFieldIndex == 0,
                            title: arrayGet(subField, 'value.EN', null),
                            template: '',
                        });
                    }

                    Form.addTabs($containerDiv, tabData);

                    for (let arraySubFieldIndex in arraySubFields) {
                        let subField = arrayGet(arraySubFields, arraySubFieldIndex, null);

                        // Tabs have already been created, use the .control-group div as the container
                        $containerDiv = $(
                            '#' + Form.getTabKey(subField, key, arrayIndex) + ' .control-group'
                        );

                        subField['value']['EN'] = '';

                        addFieldToForm(
                            inputFields,
                            $containerDiv,
                            formSettings,
                            passKeyAsPrefix ? key : null,
                            arrayIndex,
                            subField,
                            fieldTypes,
                            extraData,
                            language,
                            record,
                            addFieldCallbackFn
                        );
                    }
                } else if (fieldsSelectorKey) {
                    // Need to index into the extraData based on the value of a different field
                    let $selectorField = $(Form.getFieldKeyLookup('#' + fieldsSelectorKey));

                    if (!$selectorField.length) {
                        console.warn('Could not find selector field: ' + fieldsSelectorKey);
                    } else {
                        let selectFieldChangeFn = function () {
                            let selectorValue = $selectorField.val();
                            let changeSubFields = arrayGet(arraySubFields, selectorValue, null);

                            $containerDiv.empty();

                            for (let arraySubFieldIndex in changeSubFields) {
                                let subField = arrayGet(changeSubFields, arraySubFieldIndex, null);
                                addFieldToForm(
                                    inputFields,
                                    $containerDiv,
                                    formSettings,
                                    passKeyAsPrefix ? key : null,
                                    arrayIndex,
                                    subField,
                                    fieldTypes,
                                    extraData,
                                    language,
                                    record,
                                    addFieldCallbackFn
                                );
                            }
                        };

                        $selectorField.change(selectFieldChangeFn);
                        // Do the initial seeding
                        selectFieldChangeFn();
                    }
                } else if (type === 'array') {
                    // Add existing fields
                    let arrayRecordKey = Form.getRecordKey(key);
                    let arrayRecords = arrayGet(record, arrayRecordKey, []);

                    for (let i = 0; i < arrayRecords.length; i++) {
                        addArrayItem(
                            $containerDiv,
                            formSettings,
                            passKeyAsPrefix ? key : null,
                            i,
                            key,
                            field,
                            arraySubFields,
                            fieldTypes,
                            extraData,
                            language,
                            record,
                            addFieldCallbackFn
                        );
                    }

                    checkArrayItemEmpty($containerDiv, field);
                } else {
                    for (let arraySubFieldIndex in arraySubFields) {
                        let subField = arrayGet(arraySubFields, arraySubFieldIndex, null);
                        addFieldToForm(
                            inputFields,
                            $containerDiv,
                            formSettings,
                            passKeyAsPrefix ? key : null,
                            null,
                            subField,
                            fieldTypes,
                            extraData,
                            language,
                            record,
                            addFieldCallbackFn
                        );
                    }
                }
            } else {
                Form.addLabel(
                    $formGroup,
                    formSettings,
                    keyPrefix,
                    arrayIndex,
                    field,
                    fieldTypes,
                    extraData
                );
                addElementToForm(
                    $formGroup,
                    formSettings,
                    keyPrefix,
                    arrayIndex,
                    field,
                    fieldTypes,
                    language,
                    extraData,
                    record,
                    inputFields
                );
                Form.addAnnotation(
                    $formGroup,
                    formSettings,
                    keyPrefix,
                    field,
                    fieldTypes,
                    extraData
                ),
                Form.addAllowedTagsNote($formGroup, field);
            }

            // Add in optional dependencies support
            addFieldDependencies($form, keyPrefix, arrayIndex, field);

            // Add in optional collapsable support to the form group
            addFieldGroupCollapsableSupport($formGroup, field);

            // Call the post action callback
            if ($.isFunction(addFieldCallbackFn)) {
                addFieldCallbackFn(
                    CALLBACK_ACTION_POST,
                    formSettings,
                    keyPrefix,
                    arrayIndex,
                    $form,
                    field,
                    fieldTypes,
                    language,
                    extraData,
                    record
                );
            }
        }
    }
}

function addArrayItem(
    $containerDiv,
    formSettings,
    keyPrefix,
    arrayIndex,
    key,
    field,
    arraySubFields,
    fieldTypes,
    extraData,
    language,
    record,
    addFieldCallbackFn
) {
    let readOnly =
        arrayGet(field, 'read_only', false) || arrayGet(formSettings, 'read_only', false);

    $('.form_array_item_empty').remove();

    let $itemContainerDiv = $('<div>', {
        class: 'form_array_item_container',
        'data-array-index': arrayIndex,
    });

    if (typeof addFieldCallbackFn === 'function') {
        addFieldCallbackFn(
            CALLBACK_ACTION_ARRAY_ITEM_PRE,
            formSettings,
            keyPrefix,
            arrayIndex,
            $itemContainerDiv,
            field,
            fieldTypes,
            language,
            extraData,
            record
        );
    }

    $containerDiv.append($itemContainerDiv);

    //Add a dragger if a rank field exists
    const rankFieldExists = arraySubFields?.find((field) => field.key === 'rank');
    if (!readOnly && rankFieldExists) {
        let $itemContainerDraggerDiv = $('<div>', {
            class: 'form_array_item_container_dragger',
        });

        $itemContainerDraggerDiv.append(
            $('<span>', {
                class: joinClasses(['rankdragger', 'glyphicon', 'glyphicon-move']),
            })
        );

        $itemContainerDiv.append($itemContainerDraggerDiv);
    }

    let $itemContainerFieldsDiv = $('<div>', {
        class: 'form_array_item_container_fields',
    });
    $itemContainerDiv.append($itemContainerFieldsDiv);

    for (let arraySubFieldIndex in arraySubFields) {
        let subField = arrayGet(arraySubFields, arraySubFieldIndex, null);
        addFieldToForm(
            null,
            $itemContainerFieldsDiv,
            formSettings,
            keyPrefix,
            arrayIndex,
            subField,
            fieldTypes,
            extraData,
            language,
            record,
            addFieldCallbackFn
        );
    }

    let $itemContainerActionsDiv = $('<div>', {
        class: 'form_array_item_container_actions',
    });
    $itemContainerDiv.append($itemContainerActionsDiv);

    if (!readOnly) {
        let $arrayDelete = $('<a>', {
            href: 'Javascript:void(0)',
            class: 'form_array_delete btn btn-sm btn-danger',
        }).html("<i class='fas fa-trash' aria-hidden='true'></i>");

        $arrayDelete.on('click', function (e) {
            /**
             * The data-await-confirmation attribute may be set and removed in module_record_created_edit
             * for each module, e.g. to inject a confirmation dialog.
             */
            if ($(this).attr('data-await-confirmation')) {
                return;
            }
            //Remove the item from the record in memory
            if (record && record[key]) {
                record[key].splice($itemContainerDiv.attr('data-array-index'), 1);
            }

            //Remove the item from the DOM and re-index rank for remaining items
            $itemContainerDiv.remove();
            updateItemPositionData(field);

            let entriesCount = $containerDiv.children().length;

            $containerDiv
                .prev()
                .find('.current_entries')
                .html(`${entriesCount} ${getEntryCountConstraintText(field)}`);
            $containerDiv.prev().find('.form_array_add').show();

            checkArrayItemEmpty($containerDiv, field);
        });

        $itemContainerActionsDiv.append($arrayDelete);
    }

    if (typeof addFieldCallbackFn === 'function') {
        addFieldCallbackFn(
            CALLBACK_ACTION_ARRAY_ITEM_POST,
            formSettings,
            keyPrefix,
            arrayIndex,
            $itemContainerDiv,
            field,
            fieldTypes,
            language,
            extraData,
            record
        );
    }
}

function checkArrayItemEmpty($containerDiv, field) {
    let entriesCount = $containerDiv.children('.form_array_item_container').length;
    let emptyText = arrayGet(field, 'empty_list', 'No items currently in list');

    if (entriesCount == 0) {
        let $itemContainerDiv = $('<span>', {
            class: 'form_array_item_empty',
        }).html(emptyText);
        $containerDiv.append($itemContainerDiv);
    }
}

/**
 * Return something like (min: 1, max: 4) or (max 25) for the field.
 * @param {} field  An array field
 * @returns string
 */
function getEntryCountConstraintText(field) {
    if (!field || field.type !== 'array') {
        return '';
    }

    const constraintNotes = [];

    if (field.min) {
        constraintNotes.push(`min: ${field.min}`);
    }

    if (field.max) {
        constraintNotes.push(`max: ${field.max}`);
    }

    return constraintNotes.length ? '(' + constraintNotes.join(', ') + ')' : ``;
}

/**
 * Refresh indices and id-carrying attributes on the entire array (needed after reorder/delete)
 * @param {array}  field
 */
function updateItemPositionData(field) {
    const key = field?.key;
    const arraySubFields = field?.fields;
    const $containerDiv = $(Form.getFieldKeyLookup(`#${key}`));
    const containerIsSortable = $containerDiv.attr('class').split(' ').includes('ui-sortable');
    const rankFieldExists = arraySubFields?.find((field) => field.key === 'rank');
    const containerHasItems = $containerDiv.children().length;

    if (!(containerIsSortable && rankFieldExists && containerHasItems)) {
        return;
    }

    let oldArrayIndex;
    let newArrayIndex = 0;

    let jobs = [];
    let $item = $containerDiv.find(':first-child').first();

    // Iterate over the items of the array and collect the subfield elements that need updating.
    while ($item.length) {
        oldArrayIndex = $item.attr('data-array-index');

        //Update data-array-index attribute on item container
        $item.attr('data-array-index', newArrayIndex);

        // Regular expression to catch a sequence of characters that's not whitespace and not an opening square bracket, followed by an opening square bracket.
        const regex = new RegExp('^[^\\s\\[\\]]+\\[' + oldArrayIndex + '\\].*');
        const $subfieldElements = $containerDiv.find('*').filter(function () {
            return regex.test(this.id);
        });

        if (!(newArrayIndex == oldArrayIndex)) {
            jobs.push({ $subfieldElements, newArrayIndex });
        }

        $item = $item.next();
        newArrayIndex++;
    }

    // Update the ids (and name attributes, where present)
    jobs.forEach((job) => {
        const { $subfieldElements, newArrayIndex } = job;
        $subfieldElements.each(function () {
            const currentId = this.id;
            const regex = /\[(\d+)\]/;
            const match = currentId.match(regex);
            if (match && match.length > 1) {
                const newId = currentId.replace(regex, '[' + newArrayIndex + ']');

                $(this).attr('id', newId);

                if ($(this).attr('name') === currentId) {
                    $(this).attr('name', newId);
                }

                if (/\[rank\]$/.test(currentId)) {
                    // This is a rank field, update the value.
                    $(this).val(newArrayIndex + 1);
                }
            }
        });
    });
}

function addFieldDependencies($form, keyPrefix, arrayIndex, field) {
    let dependencies = arrayGet(field, 'dependencies');
    if (dependencies) {
        let fieldKey = Form.getFieldKey(field, keyPrefix, arrayIndex);

        for (let dependency of dependencies) {
            // Currently if there are multiple dependencies the assumption is that the logic is an 'AND'.
            // This means all dependencies have to have the specified value for the element to be shown.
            let dependencyKey = arrayGet(dependency, 'key', null);
            let dependencyValue = arrayGet(dependency, 'value', null);

            if (dependencyKey) {
                $depencencyElement = $(Form.getFieldKeyLookup('#' + dependencyKey));

                if (!$depencencyElement.length) {
                    console.error(
                        'Form dependency with the following ID not found: ' + dependencyKey
                    );
                    break;
                }

                dependencyChangeFn = function () {
                    let show = true;

                    for (let dependencyCheck of dependencies) {
                        if (!show) {
                            // If anything indicates not to show, then just break the loop
                            break;
                        }

                        // Only check if show is still true
                        let dependencyCheckKey = arrayGet(dependencyCheck, 'key', null);
                        let dependencyCheckValue = arrayGet(dependencyCheck, 'value', null);
                        let dependencyCheckValueAction = arrayGet(
                            dependencyCheck,
                            'value_action',
                            'equals'
                        );
                        let $dependencyCheck = $(Form.getFieldKeyLookup('#' + dependencyCheckKey));

                        if (!$dependencyCheck.length) {
                            console.error(
                                'Form dependency check with the following ID not found: ' +
                                    dependencyCheckKey
                            );
                            show = false;
                        }

                        if ($dependencyCheck.attr('type') === 'checkbox') {
                            let value = $dependencyCheck.val();
                            let checked = $dependencyCheck.is(':checked');

                            if (value === dependencyCheckValue) {
                                show = checked;
                            } else {
                                show = !checked;
                            }
                        } else {
                            let value = $dependencyCheck.val();


                            switch (dependencyCheckValueAction) {
                                case 'equals':
                                    if (Array.isArray(dependencyValue)) {
                                        show = dependencyValue.includes(value);
                                    } else {
                                        show = value == dependencyValue;
                                    }
                                    break;

                                case 'not_equals':
                                    if (Array.isArray(dependencyValue)) {
                                        show = !dependencyValue.includes(value);
                                    } else {
                                        show = value != dependencyValue;
                                    }
                                    break;

                                case 'empty':
                                    show = !value ? true : false;
                                    break;

                                case 'not_empty':
                                    show = value ? true : false;
                                    break;
                            }
                        }
                    }

                    let $formGroup = $form.find(
                        Form.getFieldKeyLookup('#' + fieldKey + '_form_group')
                    );

                    if (show) {
                        $formGroup.show();
                    } else {
                        $formGroup.hide();
                    }
                };

                $depencencyElement.on('change', dependencyChangeFn);
                // Seed the change
                dependencyChangeFn();
            }
        }
    }
}

function addFieldGroupCollapsableSupport($formGroup, field) {
    let collapsable = arrayGet(field, 'collapsable');

    if (collapsable === true || collapsable === 'collapsed') {
        let type = arrayGet(field, 'type');

        if (type === 'subfield_array' || type === 'array' || type === 'contacts') {
            $formGroup
                .find('.soda-array-group-heading:first')
                .after('<i class="fas fa-caret-down fa-2x form-collapsable"></i>');
        } else {
            $formGroup
                .find('.form-label:first')
                .after('<i class="fas fa-caret-down fa-2x form-collapsable"></i>');
        }

        $formGroup.find('.form-collapsable:first').click(function (event) {
            let $target = $(event.target);
            let $container = $target
                .parent()
                .closest('.soda-form-group')
                .find('div:not(".soda-form-heading-container"):first');

            if ($target.hasClass('form-entry-collapsed')) {
                $target.removeClass('form-entry-collapsed');
                $target.removeClass('fa-caret-right');
                $target.addClass('fa-caret-down');
                $container.show();
            } else {
                $target.addClass('form-entry-collapsed');
                $target.removeClass('fa-caret-down');
                $target.addClass('fa-caret-right');
                $container.hide();
            }
        });

        if (collapsable === 'collapsed') {
            $formGroup.find('.form-collapsable:first').click();
        }
    }
}

function addElementToForm(
    $form,
    formSettings,
    keyPrefix,
    arrayIndex,
    field,
    fieldTypes,
    language,
    extraData,
    record,
    inputFields
) {
    let show = arrayGet(field, 'show_in_form', true);
    if (!show) {
        // Do not show the specified field, just return
        return;
    }

    let key = Form.getFieldKey(field, keyPrefix, arrayIndex);
    let recordKey = Form.getRecordKey(key);
    let type = arrayGet(field, 'type', '');
    let fieldClass = arrayGet(field, 'class', '');
    let containerClass = arrayGet(field, 'container_class', '');
    let optional = arrayGet(field, 'optional', false) || field.custom; // Custom fields are always optional for now
    let placeholder = arrayGet(field, 'placeholder', '');
    let defaultValue = arrayGet(field, 'default', null);
    let fieldElementProps = arrayGet(field, 'element_props', {});
    let readOnly =
        arrayGet(formSettings, 'read_only', false) || arrayGet(field, 'read_only', false);

    let fieldTypeInfo = arrayGet(fieldTypes, type, null);
    if (!fieldTypeInfo) {
        console.warn('Field type does not appear to be supported: ' + type);
        return;
    }

    // Get the field Type elements
    let fieldTypeElement = arrayGet(fieldTypeInfo, 'element', '');
    let fieldTypeClass = arrayGet(fieldTypeInfo, 'class', '');
    let fieldTypeDataValidate = arrayGet(fieldTypeInfo, 'data-validate', '');
    let fieldMaxLength = arrayGet(field, 'length', arrayGet(fieldTypeInfo, 'data-length', null));

    if (readOnly) {
        // The default value to be null if not in editing mode. This ensures that blanks are shown
        // when editing is not possible. Note that if there is a record, then whatever value is there
        // should still be used.
        defaultValue = null;
    }

    // Check to see if the record has a value set
    if (record) {
        defaultValue = arrayGet(record, recordKey, defaultValue);
    }

    // Do not support optional with certain field types
    if (fieldTypeElement === 'checkbox') {
        optional = false;
    }

    // Use the fieldType placeholder if there is no current placeholder
    placeholder = placeholder || arrayGet(fieldTypeInfo, 'placeholder', '');
    if (optional && fieldTypeElement !== 'checkbox_array') {
        // Checkbox arrays have optional in the label, no need to have it in the placeholder
        placeholder = placeholder ? placeholder + ' [Optional]' : '[Optional]';
    }

    if (!optional) {
        fieldTypeClass += fieldTypeClass ? ' required' : 'required';
    }

    // First check for 'type' which is a more specific identifier. For example 'password'
    // which is a special case. Then check for the generic case which is fieldTypeElement
    // Try to keep them in alphabetic order.

    if (type === 'header') {
        // Do nothing only label is needed
    } else if (type === 'html_display') {
        $elem = $(`<${fieldTypeElement}>`, {
            id: key,
            class: `${fieldTypeClass} ${fieldClass}`,
        });

        $elem.html(defaultValue);

        $form.append($elem);
    } else if (type === 'html_page_display') {
        const spinnerHtml =
            '<div class="form_spinner" style="display: block; height: 100px;">' +
            '<i class="fas fa-spinner fa-spin fa-1x fa-fw"></i>' +
            '<span class="sr-only">Loading...</span>' +
            '</div>';

        // Create the div; it will only be a container for an iframe.
        $elem = $(`<${fieldTypeElement}>`, {
            id: key,
            class: `${fieldTypeClass} ${fieldClass}`,
            html: spinnerHtml,
        });

        // Create the iframe and hide it during document load.
        let $iframe = $('<iframe>', {
            srcdoc: defaultValue, // This allows using local html instead of loading from a server.
            id: key,
            class: `${fieldTypeClass} ${fieldClass}`,
            width: '100%',
            height: '0', // Will be adjusted after load.
        });
        $iframe.css({
            display: 'block',     // Seems to default to inline-block
            visibility: 'hidden', // Do not use display: none or else document height can't be calculated.
            border: 'none',
            padding: '0',
            margin: '0',
        });
        $elem.append($iframe);

        $iframe.on('load', function () {
            const iframeDoc = this.contentDocument || this.contentWindow.document;
            const iframeBody = iframeDoc.body;
            $(iframeDoc).ready(() => {
                // Remove additional padding that the iframe body may default to.
                $(iframeBody).css({ margin: '0' });

                // Set the iframe height to content height to avoid adding an extra scrollbar.
                const html = iframeDoc.documentElement;
                const contentHeight = Math.max(
                    iframeBody.scrollHeight,
                    iframeBody.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight
                );
                $(this).height(contentHeight + 20); // Add a bit of extra space as in some cases a vertical scrollbar may still appear.

                // Show the iframe content and remove the spinner.
                $(this).css({ visibility: 'visible' });
                $elem.find('.form_spinner').remove();
            });
        });

        $form.append($elem);
    } else if (type === 'color') {
        let $containerDiv = $('<div>', {
            class: joinClasses(['colorpicker input-group colorpicker-component', containerClass]),
        });
        $containerDiv.append(
            $('<input>', {
                type: fieldTypeElement,
                id: key,
                name: key,
                class: joinClasses(['form-control', fieldTypeClass, fieldClass]),
                placeholder: placeholder,
                'data-validate': fieldTypeDataValidate,
                value: defaultValue,
                maxlength: fieldMaxLength,
                readonly: readOnly ? 'readonly' : null,
            })
        );
        $containerDiv.append($('<span class="input-group-addon"><i></i></span>'));

        // https://itsjavi.com/bootstrap-colorpicker/
        $containerDiv.colorpicker({
            format: 'hex',
        });

        if (readOnly) {
            $containerDiv.colorpicker('disable');
        }

        // Add the container to the form
        $form.append($containerDiv);
    } else if (
        type === 'date' ||
        type === 'datetime' ||
        type === 'datetime_tz' ||
        type === 'time'
    ) {
        // Setup the options depending on what field type is being used
        let fieldPlaceholder = arrayGet(field, 'placeholder', '');
        let timezone = null;
        let timezoneField = arrayGet(field, 'timezone_field');
        let glyphion = 'glyphicon-calendar';
        let groupClass = '';
        let defaultSaveFormat = '';
        let defaultDisplayFormat = '';
        let offsetSave = null;
        let showToday = true;
        let supportedParsingFormats = ''; // This should be used special parsing such as time, normal dates formats are not 'special'
        let components = {};

        switch (type) {
            case 'date':
                groupClass = 'date';
                defaultSaveFormat = 'YYYY-MM-DD';
                defaultDisplayFormat = 'YYYY-MM-DD';
                components = { calendar: true, clock: false };
                break;

            case 'datetime':
                groupClass = 'datetime';
                defaultSaveFormat = 'YYYY-MM-DDTHH:mm:ss';
                defaultDisplayFormat = 'YYYY-MM-DD h:mm a';
                components = { calendar: true, clock: true };
                break;

            case 'datetime_tz':
                groupClass = 'datetime_tz';
                defaultSaveFormat = 'YYYY-MM-DDTHH:mm:ssZ';
                defaultDisplayFormat = 'YYYY-MM-DD h:mm a';
                timezone = getTimezone(fieldTypes, extraData, timezoneField);
                components = { calendar: true, clock: true };
                supportedParsingFormats = [
                    'YYYY-MM-DDTHH:mm:ss',
                    'YYYY-MM-DD HH:mm:ss',
                    'YYYY-MM-DD HH:mm',
                    'YYYY-MM-DD h:mm a',
                ];
                //Since tempus dominus does not support timezones, need to consider the offset to the client location on save.
                offsetSave = '-';
                break;

            case 'time':
                glyphion = 'glyphicon-time';
                groupClass = 'time';
                defaultSaveFormat = 'HH:mm';
                defaultDisplayFormat = 'h:mm a';
                showToday = false;
                supportedParsingFormats = [
                    'HH:mm',
                    'h:mm a',
                    'YYYY-MM-DDTHH:mm:ss',
                    'YYYY-MM-DD HH:mm:ss',
                    'YYYY-MM-DD HH:mm',
                    'YYYY-MM-DD h:mm a',
                ];
                components = { calendar: false, clock: true };
                break;
        }

        let saveFormat =
            arrayGet(field, 'save_format', '') ||
            arrayGet(fieldTypeInfo, 'save_format', '') ||
            defaultSaveFormat;
        let displayFormat =
            arrayGet(field, 'display_format', '') ||
            arrayGet(fieldTypeInfo, 'display_format', '') ||
            defaultDisplayFormat;

        // Handle TempusDominus mapping, as it uses slight different formats
        // and hanlding for timezones
        let displayFormatTempusDominus = displayFormat;
        let placeholderTempusDominus = '';

        switch (displayFormat) {
            case 'YYYY-MM-DD h:mm a ZZ':
            case 'YYYY-MM-DD h:mm a Z':
            case 'YYYY-MM-DD h:mm a':
                displayFormatTempusDominus = 'yyyy-MM-dd h:mm T';
                placeholderTempusDominus = 'e.g. 2021-05-27 3:55 PM';
                break;

            case 'YYYY-MM-DD':
                displayFormatTempusDominus = 'yyyy-MM-dd';
                placeholderTempusDominus = 'e.g. 2021-05-27';
                break;

            case 'h:mm a':
                displayFormatTempusDominus = 'h:mm T';
                placeholderTempusDominus = 'e.g. 3:55 PM';
                break;

            case 'HH:mm':
                displayFormatTempusDominus = 'HH:mm';
                placeholderTempusDominus = 'e.g. 15:55';
                break;
        }

        const hourCycle = displayFormatTempusDominus.includes('T') ? 'h12' : 'h23';

        let saveElementOptions = {
            type: 'hidden',
            id: key,
            name: key,
            class: '',
            'data-validate': fieldTypeDataValidate,
        };

        if (defaultValue) {
            // Handle potential issues with the Tempus Dominus time picker.
            if (displayFormatTempusDominus === 'h:mm T') {
                // Remove seconds if present.
                parts = defaultValue.split(':');
                if (parts.length > 2) {
                    defaultValue = parts[0] + ':' + parts[1];
                }

                // Handle 'INVALID DATE'.
                if (defaultValue === 'INVALID DATE') {
                    // Default to current client time (eg. 10:10 AM).
                    defaultValue = new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                    }).format(Date.now());
                }
            }

            saveElementOptions.value = getDatetimeDefaultValue(
                defaultValue,
                saveFormat,
                timezone,
                supportedParsingFormats
            );
        }

        if (!fieldPlaceholder) {
            placeholder = optional
                ? placeholderTempusDominus + ' [Optional]'
                : placeholderTempusDominus;
        }

        let displayElementOptions = {
            type: fieldTypeElement,
            id: key + '_display',
            name: key + '_display',
            class: 'form-control ' + fieldTypeClass,
            placeholder: placeholder,
            readonly: readOnly ? 'readonly' : null,
            maxlength: fieldMaxLength,
        };

        if (defaultValue) {
            displayElementOptions.value = getDatetimeDefaultValue(
                defaultValue,
                displayFormat,
                timezone,
                supportedParsingFormats
            );
        }

        // Add the save element outside the group, as the group is focused on the picker, and this is not managed by the picker
        $form.append($('<input>', saveElementOptions));
        let $groupDiv = $('<div>', {
            class: 'input-group ' + groupClass,
            id: key + '_datepicker_group',
        });
        $groupDiv.append($('<input>', displayElementOptions));
        $groupDiv.append(
            '<span class="input-group-addon">' +
                '  <span class="glyphicon ' +
                glyphion +
                '"></span>' +
                '</span>'
        );
        $form.append($groupDiv);

        if (!readOnly) {
            restrictions = {};

            // If a minimum date is specified to be __now__, put that in as a restriction
            // NOTE: there is a check on submit as well, this is just a UX improvement
            if (arrayGet(field, 'min_datetime') == '__now__') {
                restrictions = {
                    minDate: moment().toDate(),
                };
            }

            // Add in the datetime picker if not readonly
            // Tempus-Dominus v6 (switched to this Sep 2022)
            // Use moment-parse plugin for convenience since we already have momentjs formatted display options
            tempusDominus.extend(tempusDominus.plugins.fa_five.load);

            $groupDiv.data(
                'DateTimePicker',
                new tempusDominus.TempusDominus($groupDiv[0], {
                    display: {
                        buttons: {
                            close: true,
                            clear: optional ? true : false,
                            today: showToday,
                        },
                        components,
                    },
                    restrictions: restrictions,
                    localization: {
                        format: displayFormatTempusDominus,
                        hourCycle: hourCycle,
                    },
                })
            );

            // Use the change.td event to make sure the display and saved values stay in sync
            $groupDiv.on('change.td', function (e) {
                let $saveElement = $(Form.getFieldKeyLookup('#' + saveElementOptions.id));
                let $displayElement = $(Form.getFieldKeyLookup('#' + displayElementOptions.id));
                let displayValue = $displayElement.val().trim();

                if (displayValue) {
                    // Handle a bug in the picker: it outputs '0:23 AM' when selecting '12:23 AM' (Sep 2023)
                    switch (displayFormatTempusDominus) {
                        case 'yyyy-MM-dd h:mm T':
                            displayValue = displayValue.replace(' 0:', ' 12:');
                            $displayElement.val(displayValue);
                            break;

                        case 'h:mm T':
                            displayValue = displayValue.replace(/^0:/, '12:');
                            $displayElement.val(displayValue);
                            break;
                    }

                    $saveElement.val(
                        getDatetimeDefaultValue(
                            //On some browsers, tempus dominus will output 'A.M.' instead of 'AM',
                            //which causes parsing issues. Take out the periods.
                            displayValue.replace(/((?:A|P)\.M\.)/i, (match, p1) =>
                                p1.replaceAll('.', '')
                            ),
                            saveFormat,
                            timezone,
                            [displayFormat, ...supportedParsingFormats],
                            offsetSave
                        )
                    );
                } else {
                    // display value is blank, then use blank as well
                    $saveElement.val('');
                }
            }).trigger('change.td');
        }
    } else if (type === 'password' || type === 'protected_text') {
        // Password is high level type special case
        let recordExists = record !== null && Object.keys(record).length !== 0;
        if (recordExists) {
            fieldTypeClass = fieldTypeClass.replace('required', '');
            if (type === 'password') {
                placeholder =
                    'Passwords are not displayed - leave blank to keep current password. ' +
                    placeholder;
            } else if (type === 'protected_text') {
                placeholder =
                    'Text is protected - leave blank to keep current information. ' + placeholder;
            }
        }

        $form.append(
            $('<input>', {
                type: fieldTypeElement,
                id: key,
                name: key,
                class: joinClasses(['form-control', fieldTypeClass, fieldClass]),
                placeholder: placeholder,
                'data-validate': fieldTypeDataValidate,
                value: '', // Never show a password
                readonly: readOnly ? 'readonly' : null,
                maxlength: fieldMaxLength,
            })
        );
    } else if (type === 'photo_display') {
        $img = $('<img>', {
            id: key,
            src: defaultValue,
            class: 'form-photo-display',
        });

        if (arrayGet(field, 'settings.border')) {
            // Support default border only.
            $img.css('border-style', 'solid')
                .css('border-width', '1px')
                .css('border-color', '#D2D6DE');
        }

        // Support selected style attributes with any value.
        const styleAttrs = [
            'width',
            'min-width',
            'max-width',
            'height',
            'min-height',
            'max-height',
            'padding',
        ];
        styleAttrs.forEach((styleAttr) => {
            const value = arrayGet(field, `settings.${styleAttr}`, null);
            if (value !== null) {
                $img.css(styleAttr, value);
            }
        });

        $form.append($img);
    } else if (type === 'photo') {
        if (defaultValue || fieldElementProps.show_thumbnail) {
            $form.append(
                $('<a>', {
                    class: 'form-photo-thumbnail',
                    href: defaultValue,
                    target: '_blank',
                    rel: 'noopener',
                    title: defaultValue,
                }).append($('<img>', { id: key + '__thumbnail', src: defaultValue }))
            );
        }

        let extensions = arrayGet(fieldTypeInfo, 'extensions', []);
        // Make sure the extensions all have a . before them
        extensions = extensions.map((extension) => '.' + extension);

        // If this field is 'required' then make the entire group is 'required'.
        // This ensures that at least one of the fields is set, rather than
        // requiring both, or just one of them.
        // Remove the individual field 'required' flag as the group flag will handle it.
        if (!optional) {
            fieldTypeClass = fieldTypeClass.replaceAll('required', '');
            $form.addClass('required-form-group');
        }

        let fileInputClasses = joinClasses([
            'form-control form-photo-input ' + (optional ? 'file-input-optional' : ''),
            fieldTypeClass,
            fieldClass,
        ]);

        $form.append(
            $('<input>', {
                type: fieldTypeElement,
                id: key + '__file',
                name: key + '__file',
                class: fileInputClasses,
                accept: extensions.toString(),
                placeholder: placeholder,
                'data-validate': fieldTypeDataValidate,
                readonly: readOnly ? 'readonly' : null,
            })
        );

        $form.append(
            $('<input>', {
                type: 'text',
                id: key,
                name: key,
                class: joinClasses(['form-control form-photo-input', fieldTypeClass, fieldClass]),
                placeholder: placeholder,
                'data-validate': fieldTypeDataValidate,
                value: defaultValue,
                readonly: readOnly ? 'readonly' : null,
                maxlength: fieldMaxLength,
            })
        );
    } else if (type === 'multi_photo') {
        if (defaultValue) {
            defaultValue.forEach(function (obj) {
                $form.append(
                    $('<a>', {
                        class: 'form-photo-thumbnail',
                        href: obj,
                        target: '_blank',
                        rel: 'noopener',
                        title: obj,
                    }).append($('<img>', { src: obj }))
                );

                $form.append(
                    $('<input>', {
                        type: 'hidden',
                        id: key + '[]',
                        name: key + '[]',
                        value: obj,
                    })
                );
            });
        }

        let extensions = arrayGet(fieldTypeInfo, 'extensions', []);
        // Make sure the extensions all have a . before them
        extensions = extensions.map((extension) => '.' + extension);

        $form.append(
            $('<input>', {
                type: fieldTypeElement,
                id: key + '__files[]',
                name: key + '__files[]',
                class: joinClasses([
                    'form-control form-photo-input ' + (optional ? 'file-input-optional' : ''),
                    fieldTypeClass,
                    fieldClass,
                ]),
                accept: extensions.toString(),
                placeholder: placeholder,
                'data-validate': fieldTypeDataValidate,
                readonly: readOnly ? 'readonly' : null,
            })
        );
    } else if (type === 'rank') {
        //If this a newly inserted element, defaultValue is next rank (which equals the # of children/items)
        if (!arrayGet(record, key)) {
            $containerDiv = $(Form.getFieldKeyLookup('#' + keyPrefix) + '.soda-array-container');
            if ($containerDiv.length) {
                defaultValue = $containerDiv.children().length;
            }
        }

        $form.append(
            $('<input>', {
                type: 'hidden',
                id: key,
                name: key,
                value: defaultValue,
            })
        );
    } else if (type === 'uuid') {
        $form.append(
            $('<input>', {
                type: 'hidden',
                id: key,
                name: key,
                value: defaultValue,
            })
        );
    } else {
        //
        // Now checking FieldTypeElements
        //
        if (fieldTypeElement === 'checkbox') {
            let elementOptions = {
                type: fieldTypeElement,
                id: key,
                name: key,
                class: joinClasses([fieldTypeClass, fieldClass, 'form-checkbox-left']),
                'data-validate': fieldTypeDataValidate,
                value: arrayGet(fieldTypeInfo, 'checkbox_value', '1'),
                readonly: readOnly ? 'readonly' : null,
            };

            let checkboxValue = arrayGet(fieldTypeInfo, 'checkbox_value', '1');
            if (defaultValue === checkboxValue) {
                elementOptions['checked'] = 'checked';
            }

            $form.append($('<input>', elementOptions));

            if (placeholder) {
                $form.append('<p>' + placeholder + '</p>');
            }
        } else if (fieldTypeElement === 'checkbox_array') {
            let extraDataValues = arrayGet(extraData, key, []);
            let recordValueArray = arrayGet(record, recordKey, arrayGet(field, 'default', []));

            let $containerDiv = $('<div>', {
                class: containerClass,
            });

            values = [];
            if (Object.keys(extraDataValues).length > 0) {
                Object.keys(extraDataValues).forEach(function (checkboxKey) {
                    let value = extraDataValues[checkboxKey];

                    values.push({
                        key: checkboxKey,
                        value: value,
                    });
                });
            } else {
                values = arrayGet(field, 'values', []);
            }

            addCheckBoxElements(
                $containerDiv,
                fieldTypeClass,
                fieldTypeDataValidate,
                fieldElementProps,
                key,
                recordValueArray,
                values,
                readOnly,
                15,
                0
            );
            $form.append($containerDiv);

            if (placeholder) {
                $form.append('<p>' + placeholder + '</p>');
            }

            // If it is indicated to be a single select then make sure only one can be selected at a time
            if (fieldElementProps.single_select) {
                $containerDiv.find('input[name="' + key + '[]"]').on('change', function () {
                    $containerDiv
                        .find('input[name="' + key + '[]"]')
                        .not(this)
                        .prop('checked', false);
                });
            }
        } else if (fieldTypeElement === 'file') {
            let extensions = arrayGet(
                field,
                'extensions',
                arrayGet(fieldTypeInfo, 'extensions', [])
            );
            // Make sure the extensions all have a . before them
            extensions = extensions.map((extension) => '.' + extension);

            // If this field is 'required' then make the entire group is 'required'.
            // This ensures that at least one of the fields is set, rather than
            // requiring both, or just one of them.
            // Remove the individual field 'required' flag as the group flag will handle it.
            if (!optional) {
                fieldTypeClass = fieldTypeClass.replaceAll('required', '');
                $form.addClass('required-form-group');
            }

            $form.append(
                $('<input>', {
                    type: fieldTypeElement,
                    id: key + '__file',
                    name: key + '__file',
                    class: joinClasses([
                        'form-control' + (optional ? ' file-input-optional' : ''),
                        fieldTypeClass,
                        fieldClass,
                        'customFileInput',
                    ]),
                    accept: extensions.toString(),
                    placeholder: placeholder,
                    'data-validate': fieldTypeDataValidate,
                    readonly: readOnly ? 'readonly' : null,
                })
            );

            $form.append(
                $('<input>', {
                    type: 'text',
                    id: key,
                    name: key,
                    class: joinClasses(['form-control', fieldTypeClass, fieldClass]),
                    placeholder: placeholder,
                    value: defaultValue,
                    'data-validate': fieldTypeDataValidate,
                    maxlength: fieldMaxLength,
                    readonly: readOnly ? 'readonly' : null,
                })
            );
        } else if (fieldTypeElement === 'multi_select') {
            let extraDataValues = arrayGet(extraData, recordKey, []);
            let recordValueArray = arrayGet(record, recordKey, []);
            let useValuesApi = arrayGet(field, 'use_values_api', false);

            let $containerDiv = $('<div>', {
                class: containerClass,
            });
            // Add in an empty hidden input first, this ensures that for multiple something get sent when empty
            $containerDiv.append($('<input>', { type: 'hidden', name: key, value: '' }));
            let $select = $('<select>', {
                id: key + '[]',
                name: key + '[]',
                class: joinClasses(['form-control', fieldTypeClass, fieldClass]),
                multiple: 'multiple',
                size: 6,
            });
            // Make sure the select is added to the form before setSelectOptions as there are divs that might be added .after()
            $containerDiv.append($select);
            $form.append($containerDiv);

            if (useValuesApi) {
                loadValuesFromApi(
                    $select,
                    recordKey,
                    field,
                    language,
                    extraData,
                    recordValueArray,
                    optional,
                    readOnly,
                    null
                );
            } else {
                valuesMap = new Map();

                if (typeof extraDataValues === 'object' && Object.keys(extraDataValues).length) {
                    Object.keys(extraDataValues).forEach((checkboxKey) => {
                        let label = extraDataValues[checkboxKey];
                        valuesMap.set(checkboxKey, {
                            key: checkboxKey,
                            value: label,
                        });
                    });
                } else {
                    // Support values in the actual field definition
                    values = arrayGet(field, 'values', []);

                    for (let i = 0; i < values.length; i++) {
                        let checkboxKey = values[i].key;
                        let label = values[i].value || values[i].display;

                        valuesMap.set(checkboxKey, {
                            key: checkboxKey,
                            value: label,
                        });
                    }
                }

                setSelectOptions(
                    $select,
                    field,
                    valuesMap,
                    recordValueArray,
                    optional,
                    readOnly,
                    null
                );
            }
        } else if (fieldTypeElement === 'number') {
            // Covers soda field types: number, float_positive
            let elementOptions = {
                type: fieldTypeElement,
                id: key,
                name: key,
                class: joinClasses(['form-control', fieldTypeClass, fieldClass]),
                min: arrayGet(field, 'minimum', '') || arrayGet(fieldTypeInfo, 'minimum', '') || '0',
                max: arrayGet(field, 'maximum', '') || arrayGet(fieldTypeInfo, 'maximum', '') || '',
                step: arrayGet(field, 'step', '') || arrayGet(fieldTypeInfo, 'step', '') || '1',
                placeholder: placeholder,
                'data-validate': fieldTypeDataValidate,
                readonly: readOnly ? 'readonly' : null,
            };

            if (Utils.isNumeric(defaultValue)) {
                elementOptions.value = defaultValue;
            }

            $form.append($('<input>', elementOptions));
        } else if (fieldTypeElement === 'select') {
            let formReadOnly = arrayGet(formSettings, 'read_only', false);
            let useValuesApi = arrayGet(field, 'use_values_api', false);
            let arraySelectorKey = arrayGet(field, 'array_selector_key', null);

            let $select = $('<select>', {
                id: key,
                name: key,
                class: joinClasses(['form-control', fieldTypeClass, fieldClass]),
                value: defaultValue,
                'data-validate': fieldTypeDataValidate,
            });
            // Make sure the select is added to the form before setSelectOptions as there are divs that might be added .after()
            $form.append($select);

            if (useValuesApi) {
                loadValuesFromApi(
                    $select,
                    recordKey,
                    field,
                    language,
                    extraData,
                    defaultValue,
                    optional,
                    readOnly
                );
            } else if (arraySelectorKey) {
                // Need to index into the extraData based on the value of a different field
                let $selectorField = $(Form.getFieldKeyLookup('#' + arraySelectorKey));
                let selectFieldChangeFn = function () {
                    let keyedSelectArrays = arrayGet(extraData, recordKey, []);
                    let selectorValue = $selectorField.val();
                    let selectValues = arrayGet(keyedSelectArrays, selectorValue, []);

                    let valuesMapChange = new Map();
                    addSelectValuesToMap(valuesMapChange, selectValues, '');
                    setSelectOptions(
                        $select,
                        field,
                        valuesMapChange,
                        defaultValue,
                        optional,
                        readOnly,
                        null
                    );
                };

                $selectorField.change(selectFieldChangeFn);
                // Seed the function
                selectFieldChangeFn();
            } else {
                let valuesMap = new Map();

                if (type === 'select_list' || type === 'product_list') {
                    // These are the dynamically provided select options
                    let valuesObjects = arrayGet(
                        extraData,
                        Form.getExtraDataKeyLookup(recordKey),
                        arrayGet(field, 'values', [])
                    );

                    addSelectValuesToMap(valuesMap, valuesObjects, '');
                } else {
                    let values = arrayGet(fieldTypeInfo, 'values', []);

                    // If options defined in the field, use those instead
                    let fieldOptions = arrayGet(field, 'options');
                    if (fieldOptions) {
                        values = fieldOptions;
                    }

                    if (type === 'boolean') {
                        if (defaultValue === null && !optional && !readOnly) {
                            defaultValue = '0';
                        }

                        if (defaultValue !== null) {
                            defaultValue = defaultValue && defaultValue !== '0' ? '1' : '0';
                        }
                    }

                    for (let valuesKey in values) {
                        valuesMap.set(valuesKey, {
                            key: valuesKey,
                            value: values[valuesKey],
                        });
                    }
                }

                setSelectOptions($select, field, valuesMap, defaultValue, optional, readOnly, null);
            }

            if (!formReadOnly && readOnly) {
                // Only do this if it is the only this field is readonly. If the form is readonly, then it won't be
                // saved and there is no point is cloning. The clone can create some problems when doing some
                // custom functionality with the select_list, so it is best not to clone unless there is a reason
                let $clone = $select.clone();
                $select.find('option').clone().appendTo($clone);
                let newId = '__' + $clone.attr('id') + '_clone';
                $clone.attr('id', newId);
                $clone.prop('name', newId);
                $clone.prop('disabled', true);
                $form.append($clone);

                // Add the hidden original element
                $select.hide();
                $form.append($select);
                // If the options change (due to delayed loading, make sure to update)
                $select.change(function () {
                    $select.find('option').clone().appendTo($clone);
                });
            }
        } else if (fieldTypeElement === 'textarea') {
            let rows = arrayGet(field, 'rows', null);
            if (rows === null) {
                // default to 5 rows
                rows = 5;

                if (!isNaN(parseInt(fieldMaxLength))) {
                    rows = parseInt(fieldMaxLength) / 50 + 1;

                    if (rows < 1) {
                        rows = 1;
                    } else if (rows > 12) {
                        rows = 15;
                    }
                }
            }

            let elementOptions = {
                cols: '50',
                rows: rows,
                class: joinClasses(['form-control', fieldTypeClass, fieldClass]),
                id: key,
                name: key,
                text: defaultValue,
                readonly: readOnly ? 'readonly' : null,
                maxlength: fieldMaxLength,
                placeholder: placeholder,
                'data-validate': fieldTypeDataValidate,
            };

            let editorSupported = ['editor', 'html', 'html_lite'].includes(type);
            if (editorSupported) {
                elementOptions['class'] = elementOptions.class + ' ckeditor';
            }

            $form.append($('<textarea>', elementOptions));

            if (editorSupported && !readOnly) {
                let editorManager = new EditorManager();
                let editorOptions = EditorManager.getDefaultConfigOptions($('#token').val());

                if (type == 'html_lite') {
                    editorOptions = $.extend(editorOptions, {
                        fullPage: false,
                        removePlugins: 'resize,elementspath,PasteFromWord',
                        toolbarGroups: [
                            {
                                name: 'document',
                                groups: ['mode', 'document'],
                            },
                            {
                                name: 'editing',
                                groups: ['find', 'selection', 'spellchecker'],
                            },
                            {
                                name: 'clipboard',
                                groups: ['clipboard', 'undo'],
                            },
                            { name: 'links' },
                            {
                                name: 'basicstyles',
                                groups: ['basicstyles', 'cleanup'],
                            },
                            {
                                name: 'paragraph',
                                groups: ['list'],
                            },
                            { name: 'insert' },
                        ],
                    });
                }

                let editorAllowedContent = arrayGet(fieldTypeInfo, 'editor_allowed_content', null);
                if (editorAllowedContent) {
                    editorOptions = $.extend(editorOptions, {
                        allowedContent: editorAllowedContent,
                    });
                }

                editorManager.initialize($form.find('#' + key), editorOptions);
            }
        } else {
            $form.append(
                $('<input>', {
                    type: fieldTypeElement,
                    id: key,
                    name: key,
                    class: joinClasses(['form-control', fieldTypeClass, fieldClass]),
                    placeholder: placeholder,
                    'data-validate': fieldTypeDataValidate,
                    maxlength: fieldMaxLength,
                    readonly: readOnly ? 'readonly' : null,
                    value: defaultValue ? defaultValue : null,
                })
            );
        }
    }
}

function getDatetimeDefaultValue(
    defaultValue,
    format,
    timezone,
    supportedFormats,
    applyLocalOffset
) {
    // This can be used for time, date or datetime, it is the
    // format that decides what comes back.
    if (!defaultValue) {
        return null;
    }

    if (defaultValue && format) {
        const regexAndType = {
            '^([+-]?)(\\d+)\\s*s': 'seconds',
            '^([+-]?)(\\d+)\\s*m': 'minutes',
            '^([+-]?)(\\d+)\\s*h': 'hours',
            '^([+-]?)(\\d+)\\s*d': 'days',
            '^([+-]?)(\\d+)\\s*y': 'years',
        };

        let base = moment();
        let matched = false;
        let offsets = defaultValue.split(',');

        if (timezone) {
            base.tz(timezone);
        }

        for (i = 0; i < offsets.length; i++) {
            let offset = offsets[i].trim();

            Object.keys(regexAndType).forEach(function (key) {
                let matches = offset.match(new RegExp(key, 'i'));
                if (matches) {
                    matched = true;
                    if (matches[1] === '-') {
                        base = base.subtract(matches[2], regexAndType[key]);
                    } else {
                        base = base.add(matches[2], regexAndType[key]);
                    }
                }
            });
        }

        if (matched) {
            return base.format(format);
        }
    }

    // If we make it this far, return the formatted default value
    let returnValue = null;
    if (supportedFormats) {
        if (!Array.isArray(supportedFormats)) {
            supportedFormats = [supportedFormats];
        }

        for (i = 0; i < supportedFormats.length; i++) {
            // Use strict mode when parsing and make sure it is value
            returnValue = moment(defaultValue, supportedFormats[i], true);
            if (returnValue && returnValue.isValid()) {
                break;
            }
        }

        if (!returnValue || !returnValue.isValid()) {
            returnValue = moment(defaultValue);
        }
    } else {
        returnValue = moment(defaultValue);
    }

    if (timezone) {
        returnValue.tz(timezone);
    }

    //The below compensates for Tempus Dominus v6 lack of timezone support
    const targetDateInLocalTZ = moment(
        returnValue.format('YYYY-MM-DD h:mm a'),
        'YYYY-MM-DD h:mm a'
    );
    const clientOffset = targetDateInLocalTZ.utcOffset();
    const targetOffset = returnValue.utcOffset();

    let offsetAdjustment;
    switch (applyLocalOffset) {
        case '+':
            //When displaying: Add the time difference between the target and local time zone
            offsetAdjustment = targetOffset - clientOffset;
            break;

        case '-':
            //When saving: Subtract the time difference between the traget and local time zone
            offsetAdjustment = -(targetOffset - clientOffset);
            break;

        default:
            offsetAdjustment = 0;
    }

    returnValue.add(offsetAdjustment, 'minutes');
    return returnValue.format(format).toUpperCase();
}

function getTimezone(fieldTypes, extraData, timezoneField) {
    let timezone = null;
    let $timezoneField = $(Form.getFieldKeyLookup('#' + timezoneField));

    if ($timezoneField) {
        let timezoneValue = $timezoneField.val();

        let mapping = arrayGet(fieldTypes, 'timezone.php_mapping');
        if (timezoneValue && mapping) {
            timezone = arrayGet(mapping, timezoneValue);
        }
    }

    if (!timezone) {
        if (extraData) {
            let timezoneInfo = arrayGet(extraData, 'timezone_info', null);
            if (timezoneInfo) {
                timezone = arrayGet(timezoneInfo, 'timezone_php', null);
            }
        }
    }

    return timezone;
}

function addCheckBoxElements(
    $div,
    fieldTypeClass,
    fieldTypeDataValidate,
    fieldElementProps,
    key,
    recordValueArray,
    values,
    readOnly,
    margin,
    level
) {
    if (values && values.length) {
        for (let i = 0; i < values.length; i++) {
            let value = values[i];
            let checkboxKey = value.key;
            let label = value.value;
            let children = value.children;
            let isSet = recordValueArray ? recordValueArray.includes(checkboxKey) : false;

            let elementOptions = {
                type: 'checkbox',
                name: key + '[]',
                class: joinClasses([fieldTypeClass, 'form-checkbox-right']),
                style: ' margin-left: ' + margin * level + 'px;',
                'data-validate': fieldTypeDataValidate,
                value: checkboxKey,
                readonly: readOnly ? 'readonly' : null,
            };

            if (isSet) {
                elementOptions['checked'] = 'checked';
            }

            let $inputDiv = $(
                '<div class="' + (!fieldElementProps.stacked ? 'checkbox-horizontal' : '') + '">'
            );
            $inputDiv.append($('<input>', elementOptions));
            $inputDiv.append(
                $('<label class="checkbox-label" for="' + key + '">' + label + '</label>')
            );
            $div.append($inputDiv);

            if (children && children.length > 0) {
                addCheckBoxElements(
                    $div,
                    fieldTypeClass,
                    fieldTypeDataValidate,
                    fieldElementProps,
                    key,
                    recordValueArray,
                    children,
                    readOnly,
                    margin,
                    level + 1
                );
            }
        }
    } else {
        $div.append('-- None --');
    }
}

function loadValuesFromApi(
    $select,
    recordKey,
    field,
    language,
    extraData,
    defaultValue,
    optional,
    readOnly
) {
    let moduleDb = arrayGet(extraData, 'module.database');
    let moduleId = arrayGet(extraData, 'module.id');
    let moduleType = arrayGet(extraData, 'module.type');
    let keyValue = arrayGet(field, 'values_api_key');

    if (keyValue == null || keyValue === '') {
        setSelectOptions($select, field, new Map(), null, false, readOnly, '-- Loading ... --');
        $.ajax({
            type: 'POST',
            url: '/data/dynamic-data/',
            cache: false,
            data: {
                data_type: 'values',
                language: language,
                module_db: moduleDb,
                module_id: moduleId,
                module_type: moduleType,
                module_field: recordKey,
                key_value: keyValue,
            },
            success: function (response) {
                let responseError = null;
                let values = [];
                let valuesMap = new Map();

                if (response.success) {
                    if (response.data && response.data.field && response.data.field.values) {
                        values = response.data.field.values;
                    }
                } else {
                    responseError = response.error_message;
                    if (responseError == null) {
                        responseError = 'An unknown error occurred';
                    }
                }
                addSelectValuesToMap(valuesMap, values, '');
                setSelectOptions(
                    $select,
                    field,
                    valuesMap,
                    defaultValue,
                    optional,
                    readOnly,
                    responseError
                );
            },
            error: function (xhr, status, error) {
                setSelectOptions(
                    $select,
                    field,
                    [],
                    null,
                    false,
                    readOnly,
                    'An unknown error occurred'
                );
            },
        });
    } else {
        let keyValueArray = keyValue.split(',');
        let firstOnChangeFn = null;

        keyValueArray.forEach((keyValueCheck) => {
            $targetElement = $(Form.getFieldKeyLookup('#' + keyValueCheck));

            if ($targetElement.length) {
                let onChangeFn = function () {
                    setSelectOptions(
                        $select,
                        field,
                        new Map(),
                        null,
                        false,
                        readOnly,
                        '-- Loading ... --'
                    );

                    let currentKeyValues = [];
                    keyValueArray.forEach((currentKeyValueLookup) => {
                        let $thisSelect = $(Form.getFieldKeyLookup('#' + currentKeyValueLookup));
                        currentKeyValues.push($thisSelect.val());
                    });

                    $.ajax({
                        type: 'POST',
                        url: '/data/dynamic-data/',
                        cache: false,
                        data: {
                            data_type: 'values',
                            language: language,
                            module_db: moduleDb,
                            module_id: moduleId,
                            module_type: moduleType,
                            module_field: recordKey,
                            key_value: currentKeyValues.join(','),
                        },
                        success: function (response) {
                            let values = [];
                            if (response.success) {
                                if (
                                    response.data &&
                                    response.data.field &&
                                    response.data.field.values
                                ) {
                                    values = response.data.field.values;
                                }
                                let valuesMap = new Map();
                                addSelectValuesToMap(valuesMap, values, '');
                                setSelectOptions(
                                    $select,
                                    field,
                                    valuesMap,
                                    defaultValue,
                                    optional,
                                    readOnly,
                                    null
                                );
                            } else {
                                /*200 with error message*/
                                let responseError = response.error_message;
                                if (responseError == null) {
                                    responseError = 'An unknown error occurred';
                                }
                                setSelectOptions(
                                    $select,
                                    field,
                                    new Map(),
                                    null,
                                    false,
                                    readOnly,
                                    responseError
                                );
                            }
                        },
                        error: function (xhr, status, error) {
                            setSelectOptions(
                                $select,
                                field,
                                new Map(),
                                null,
                                false,
                                readOnly,
                                'An unknown error occurred'
                            );
                        },
                    });
                };

                $targetElement.change(onChangeFn);

                if (firstOnChangeFn === null) {
                    firstOnChangeFn = onChangeFn;
                }
            }
        });

        if (firstOnChangeFn) {
            firstOnChangeFn();
        }
    }
}

function addSelectValuesToMap(valuesMap, valuesObjects, prefix) {
    for (let valueIndex in valuesObjects) {
        let valueObject = valuesObjects[valueIndex];
        let valuesKey = arrayGet(valueObject, 'key', null);
        let valuesValue = arrayGet(valueObject, 'value', null);

        if (valueObject && valueObject instanceof Object && 'display' in valueObject) {
            valuesValue = arrayGet(valueObject, 'display', null);
            valueObject['value'] = valuesValue;
        }

        valueObject['value'] = (prefix === '' ? '' : prefix + ' ') + valueObject['value'];

        if (valuesKey !== null && valuesValue !== null) {
            valuesMap.set(valuesKey, valueObject);
        }

        let children = arrayGet(valueObject, 'children', null);
        if (children) {
            addSelectValuesToMap(valuesMap, children, '--' + prefix);
        }
    }
}

function setSelectOptions(
    $el,
    field,
    valuesMap,
    defaultValue,
    optional,
    readOnly,
    placeholderOverride
) {
    let multiple = arrayGet(field, 'type') === 'multi_select';
    let subObjectKey = arrayGet(field, 'subobject_key');
    let placeholder = placeholderOverride ?? arrayGet(field, 'placeholder');
    let blank = false;

    // Remove the current options, if any
    $el.find('option').remove();

    if (!multiple) {
        // Empty strings '', and null are currently considered to be 'empty' selections
        // for a select list. If the optional flag is true, these will be allowed, otherwise
        // they won't be (and be disabled).
        for (let [key, valueData] of valuesMap) {
            let disabled = valueData?.disabled;

            if (!disabled && !key) {
                if (!optional) {
                    // Disable this value is not optional
                    valueData.disabled = true;
                    valuesMap.set(key, valueData);
                } else {
                    // Otherwise flag that we have a blank
                    blank = true;
                }
            }
        }
    }

    if (placeholder) {
        let placeholderAttrs = {
            value: '',
            text: placeholder,
        };

        if (multiple || blank) {
            placeholderAttrs['disabled'] = 'disabled';
        }

        // Blank will be set if not already
        blank = true;

        if (!defaultValue) {
            placeholderAttrs['selected'] = 'selected';
        }

        $el.append($('<option>', placeholderAttrs));
    }

    if (
        !multiple &&
        (optional || (readOnly && (defaultValue == null || defaultValue == ''))) &&
        !blank
    ) {
        let optionAttrs = {
            value: '',
        };

        if (defaultValue === null || defaultValue === '') {
            optionAttrs['selected'] = 'selected';
        }

        $el.append($('<option>', optionAttrs));
    }

    defaultFound = false;

    for (let key of valuesMap) {
        let valueData = key[1];
        let label = valueData.value || valueData.display;
        let disabled = valueData.disabled;
        let optionAttrs = { value: valueData.key, text: label };

        if (multiple) {
            optionAttrs['name'] = valueData.key + '[]';
        }

        if (Array.isArray(defaultValue)) {
            if (subObjectKey) {
                for (let itemIndex in defaultValue) {
                    let subObjectValue = arrayGet(defaultValue[itemIndex], subObjectKey);
                    if (subObjectValue === valueData.key) {
                        optionAttrs.selected = 'selected';
                        defaultFound = true;
                        break;
                    }
                }
            } else {
                if (defaultValue.includes(valueData.key)) {
                    optionAttrs.selected = 'selected';
                    defaultFound = true;
                }
            }
        } else {
            if (defaultValue == valueData.key) {
                optionAttrs.selected = 'selected';
                defaultFound = true;
            }
        }

        if (disabled) {
            optionAttrs.disabled = 'disabled';
        }

        $el.append($('<option>', optionAttrs));
    }

    // See if we need to add in an invalid option warning. Note: it may be suppressed by setting "suppress_default_check" for the field.
    if (defaultValue && !defaultFound && !field.suppress_default_check) {
        $el.append(
            $('<option>', {
                value: defaultValue,
                text: '[Please choose a different option.]',
                selected: 'selected',
            })
        );

        $el.parent().find('.soda-form-invalid-selection-warning').remove();

        $el.after(
            $(`
            <div class="soda-form-annotation soda-form-invalid-selection-warning text-danger">
                This select box has been set to an option that is no longer valid. Pick a different one and re-save the form to remove this warning.
            </div>
        `)
        );

        // Remove the warning and the invalid option when a valid option is selected.
        $el.on('change', function () {
            if ($(this).val() !== defaultValue) {
                $(this).parent().find('.soda-form-invalid-selection-warning').remove();
                $(this).find(`option[value="${defaultValue}"]`).remove();
            }
        });
    } else {
        // Make sure the warning isn't shownn if the select list is changed. This can happen on dynanmic loading select lists in some scenarios
        $el.parent().find('.soda-form-invalid-selection-warning').remove();
    }

    // The options are updated, and likely a new one selected, send out a change in case there is
    // anything waiting on this field
    $el.trigger('change');

    return $el;
}

function joinClasses(classesArray) {
    let result = '';
    let arrayLength = classesArray.length;
    for (let i = 0; i < arrayLength; i++) {
        item = classesArray[i];

        if (item && item.length) {
            result += result.length ? ' ' + item : item;
        }
    }

    return result;
}

function filterClasses(classString, filterClassesArray) {
    let arrayLength = filterClassesArray.length;
    for (let i = 0; i < arrayLength; i++) {
        item = filterClassesArray[i];

        if (item && item.length) {
            classString = classString.replace(item, '');
        }
    }

    return classString.replace('  ', ' ');
}

function arrayGet(jsonArray, key, defaultValue) {
    if (!jsonArray || !key) {
        return defaultValue;
    }

    let selectedItem = jsonArray;
    let convertedKey = key.replace(/\[(\S+?)\]/g, '.$1');
    let keys = convertedKey.split('.');

    // Go through the array and find the key, if not found pass back the default value
    let arrayLength = keys.length;
    for (let i = 0; i < arrayLength; i++) {
        let selectedKey = keys[i];

        if (
            selectedItem !== null &&
            typeof selectedItem !== 'undefined' &&
            typeof selectedItem === 'object' &&
            selectedKey in selectedItem
        ) {
            selectedItem = selectedItem[selectedKey];
        } else if (
            selectedItem !== null &&
            typeof selectedItem !== 'undefined' &&
            Array.isArray(selectedItem) &&
            !isNaN(selectedKey) &&
            parseInt(selectedKey) < selectedItem.length
        ) {
            selectedItem = selectedItem[parseInt(selectedKey)];
        } else {
            return defaultValue;
        }
    }

    return selectedItem;
}

var Form = (function () {
    return {
        getFieldKey: function (field, keyPrefix, arrayIndex = null) {
            // Creates a field key based on the field settings and an optional keyPrefix
            // Note that fields cannot have a period, so commas are used instead
            let key = arrayGet(field, 'key').replace('.', ',');
            let usePrefix = arrayGet(field, 'use_prefix', true);

            if (key) {
                if (arrayIndex !== null) {
                    if (usePrefix && keyPrefix) {
                        return keyPrefix + '[' + arrayIndex + ']' + '[' + key + ']';
                    } else {
                        return key + '[' + arrayIndex + ']';
                    }
                } else {
                    if (usePrefix && keyPrefix) {
                        if (keyPrefix.slice(-1) == ']') {
                            // If it is already part of an array, then this should be too
                            return keyPrefix + '[' + key + ']';
                        } else {
                            // Need to use a comma rather than period
                            return keyPrefix + ',' + key;
                        }
                    } else {
                        return key;
                    }
                }
            } else if (usePrefix) {
                return keyPrefix;
            }

            return null;
        },
        getExtraDataKeyLookup: function (key) {
            if (key) {
                let convertedKey = key.replaceAll(/\[\d+\]/g, '');
                return convertedKey.replace(/\[(.+)\]/g, '.$1');
            }

            return key;
        },
        getFieldKeyLookup: function (key) {
            // Any attempts to do a lookup with a period will be converted to a comma, as wells as escaped value
            // https://learn.jquery.com/using-jquery-core/faq/how-do-i-select-an-element-by-an-id-that-has-characters-used-in-css-notation/
            if (key) {
                let convertedKey = key.replace(/\./g, ',');
                return convertedKey.replace(/(:|\.|\[|\]|,|=|@)/g, '\\$1');
            }

            return key;
        },
        getRecordKey: function (key) {
            if (key) {
                // When looking up a key in the record, convert any commas to periods
                return key.replace(/,/g, '.');
            }

            return key;
        },
        getTabKey: function (field, keyPrefix, arrayIndex) {
            // Tabs cannot handle commas at all, so these will be replaced with "__"
            let tabKey = Form.getFieldKey(field, keyPrefix, arrayIndex) + '_tab';
            return tabKey.replace(/,/g, '__');
        },
        getGroupSizeClass: function (field, fieldTypes) {
            let fieldSize = arrayGet(field, 'field_size', null);

            if (fieldSize) {
                switch (fieldSize) {
                    case 'small':
                        return 'soda-form-group-small';

                    case 'medium':
                        return 'soda-form-group-medium';

                    case 'large':
                        return 'soda-form-group-large';
                }
            }

            // Creates a field key based on the field settings and an optional keyPrefix
            // Note that fields cannot have a period, so commas are used instead
            let type = arrayGet(field, 'type');

            switch (type) {
                case 'array':
                case 'subfield_array':
                case 'contacts':
                    return 'soda-form-group-subfields';

                case 'header':
                    return 'soda-form-group-large';

                case 'email':
                case 'phone':
                case 'date':
                case 'datetime':
                case 'datetime_tz':
                case 'time':
                case 'coordinate':
                    return 'soda-form-group-medium';
            }

            let fieldTypeInfo = arrayGet(fieldTypes, type, null);
            let fieldTypeElement = arrayGet(fieldTypeInfo, 'element', '');
            switch (fieldTypeElement) {
                case 'file':
                case 'text':
                case 'textarea':
                    return 'soda-form-group-large';

                case 'checkbox':
                    return 'soda-form-group-small';
            }

            return 'soda-form-group-medium';
        },
        addTabs: function ($el, data) {
            // data: { id: string, active: boolean, title: string, template: string }
            $tabContainer = $('<div>', {
                class: 'soda-tabs-container soda-form-tabs-container',
            });

            $tabHeadersUl = $('<ul>');
            $tabContainer.append($tabHeadersUl);

            for (let i = 0; i < data.length; i++) {
                let tab = data[i];
                $tabHeadersUl.append(
                    $('<li>', {
                        class: tab.active ? 'soda-tab-link-active' : '',
                    }).append(
                        $('<a>').html(tab.title)
                    )
                );

                $tabContainer.append(
                    $('<div>', {
                        id: tab.id,
                    }).append($('<div>', { class: 'control-group' }).append(tab.template))
                );
            }

            site.tabs($tabContainer);
            $el.append($tabContainer);
            return $tabContainer;
        },
        addLabel: function (
            $el,
            formSettings,
            keyPrefix,
            arrayIndex,
            field,
            fieldTypes,
            extraData
        ) {
            let type = arrayGet(field, 'type', '');
            let key = Form.getFieldKey(field, keyPrefix, arrayIndex);
            let name = arrayGet(field, 'value.EN', '');
            let readOnly =
                arrayGet(formSettings, 'read_only', false) || arrayGet(field, 'read_only', false);

            if (type === 'rank' || type === 'uuid') {
                // Do nothing
            } else if (type === 'header') {
                // Add in the heading for
                let $heading = $('<h5>', {
                    class: 'form-header',
                    text: name,
                });
                $el.append($heading);
            } else {
                if ((type === 'datetime' || type === 'datetime_tz') && extraData) {
                    // If a timezone is specified in the extra data than added it
                    // to the label for any datetime
                    let timezoneInfo = arrayGet(extraData, 'timezone_info', null);
                    if (timezoneInfo) {
                        let timezoneName = arrayGet(timezoneInfo, 'timezone_name', null);

                        if (timezoneName) {
                            name += ' (' + timezoneName + ')';
                        }
                    }
                } else if (type === 'checkbox_array') {
                    let optional = arrayGet(field, 'optional', false) || field.custom; // Custom fields are always optional for now

                    if (optional) {
                        name += ' [Optional]';
                    }
                }

                if (type === 'multi_select') {
                    $labelContainer = $('<div>', { class: 'soda-form-label-container' });
                    $el.append($labelContainer);

                    $labelContainer.append(
                        '<label class="control-label form-label soda-form-label" for="' +
                            key +
                            '">' +
                            name +
                            '</label>'
                    );

                    if (!readOnly) {
                        // Added in "Select All | Clear All" buttons
                        $labelControlsDiv = $('<div>', { class: 'text-right' });
                        $labelControlsDiv.append(
                            $('<a>', {
                                href: 'Javascript:void(0);',
                                class: 'form_select_all',
                            }).html('Select All')
                        );
                        $labelControlsDiv.append('&nbsp;|&nbsp');
                        $labelControlsDiv.append(
                            $('<a>', {
                                href: 'Javascript:void(0);',
                                class: 'form_clear_all',
                            }).html('Clear All')
                        );
                        $labelContainer.append($labelControlsDiv);

                        $labelControlsDiv.find('.form_select_all').on('click', function () {
                            $(
                                '#' + Form.getFieldKeyLookup(key + '[]') + ' option:not([disabled])'
                            ).prop('selected', 'selected');
                        });

                        $labelControlsDiv.find('.form_clear_all').on('click', function () {
                            $(
                                '#' + Form.getFieldKeyLookup(key + '[]') + ' option:not([disabled])'
                            ).prop('selected', '');
                        });

                        this.addFieldInfoMessage($labelControlsDiv, keyPrefix, field, fieldTypes);
                    } else {
                        this.addFieldInfoMessage($labelContainer, keyPrefix, field, fieldTypes);
                    }
                } else {
                    $el.append(
                        '<label class="control-label form-label soda-form-label" for="' +
                            key +
                            '">' +
                            name +
                            '</label>'
                    );

                    this.addFieldInfoMessage($el, keyPrefix, field, fieldTypes);
                }
            }
        },
        addFieldInfoMessage: function ($el, keyPrefix, field, fieldTypes) {
            let versionAnnotation = arrayGet(field, 'version_annotation', '');
            let userNotes = arrayGet(field, 'user_notes', '');

            let title = [userNotes, versionAnnotation]
                .filter(function (value) {
                    return value.trim() !== '';
                })
                .join(' ');

            if (title !== '') {
                $el.append(
                    '<a data-toggle="tooltip" data-container="body" data-tooltip-container-class="field-info-tooltip-container" data-placement="right" title="' +
                        title +
                        '" style="margin-left: 5px; cursor: pointer;">' +
                        '  <i class="fas fa-question-circle"></i>' +
                        '</a>'
                );
            }
        },
        addAnnotation: function ($el, formSettings, keyPrefix, field, fieldTypes, extraData) {
            let annotation = arrayGet(field, 'annotation', null);
            let type = arrayGet(field, 'type', null);
            let fieldTypeInfo = arrayGet(fieldTypes, type, null);
            let maxSize = arrayGet(field, 'max_size', arrayGet(fieldTypeInfo, 'max_size'));
            let maxSizeFormatted = Utils.bytesToHumanReadable(maxSize);
            if (maxSizeFormatted) {
                if (annotation) {
                    annotation += '(Max: ' + maxSizeFormatted + ')';
                } else {
                    annotation = 'Max: ' + maxSizeFormatted;
                }
            }

            if (annotation) {
                $el.append(
                    $('<span>', {
                        class: 'soda-form-annotation',
                    }).html(annotation)
                );
            }
        },
        addAllowedTagsNote: function ($el, field) {
            if (Array.isArray(field.allowed_html_tags)) {
                //Fetch the set of tags associated with the latest version
                const versions = field.allowed_html_tags.map((i) => i.minVersion);
                const sorted = versions.sort((a, b) => b - a); //sort inverse
                let allowed_tags = field.allowed_html_tags.find(
                    (i) => i.minVersion === sorted[0]
                ).tags;

                if (allowed_tags) {
                    $el.append(
                        $('<div>', {
                            class: 'soda-form-annotation-align-right',
                        }).html(`Allowed tags: ${allowed_tags}`)
                    );
                }
            }
        },
        addFieldToForm,
        addElementToForm: function (
            $el,
            formSettings,
            keyPrefix,
            arrayIndex,
            field,
            fieldTypes,
            language,
            extraData,
            record,
            inputFields
        ) {
            addElementToForm(
                $el,
                formSettings,
                keyPrefix,
                arrayIndex,
                field,
                fieldTypes,
                language,
                extraData,
                record,
                inputFields
            );
        },
        addFieldDependencies: function ($form, keyPrefix, arrayIndex, field) {
            addFieldDependencies($form, keyPrefix, arrayIndex, field);
        },
        emptyArray(key) {
            $containerDiv = $(Form.getFieldKeyLookup('#' + key));
            // Remove items
            $containerDiv.empty();
            // Update entry count
            $currentEntries = $containerDiv
                .parent()
                .find('.form_array_entry_count .current_entries');
            $currentEntries.html('0');
            // Add in "No items currently in list"
            checkArrayItemEmpty($containerDiv);
        },
    };
})();

window.manageForm = manageForm;
window.arrayGet = arrayGet;
window.Form = Form;
