var EditorManager = (function ($, CKEDITOR) {
    var styleId = 'custom_fonts';

    var EditorManager = function () {};

    var FILE_BROWSER_IMAGE_UPLOAD_BASE_URL = '/tenant-company/uploads/editor?upload_type=image';
    var FILE_BROWSER_UPLOAD_BASE_URL = '/tenant-company/uploads/editor?upload_type=file';

    EditorManager.ACTION_TYPE_BACKEND_HELP = 'backend_help';

    EditorManager.getBackendConfigOptions = function (actionType, token) {
        return {
            filebrowserUploadMethod: 'form',
            filebrowserImageUploadUrl:
                '/backend/uploads/editor?upload_type=image&action_type=' +
                actionType +
                '&_token=' +
                token,
            filebrowserUploadUrl:
                '/backend/uploads/editor?upload_type=file&action_type=' +
                actionType +
                '&_token=' +
                token,
        };
    };

    EditorManager.getDefaultConfigOptions = function (token) {
        return {
            versionCheck: false,
            filebrowserUploadMethod: 'form',
            filebrowserImageUploadUrl: FILE_BROWSER_IMAGE_UPLOAD_BASE_URL + '&_token=' + token,
            filebrowserUploadUrl: FILE_BROWSER_UPLOAD_BASE_URL + '&_token=' + token,
        };
    };

    EditorManager.prototype.setFonts = function (fonts) {
        if (!fonts) {
            fonts = [];
        }

        this.fonts = fonts;
    };

    EditorManager.prototype.initialize = function ($ckeditor, configOptions) {
        var self = this;
        var fonts = self.fonts;

        if (!$ckeditor.length) {
            return console.warn('Editor element not found.');
        }

        if (!configOptions.filebrowserImageUploadUrl || !configOptions.filebrowserUploadUrl) {
            return console.warn('Missing file browser upload URL.');
        }

        var styleText = '<style id="' + styleId + '" type="text/css">';

        // Ensure that custom fonts CSS is only added once
        // CKEDITOR css is for the editor font. styleText is for the dropdown menu
        if (!CKEDITOR.cssAdded) {
            $.each(fonts, function (_i, font) {
                if (font['name'] && font['url']) {
                    var cssFontFace = [
                        '@font-face {',
                        'font-family: "' + font['name'] + '";',
                        'font-style: normal;',
                        'font-weight: 400;',
                        'src: local("' + font['name'] + '"), url(' + font['url'] + ');',
                        '}',
                    ].join('');

                    styleText += cssFontFace + '\n';
                    CKEDITOR.addCss(cssFontFace);
                }
            });
            CKEDITOR.cssAdded = true;
        }
        styleText += '</style>';

        // The font plugin has to be loaded first before the config font_names property is set.
        // Therefore, destroy the editor and rebuild it using the default font_names + the custom
        // fonts.
        CKEDITOR.once('instanceReady', function (evt) {
            var fontNames = '';
            $.each(fonts, function (_i, font) {
                if (font['name'] && font['url']) {
                    fontNames += font['name'] + '/' + font['name'] + '; ';
                }
            });
            fontNames += CKEDITOR.config.font_names;

            var editor = evt.editor;
            if (editor) {
                editor.destroy(true);
            }

            editor = CKEDITOR.replace(
                $ckeditor[0],
                $.extend(
                    {},
                    {
                        font_names: fontNames,
                        on: {
                            selectionChange: function (e) {
                                // Ensure that the style is not added to the dropdown twice
                                $head = $('.cke_panel_frame').contents().find('head');
                                if (!$head.find('#' + styleId).length) {
                                    $head.append(styleText);
                                }
                            },
                        },
                    },
                    configOptions
                )
            );

            editor.timestamp = Math.random().toString(36).substring(7);

            // On the second setup, make sure the bodyClass and bodyId are set
            // the configOptions don't seem to work properly without this.
            if (configOptions.bodyClass || configOptions.bodyId || configOptions.contentsCss)
                CKEDITOR.once('instanceReady', function (evt2) {
                    var editorBody = evt2.editor.document.getBody();

                    if (configOptions.bodyId) {
                        editorBody.setAttribute('id', configOptions.bodyId);
                    }

                    if (configOptions.bodyClass) {
                        if (Array.isArray(configOptions.bodyClass)) {
                            configOptions.bodyClass.forEach((value) => {
                                editorBody.addClass(value);
                            })
                        } else {
                            editorBody.addClass(configOptions.bodyClass);
                        }
                    }

                    if (configOptions.contentsCss) {
                        evt2.editor.document.appendStyleSheet(configOptions.contentsCss);
                    }
                });
        });

        // Fire the instanceReady event
        CKEDITOR.replace($ckeditor[0], {
            // Make sure the version check is not done
            versionCheck: false
        });
    };

    return EditorManager;
})(jQuery, CKEDITOR);

window.EditorManager = EditorManager;
