var SessionManager = (function () {

    var SessionManager = function () {
    };

    SessionManager.prototype.pushCurrentPage = function () {
        var pageHistoryJson = sessionStorage.getItem('page_history');
        var pageHistory = [];
        if (pageHistoryJson) {
            pageHistory = JSON.parse(pageHistoryJson);
        }

        pageHistory.push(window.location.href);
        pageHistory = pageHistory.slice(-5);

        sessionStorage.setItem('page_history', JSON.stringify(pageHistory));
    };

    // This script needs to be placed near the top of the JS files to ensure
    // that page is pushed before the check occurs.
    SessionManager.prototype.hasPageChanged = function () {
        var pageHistoryJson = sessionStorage.getItem('page_history');
        var pageHistory = [];
        if (pageHistoryJson) {
            pageHistory = JSON.parse(pageHistoryJson);
        }

        return pageHistory[pageHistory.length - 1] === pageHistory[pageHistory.length - 2];
    };

    return SessionManager;
})();

var sessionManager = new SessionManager();
sessionManager.pushCurrentPage();

window.SessionManager = SessionManager;
