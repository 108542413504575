var ColumnSettingsDropdown = (function ($, Utils, site, Cookies) {

    const COLUMN_DISPLAY_MODE_AUTO = 'column_display_mode_auto';
    const COLUMN_DISPLAY_MODE_DEFINED = 'column_display_mode_defined';

    var ColumnSettingsDropdown = function (_tableId, _columns, options) {
        let tableId = _tableId;
        let dropdownId = _tableId + '_dropdown';
        let columnVisibilityMapKey = dropdownId + '_column_visibility_map';

        let cookiePath = window.location.href;
        let cookieExpiry = 2 / 24;

        let columns = _columns;
        let columnsArray = [];
        let columnsId = 0;

        let $table = $('#' + tableId);
        let $dropdownRoot = $('#' + dropdownId);
        let $dropdown = $dropdownRoot.find('a.button.dropdown');
        let $dropdownListItems = $dropdownRoot.find('.dropdown-menu li.checkbox.toggleColumnVisibility');

        let defaults = {
            columnDisplayMode: COLUMN_DISPLAY_MODE_AUTO
        };

        options = $.extend({}, defaults, options);

        for (let key in columns) {
            let priority = "999";
            let size = "";
            let wrap = true;
            let visible = true;
            let column = columns[key];
            let customData = column.customData;

            if (customData !== undefined) {
                priority = customData.priority ? customData.priority : priority;
                size = customData.size ? customData.size.toString() : size;
                wrap = customData.wrap === false ? customData.wrap : true;
                visible = Utils.isNullorUndefined(customData.visible) ? true : !!customData.visible;
            }

            columnsArray.push({
                columnId: columnsId.toString(),
                priority: priority,
                size: size,
                wrap: wrap,
                visible: visible
            });

            columnsId++;
        }

        // Sort the colummsArray based on priority (and column id, if the priority is the same)
        columnsArray.sort(function (a, b) {
            if (a.priority === b.priority) {
                return a.columnId - b.columnId;
            } else {
                return a.priority - b.priority;
            }
        });

        initialize();

        function initialize() {
            // Do initial table sizing
            draw();

            // Handle clicking on the main drop down button to show it
            $dropdown.on('click', function (event) {
                $(this).parent().toggleClass('open');
            });

            // Handle clicking away to close the drop down
            $('html, body').on('click', function (e) {
                if (!$dropdown.is(e.target)
                        && $dropdown.has(e.target).length === 0
                        && $('.open').has(e.target).length === 0
                        ) {
                    closeColumnSettingsDropdown();
                }
            });

            // Handle the drop down 'Close' button
            $dropdownRoot.find('ul.dropdown-menu button').click(function () {
                closeColumnSettingsDropdown();
            });

            // Handle clicking on the item label so it acts like on the checkbox
            $dropdownListItems.find('label').click(function (e) {
                e.preventDefault();
                e.stopPropagation();

                $(this).find('input').click();
            });

            // Handle clicking on the item checkbox
            $dropdownListItems.find('input').click(function (e) {
                e.stopPropagation();

                var id = $(this).parent().parent().index();
                var checkbox = $(this);
                var isVisible = checkbox.is(':checked');

                setColumnVisibility(id, isVisible);
                updateColumnVisibilityCookie();
            });

            // Handle clicking on menu, but not directly on the checkbox ite,
            $dropdownListItems.click(function (e) {
                var id = $(this).index();
                var checkbox = $(this).find('input');
                var isVisible = checkbox.is(':checked');

                checkbox.prop("checked", !isVisible);
                setColumnVisibility(id, !isVisible);
                updateColumnVisibilityCookie();
            });

            site.attachDropdownToBody($dropdownRoot);

            // Handle pagination
            $table.on('draw.dt', function(e) {
                applyColumnVisibilities();
            });
        }

        function draw() {
            resetColumnSizes();
            sizeTable();
            applyColumnVisibilitiesFromCookie();
        }

        function resetColumnSizes() {
            const MAX_INITIAL_COLUMN_WIDTH = 500;
            const MIN_RESIZE_COLUMN_WIDTH = 300;

            for (let i = 0; i < columnsArray.length; i++) {
                let column = columnsArray[i];
                let columnId = column.columnId;
                // When looking up nth child, they are 1 based index
                let columnChild = Number(columnId) + 1;
                let columnSize = column.size;
                let columnWrap = column.wrap;

                let $header = $('table#' + tableId + ' tr th:nth-child(' + columnChild + ')');
                let $cell = $('table#' + tableId + ' tr td:nth-child(' + columnChild + ')');

                // Set column width if we specified it
                if (columnSize && columnSize !== "") {
                    $header.css('width', columnSize + 'px');
                    $cell.css('width', columnSize + 'px');
                }

                if (columnWrap === false) {
                    // Wrap column to prevent breaking the tree layout
                    $cell.css('white-space', 'nowrap');
                }

                // Break very long lines
                if ($header.outerWidth() > MAX_INITIAL_COLUMN_WIDTH) {
                    $cell.css('white-space', '');
                    $header.css({
                        'min-width': MIN_RESIZE_COLUMN_WIDTH + 'px'
                    });
                    $cell.css({
                        'min-width': MIN_RESIZE_COLUMN_WIDTH + 'px',
                        'word-wrap': 'break-word',
                        'word-break': 'break-all'
                    });
                }
            }
        }

        function sizeTable() {
            let $modal = $('.modal.in');
            let $box = ($modal.length) ? $modal.find('.modal-body') : $table.closest('div.box4table').first();
            let $wrapper = $box.find('.dataTables_wrapper').first();

            let leftPadding = parseFloat($wrapper.css('padding-left'));
            let rightPadding = parseFloat($wrapper.css('padding-right'));
            let availableWidth = $box.width() - leftPadding - rightPadding; // minus padding of .dataTables_wrapper
            let currentWidth = 0;

            for (let i = 0; i < columnsArray.length; i++) {
                let column = columnsArray[i];
                let columnId = column.columnId;
                let columnPriority = column.priority;
                let columnVisible = column.visible;
                let columnWidth = column.size;

                let elIndex = parseInt(columnId) + 1;
                let $header = $('table#' + tableId + ' tr th:nth-child(' + elIndex + ')');

                // Fet column width if not specified
                if (!columnWidth || columnWidth === "") {
                    columnWidth = $header.outerWidth();
                }

                if (columnVisible) {
                    // Add to total width if the column is visible
                    currentWidth += parseInt(columnWidth);
                }

                let $checkbox = $dropdownRoot.find('li.toggleColumnVisibility:nth-child(' + elIndex + ')').find('input[type="checkbox"]');
                if (options.columnDisplayMode === COLUMN_DISPLAY_MODE_DEFINED) {
                    // Whether or not a column should be shown is defined
                    if (!columnVisible) {
                        setColumnVisibility(columnId, false);

                        $checkbox.prop('checked', false);
                    } else {
                        $checkbox.prop('checked', true);
                    }
                } else {
                    // Try and show as many columns as possible (as long as they are visible)
                    // Hide if current is bigger than available width and priority bigger than 0
                    // 0 priority should always be displayed initially
                    if (!columnVisible || (currentWidth >= availableWidth && columnPriority > 0)) {
                        setColumnVisibility(columnId, false);

                        $checkbox.prop('checked', false);
                    } else {
                        $checkbox.prop('checked', true);
                    }
                }
            }
        }

        function setColumnVisibility(colIndex, isVisible) {
            let elIndex = parseInt(colIndex) + 1;
            let th = 'table#' + tableId + ' tr th:nth-child(' + elIndex + ')';
            let td = 'table#' + tableId + ' tr td:nth-child(' + elIndex + ')';
            let $col = $(th + ', ' + td);

            if (isVisible) {
                $col.show();
            } else {
                $col.hide();
            }
        }

        // Go over the column visibility again using cookie
        function applyColumnVisibilitiesFromCookie() {
            let columnVisibilityMapValue = Cookies.get(columnVisibilityMapKey);

            if (!columnVisibilityMapValue) {
                return;
            }

            let columnVisibilityMap = JSON.parse(columnVisibilityMapValue);
            if (!compareKeys(columnVisibilityMap, getColumnVisibilityMap())) {
                // Columns have changed
                Cookies.remove(columnVisibilityMapKey, {path: cookiePath});
                return;
            }

            applyColumnVisibilitiesFromMap(columnVisibilityMap);
        }

        function compareKeys(a, b) {
            let aKeys = Object.keys(a).sort();
            let bKeys = Object.keys(b).sort();

            return JSON.stringify(aKeys) === JSON.stringify(bKeys);
        }

        function closeColumnSettingsDropdown() {
            $dropdown.parent().removeClass('open');
        }

        function getColumnVisibilityMap() {
            let columnVisibilityMap = {};

            $dropdownListItems.each(function (index) {
                let dataId = $(this).data('id');
                let visibility = $(this).find('input[type="checkbox"]').prop("checked");

                columnVisibilityMap[dataId] = visibility;
            });

            return columnVisibilityMap;
        }

        function updateColumnVisibilityCookie() {
            let columnVisibilityMap = getColumnVisibilityMap();

            Cookies.set(columnVisibilityMapKey, columnVisibilityMap, {
                expires: cookieExpiry,
                path: cookiePath
            });
        }

        function applyColumnVisibilities() {
            $dropdownListItems.each(function (index) {
                let visibility = $(this).find('input[type="checkbox"]').prop('checked');
                setColumnVisibility(index, visibility);
            });
        }

        function applyColumnVisibilitiesFromMap(columnVisibilityMap) {
            $dropdownListItems.each(function (index) {
                var dataId = $(this).data('id');
                var visibility = false;

                if (dataId in columnVisibilityMap) {
                    visibility = columnVisibilityMap[dataId];
                }

                setColumnVisibility(index, visibility);
                $(this).find('input[type="checkbox"]').prop('checked', visibility);
            });
        }

        this.refresh = draw;
    };

    ColumnSettingsDropdown.COLUMN_DISPLAY_MODE_AUTO = COLUMN_DISPLAY_MODE_AUTO;
    ColumnSettingsDropdown.COLUMN_DISPLAY_MODE_DEFINED = COLUMN_DISPLAY_MODE_DEFINED;

    return ColumnSettingsDropdown;
})(jQuery, Utils, site, Cookies);

window.ColumnSettingsDropdown = ColumnSettingsDropdown;
