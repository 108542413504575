/**
 * DashboardMenu
 * A wrapper around the Nestable2 jQuery library for creating
 * nestable menus for the dashboard.
 */
var DashboardMenu = (function ($, window) {
    /**
     * Initialize the Dashboard Menu
     *
     * @param {object} $root - The root element (i.e $('#nestable'))
     */
    DashboardMenu.prototype.initialize = function (
        $root,
        displayStyle,
        formFields,
        language,
        menuContainer,
        options
    ) {
        var options = $.extend({}, this.defaults, options);

        if (!window.JSON) {
            alert('JSON browser support required for this page.');
            return;
        }

        $root.nestable(options);

        this.newIdCount = 1;
        this.formFields = formFields;
        this.language = language;
        this.displayStyle = displayStyle;
        this.menuContainer = menuContainer;

        // Save elements
        this.$jsonOutput = $('#json-output');
        this.$menuEditorWrapper = $('.menu-editor-wrapper');
        this.$menuEditorItem = $('#menu-editor-item');
        this.$menuEditorListItem = $('#menu-editor-list-item');

        this.noIconDefaultUrl = $('#menu_settings_none').val();
        this.iconDefaultUrl = $('#menu_settings_default').val();

        this.$nestableList = $root.find('> .dd-list');
        this.$root = $root;

        // Update JSON with new item
        this.itemsArray = [];
        this.updateOutput(this.$root.data('output', this.$jsonOutput, false));

        this.bindActions();

        // Allow nesting for only parents
        $.each(this.itemsArray, function (i, item) {
            var $item = $root.find('[data-id="' + item.id + '"]');
            if ($item.data('isparent')) {
                $item.removeClass('dd-nochildren');
            }
        });

        initDashboardMenuScroll($root, $root.closest('.modal'));
    };

    /**
     * Adds an entry to the menu
     *
     * @param {object} item - entry object
     */
    DashboardMenu.prototype.addToMenu = function (item) {
        var self = this;

        var databaseName = item.getDatabaseName();
        var moduleId = item.getModuleId();
        var recordId = item.getRecordId();
        var name = item.getName();
        var title = item.getTitle();
        var isParent = item.isParent() ? '1' : '0';

        // Only allow adding children for parents
        var listClass = item.isParent() ? 'dd-item' : 'dd-item dd-nochildren';

        var newSlug = '0'; // Parent
        var newId = 'new-' + self.newIdCount;

        var listAttrs = {
            class: listClass,
            'data-id': newId,
            'data-reference': newId,
            'data-slug': newSlug,
            'data-moduleid': moduleId,
            'data-record_id': recordId,
            'data-database': databaseName,
            'data-isparent': isParent,
            'data-name': name,
            'data-title': title,
            'data-disabled': '0',
            'data-new': '1',
            'data-deleted': '0',
        };

        var listHtml = '<li ';
        var listAttrStrs = $.map(listAttrs, function (attr, key) {
            return key + '="' + attr + '"';
        });

        listHtml += listAttrStrs.join(' ');
        listHtml += '>';
        listHtml +=
            '<div class="dd-handle flex">' +
            '   <div class="menu-item-icon"></div>' +
            '   <span class="menu-item-name">' + name + '</span>' +
            '</div> ' +
            '<span class="button-edit btn btn-primary btn-xs pull-right" ' +
            'data-owner-id="' +
            newId +
            '">' +
            '<i class="fas fa-pencil-alt" aria-hidden="true"></i>' +
            '</span>' +
            '<span class="button-delete btn btn-danger btn-xs pull-right" ' +
            'data-owner-id="' +
            newId +
            '"> ' +
            '<i class="fas fa-times" aria-hidden="true"></i> ' +
            '</span>' +
            '</li>';

        var $newItem = $(listHtml).appendTo(self.$nestableList);

        self.newIdCount++;

        // Update JSON with new item
        self.updateOutput(self.$root.data('output', self.$jsonOutput), true);

        // Bind events to new menu item
        $newItem.find('.button-delete').on('click', self.deleteFromMenu);
        $newItem.find('.button-edit').on('click', self.showItemEditor);
    };

    DashboardMenu.prototype.bindActions = function () {
        var self = this;

        self.$root.find('.button-edit').on('click', self.showItemEditor);
        self.$root.find('.button-delete').on('click', self.deleteFromMenu);

        self.$root.find('.button-enable').on('click', self.enableFromMenu);
        self.$root.find('.button-disable').on('click', self.disableFromMenu);

        self.$menuEditorWrapper.find('#item_editor_save_button').on('click', self.saveItem);
        self.$menuEditorWrapper.find('#item_editor_cancel_button').on('click', self.hideItemEditor);

        $('#menu-editor-item .editContentButton').on('click', function() {
            const directLink = $(this).data('direct-link');
            if (directLink) {
                window.location.href = directLink;
            }
        })

        self.initializeIconControls();
    };

    DashboardMenu.prototype.updateOutput = function (e, needsSave) {
        var self = this;
        var list = e.length ? e : $(e.target);
        var output = list.data('output');

        if (output) {
            output.val(window.JSON.stringify(list.nestable('serialize')));
        }

        // Keep a handle on the current state of the tree as it is updated
        // when an item is picked up
        self.itemsArray = self.$root.nestable('toArray');

        if (self.menuContainer && needsSave) {
            if (!self.menuContainer.hasClass('menu-container-needs-save')) {
                self.menuContainer.addClass('menu-container-needs-save');
                self.menuContainer.after(
                    $('<div>', {
                        class: 'row box margin-top-20 margin-bottom-20 margin-left-0 margin-right-0 menu-text-needs-save',
                    }).html('Dashboard needs to be saved')
                );
            }
        }
    };

    function DashboardMenu() {
        var self = this;

        this.ICON_TYPE = {
            FONT_AWESOME: 'fontawesome',
            INPUT: 'input',
            NONE: 'none',
        };

        this.MESSAGES = {
            MOVE_IN_CLEAR_CONFIG:
                'You are moving this item from a sub-level to the top-level of the Dashboard. Sub-level-specific configuration for this item will be cleared.',
            MOVE_OUT_CLEAR_CONFIG:
                'You are moving this item from the top-level of the Dashboard to a sub-level. Top-level-specific configuration for this item will be cleared.',
        };

        // Drag and drop defaults
        this.defaults = {
            maxDepth: 8,
            noDragClass: 'dd-nodrag',
            beforeDragStop: function ($main, $target, $targetPos) {
                // Compare the depths
                var depth = self.getDepth($target.data('id'));
                var isListItem = self.isListItem(depth);

                var newLevel = $targetPos.parentsUntil(self.$root.attr('id'), 'ol.dd-list').length;
                var newDepth = newLevel + 1;
                var newIsListItem = self.isListItem(newDepth);

                if (isListItem && !newIsListItem) {
                    var result = confirm(self.MESSAGES.MOVE_IN_CLEAR_CONFIG);
                    if (!result) {
                        return false;
                    }

                    // Clear the icon data
                    $target.data('icon_type', self.ICON_TYPE.NONE);
                    $target.data('icon_url', self.noIconDefaultUrl);
                    self.removeItemIcon($target);
                    self.updateIconControls(self.ICON_TYPE.NONE, self.noIconDefaultUrl);
                } else if (!isListItem && newIsListItem) {
                    var result = confirm(self.MESSAGES.MOVE_OUT_CLEAR_CONFIG);
                    if (!result) {
                        return false;
                    }

                    // Clear item form data
                    var basicEditFormData = self.getBasicEditFormData($target);
                    $.each(basicEditFormData.formFields, function (index, field) {
                        let key = field.key;
                        $target.data(key, null); // removeData will accidentally use HTML data attr
                    });

                    // Clear out the image placeholder (if image_url was populated it was just deleted in the loop above)
                    self.removeItemIcon($target);
                }
            },
            callback: function () {
                self.updateOutput(self.$root.data('output', self.$jsonOutput), true);
            },
        };

        /*
         * Get the depth of an item.
         */
        this.getDepth = function (id) {
            var itemsArray = self.itemsArray;
            var depth = 1;

            $.each(itemsArray, function (i, item) {
                if (item['id'].toString() === id.toString()) {
                    depth = item['depth'];
                    return false;
                }
            });

            return depth;
        };

        // Nested items are shown on the dashboard as a new list on tap
        this.isListItem = function (depth) {
            var isListItem = self.displayStyle === 'flat_list' && depth > 1;
            return isListItem;
        };

        this.deleteFromMenu = function () {
            var targetId = $(this).data('owner-id');
            var target = self.$root.find('[data-id="' + targetId + '"]');

            var result = confirm('Remove ' + target.data('name') + ' and all its subitems from the dashboard?');
            if (!result) {
                return;
            }

            // Remove children (if any)
            target.find('li').each(function () {
                self.deleteFromMenuHelper($(this));
            });

            // Remove parent
            self.deleteFromMenuHelper(target);

            // Update JSON
            self.updateOutput(self.$root.data('output', self.$jsonOutput), true);
        };

        this.deleteFromMenuHelper = function (target) {
            if (target.data('new') === 1) {
                // If target is a new item (not saved in backend), remove from DOM
                target.fadeOut(function () {
                    target.remove();
                    self.updateOutput(self.$root.data('output', self.$jsonOutput), true);
                });
            } else {
                // Otherwise, hide and mark it for deletion
                target.appendTo(self.$nestableList); // If children, move to the top level
                target.data('deleted', '1');
                target.fadeOut();
            }
        };

        this.disableFromMenu = function () {
            var targetId = $(this).data('owner-id');
            var target = self.$root.find('[data-id="' + targetId + '"]');

            // Remove children (if any)
            target.find('li').each(function () {
                self.disableFromMenuHelper($(this));
            });

            // Remove parent
            self.disableFromMenuHelper(target);
            console.log('Disabling from menu');

            // Update JSON
            self.updateOutput(self.$root.data('output', self.$jsonOutput), true);
        };

        this.enableFromMenu = function () {
            var targetId = $(this).data('owner-id');
            var target = self.$root.find('[data-id="' + targetId + '"]');

            // Children (if any)
            target.find('li').each(function () {
                self.enableFromMenuHelper($(this));
            });

            // Parent
            self.enableFromMenuHelper(target);
            console.log('Enabling from menu');

            // Update JSON
            self.updateOutput(self.$root.data('output', self.$jsonOutput), true);
        };

        this.disableFromMenuHelper = function (target) {
            var el = target.data('id');
            target.data('disabled', '1');
            target.attr('data-disabled', '1');
            target.addClass('dd-nodrag');
            self.$root.find('.button-disable[data-owner-id="' + el + '"]').hide();
            self.$root.find('.button-enable[data-owner-id="' + el + '"]').show();
        };

        this.enableFromMenuHelper = function (target) {
            var el = target.data('id');
            target.data('disabled', '0');
            target.attr('data-disabled', '0');
            self.$root.find('.button-disable[data-owner-id="' + el + '"]').show();
            self.$root.find('.button-enable[data-owner-id="' + el + '"]').hide();
        };

        this.hideItemEditor = function () {
            self.$menuEditorItem.add(self.$menuEditorListItem).hide();
        };

        this.showItemEditor = function () {
            // Get the target
            var targetId = $(this).data('owner-id');
            var target = self.$root.find('[data-id="' + targetId + '"]');

            // Get the data from the menu item
            var isParent = target.data('isparent');
            var moduleId = target.data('moduleid');
            var moduleSplit = moduleId.split('_');
            var recordId = target.data('record_id');

            // To prevent mixing of selectors,
            // start searching for elements from the correct form.
            var editFormData = self.getItemEditorFormData(target);
            var $formRoot = editFormData.formRoot;

            // Scroll to the top
            var $modal = $('#content_dashboard').closest('.modal');
            var $scrollEl = $modal.is(':visible') ? $modal : $('html, body');
            // Scroll the div to the top of the scroll view
            $formRoot.find('#menu-form').animate(
                {
                    scrollTop: 0,
                },
                200
            );
            // Scroll to have the view shown
            $scrollEl.animate(
                {
                    scrollTop: 100,
                },
                200
            );

            $formRoot.find('.pleasewait').remove();

            var $el = $formRoot.find('#current-item-editor-input-name');
            $el.val(target.data('name'));

            // Set field value
            if (parseInt(isParent) === 1 && parseInt(moduleSplit[0]) === 0) {
                $el.removeAttr('readonly');
            } else {
                $el.attr('readonly', 'readonly');
            }

            $formRoot.find('#current-item-editor-input-slug').val(target.data('slug'));
            $formRoot.find('#current-item-editor-name').html(target.data('name'));
            $formRoot.find('#item_editor_save_button').data('owner-id', target.data('id'));

            // Fill in the item form fields from the menu item
            var formFields = editFormData.formFields;
            $.each(formFields, function (index, field) {
                let key = field.key;
                let value = target.data(key);

                $formRoot.find('#' + key).val(value);

                if (field.type === 'photo') {
                    // Add thumbnail value (or clear it if it is blank)
                    $thumbnail = $formRoot.find('#' + key + '__thumbnail');
                    if (value) {
                        $thumbnail.attr('src', value);
                    } else {
                        // It is better to remove the src, than set it to null, which results in different formatting of the thumbnail
                        $thumbnail.unbind('error').removeAttr('src');
                    }

                    // Clear the file input
                    $fileInput = $formRoot.find('#' + key + '__file');
                    $fileInput.val('')
                } else if (field.type === 'color') {
                    // Update the color picker value
                    $colorPicker = $formRoot.find('#' + key).closest('.colorpicker');
                    if (value) {
                        $colorPicker.colorpicker('setValue', value);
                    } else {
                        let defaultValue = field.default;
                        if (defaultValue) {
                            $colorPicker.colorpicker('setValue', defaultValue);
                        }
                    }
                }
            });

            if (editFormData.isListItem) {
                self.updateIconControls(
                    $formRoot.find('#icon_type').val(),
                    $formRoot.find('#icon_url').val()
                );
            }

            self.$menuEditorItem.hide();
            self.$menuEditorListItem.hide();

            $formRoot.fadeIn();

            const directLink = $(this).parent().data('direct-link');
            const $editContentButton = $('#menu-editor-item .editContentButton');

            if (directLink) {
                $editContentButton.data('direct-link', directLink);

                if (recordId) {
                    console.log('Setting edit record');
                    $editContentButton.text('Edit Record');
                } else {
                    console.log('Setting edit view');
                    $editContentButton.text('Edit View');
                }
                $editContentButton.show();
            } else {
                $editContentButton.hide();
            }
        };

        this.getItemEditorFormData = function (target) {
            var id = target.data('id');
            var depth = self.getDepth(id);
            var isListItem = self.isListItem(depth);
            var editFormData = null;

            if (isListItem) {
                editFormData = {
                    formRoot: self.$menuEditorListItem,
                    formFields: self.formFields['list_item_fields'],
                    isListItem: true,
                };
            } else {
                editFormData = self.getBasicEditFormData(target);
            }

            return editFormData;
        };

        this.getBasicEditFormData = function (target) {
            var editFormData = {};

            editFormData.formRoot = self.$menuEditorItem;
            editFormData.formFields = self.formFields['item_fields'];
            return editFormData;
        };

        // Edit the menu item and hide the edit form
        this.saveItem = function () {
            var targetId = $(this).data('owner-id');
            var target = $('[data-id="' + targetId + '"]');

            // Start searching from the correct form element
            var editFormData = self.getItemEditorFormData(target);
            var $formRoot = editFormData.formRoot;

            $formRoot.find('.error').removeClass('.error');
            $formRoot.find('.pleasewait').remove();

            var $inputFile = $formRoot.find('[type="file"]').first();
            // Find the field associated by removing the __file from the id
            var $urlField = $formRoot
                .find('#' + $inputFile.attr('id').replace('__file', ''))
                .first();

            if ($inputFile[0].files && $inputFile[0].files.length > 0) {
                console.log('uploading file');
                if ($urlField.attr('id') == 'icon_url') {
                    self.updateIconControls(self.ICON_TYPE.INPUT, '');
                }

                self.uploadFile($formRoot, target, $inputFile[0], $urlField);
                $inputFile.val('');
            } else {
                self.saveItemHelper($formRoot, target);
            }
        };

        this.saveItemHelper = function ($formRoot, target) {
            var $el = $formRoot.find('#current-item-editor-input-name');
            target.data('name', $el.val());

            var newName = $formRoot.find('#current-item-editor-input-name').val();
            var newSlug = $formRoot.find('#current-item-editor-input-slug').val();
            target.data('slug', newSlug);

            // Add form edit fields
            var formFields = self.getItemEditorFormData(target).formFields;
            var iconUrl = null;
            var imageUrl = $formRoot.find('#image_url').val();

            $.each(formFields, function (index, field) {
                let key = field.key;
                let value = $formRoot.find('#' + key).val();
                target.data(key, value);

                if (value && key === 'icon_url') {
                    iconUrl = value;
                }

                if (field.type === 'photo') {
                    $thumbnail = $formRoot.find('#' + key + '__thumbnail');
                    if (value) {
                        $thumbnail.attr('src', value);
                    } else {
                        // It is better to remove the src, than set it to null, which results in different formatting of the thumbnail
                        $thumbnail.unbind('error').removeAttr('src');
                    }
                }
            });

            self.updateDisplayItem(target, newName, iconUrl, imageUrl);
            self.hideItemEditor();

            // Update JSON
            self.updateOutput(self.$root.data('output', self.$jsonOutput), true);
        };

        this.updateDisplayItem = function ($target, newName, iconUrl, imageUrl) {
            const $iconContainer = $target.find('> .dd-handle > .menu-item-icon');

            if (iconUrl) {
                // Set the iconUrl for the selected icon (sublevel items).
                $iconContainer
                    .html(`<img src="${iconUrl}" class="menu-icon-in" />`)
                    .attr('title', '')
                    .css('margin-right', '5px');
            } else if (imageUrl) {
                // Add an image icon to indicate that an image has been set (top level items).
                $iconContainer
                    .html('<i class="far fa-image"></i>')
                    .attr('title', 'An image has been set for this item.')
                    .css('margin-right', '5px');
            } else {
                // Neither icon nor image was set; clear out the icon container.
                $iconContainer.html('').css('margin-right', '0px');
            }

            // Update the name.
            $target
                .find('> .dd-handle > .menu-item-name')
                .html(newName);
        };

        this.initializeIconControls = function () {
            var $formRoot = self.$menuEditorListItem;
            var $iconSourceNone = $formRoot.find('#icon-source-none');
            var $iconSourceInput = $formRoot.find('#icon_url');
            var $iconSourceThumbnail = $formRoot.find('#icon_url__thumbnail');
            var $iconType = $formRoot.find('#icon_type');

            $iconSourceNone.on('change', function () {
                var iconUrl = self.noIconDefaultUrl;

                if (this.checked) {
                    $iconType.val(self.ICON_TYPE.NONE);
                } else {
                    $iconType.val(self.ICON_TYPE.FONT_AWESOME);
                    iconUrl = self.iconDefaultUrl;
                }

                var iconType = $iconType.val();
                self.updateIconControls(iconType, iconUrl);
            });

            $iconSourceInput.on('change', function () {
                $value = $iconSourceInput.val();
                if ($value) {
                    $iconSourceThumbnail.attr('src', $value);
                } else {
                    $iconSourceThumbnail.unbind('error').removeAttr('src');
                }
            });
        };

        $('#icon-source-fontawesome').IconSelector({
            on_select: function (iconUrl, iconName) {
                $('#icon_type').val(self.ICON_TYPE.FONT_AWESOME);
                $('#icon_url').val(iconUrl);
                self.updateIconControls(self.ICON_TYPE.FONT_AWESOME, iconUrl);
            },
        });

        this.updateIconControls = function (iconType, iconUrl) {
            var $formRoot = self.$menuEditorListItem;
            var $iconSourceNone = $formRoot.find('#icon-source-none');
            var $iconSourceInput = $formRoot.find('#icon_url__file');
            var $iconSelectorBtn = $formRoot.find('#icon-source-fontawesome');
            var $iconType = $formRoot.find('#icon_type');
            var $iconUrl = $formRoot.find('#icon_url');
            var $iconColor = $formRoot.find('#icon_color');

            switch (iconType) {
                case self.ICON_TYPE.INPUT:
                case self.ICON_TYPE.FONT_AWESOME:
                    $iconSourceNone.prop('checked', false);
                    $iconSourceInput.removeClass('disabled').removeAttr('disabled');
                    $iconSelectorBtn.removeClass('disabled').removeAttr('disabled');
                    $iconUrl.removeClass('disabled').removeAttr('disabled');
                    $iconColor.removeClass('disabled').removeAttr('disabled');
                    $iconColor.closest('.colorpicker').colorpicker('enable');
                    $iconType.val(iconType);
                    $iconUrl.val(iconUrl).trigger('change');

                    if (iconType === self.ICON_TYPE.INPUT) {
                        $iconUrl.css('display', 'block');
                    } else {
                        $iconSourceInput.val('');
                        $iconUrl.css('display', 'none');
                    }
                    break;

                case self.ICON_TYPE.NONE:
                default:
                    $iconSourceNone.prop('checked', true);
                    $iconSourceInput.addClass('disabled').attr('disabled', 'disabled');
                    $iconSelectorBtn.addClass('disabled').attr('disabled', 'disabled');
                    $iconUrl.addClass('disabled').attr('disabled', 'disabled');
                    $iconColor.addClass('disabled').attr('disabled', 'disabled');
                    $iconColor.closest('.colorpicker').colorpicker('disable');
                    // Clear the icon color.
                    $iconColor.closest('.colorpicker').find('.input-group-addon i').css('background-color', '');
                    $iconColor.val('');
                    $iconType.val(self.ICON_TYPE.NONE);
                    $iconUrl.val('').trigger('change');
                    $iconUrl.css('display', 'none');
                    $iconSourceInput.val('');
                    break;
            }
        };

        this.handleFileUploadError = function ($formRoot, message) {
            alert('Not uploaded. ' + message);
            $formRoot.find('#item_editor_save_button').removeClass('disabled');
            $formRoot.find('.pleasewait').remove();
        };

        this.uploadFile = function ($formRoot, target, inputFile, $urlField) {
            var baseUrl = location.protocol + '//' + location.host + '/';
            var route = baseUrl + 'tenant-company/upload-dashboard-image';

            if (!inputFile || inputFile.files.length === 0) {
                self.saveItemHelper($formRoot, target);
                return;
            }

            var file = inputFile.files[0];
            var data = new FormData();
            data.append('input', file);
            data.append('_token', $formRoot.find('#token').val());

            // Prevent multiple edits
            $formRoot.find('#item_editor_save_button').addClass('disabled');
            $formRoot
                .find('#item_editor_save_button')
                .before('<span class="pleasewait">Please wait..</span>');
            $.ajax({
                url: route,
                type: 'POST',
                datatype: 'json',
                processData: false,
                contentType: false,
                data: data,
            })
                .then(function (data) {
                    if (data.success) {
                        if (data.hasOwnProperty('url')) {
                            $urlField.val(data.url);
                        }
                        $formRoot.find('#item_editor_save_button').removeClass('disabled');
                        self.saveItemHelper($formRoot, target);
                    } else {
                        return Promise.reject(data);
                    }
                })
                .catch(function (res) {
                    const errMsgText =
                        res.error_message ??
                        res.message ??
                        'There was an error communicating with the backend.';
                    self.handleFileUploadError($formRoot, errMsgText);
                });
        };

        this.removeItemIcon = function ($target) {
            $target.find('> .dd-handle > .menu-item-icon').html('').css('margin-right', '0px');
        };
    }

    return DashboardMenu;
})(jQuery, window);

// Initializes handling to scroll the window or a given parent element if needed when an item is being dragged.
window.initDashboardMenuScroll = function($nestable, parentElem = window) {
    const initialized = $nestable.data('scroll-initialized');
    if (initialized) {
        return;
    }

    let scrollIntervalHandler;
    let contentHeight = parentElem === window ? $(document).height() : $(parentElem)[0].scrollHeight;
    let scrollMargin = 60; // Margin from the top/bottom of the window within which to scroll.
    let scrollMarginFast = 20; // Narrower margin for faster scrolling.
    let scrollStep = 2; // Base pixels per scrollstep, multplied with scrollFactor below.
    let scrollFactor = 0;
    let isDragging = false;

    function isScrolledToBottom() {
        const maxScroll = contentHeight - $(parentElem).height();
        return $(parentElem).scrollTop() >= maxScroll;
    }

    function isScrolledToTop() {
        return $(parentElem).scrollTop === 0;
    }

    function scrollBy(offset) {
        if ((offset > 0 && !isScrolledToBottom()) || (offset < 0 && !isScrolledToTop())) {
            $(parentElem).scrollTop($(parentElem).scrollTop() + offset);
        }
    }

    function startScroll() {
        scrollIntervalHandler = setInterval(() => {
            scrollBy(scrollFactor * scrollStep);
        }, 25);
    }

    function stopScroll() {
        if (scrollIntervalHandler) {
            clearInterval(scrollIntervalHandler);
            scrollIntervalHandler = null;
        }
    }

    // Start or stop scrolling depending on whether an item is being dragged and mouse location relative to the edge of the window.
    function handleScroll(e) {
        if (!isDragging) {
            return;
        }

        if (e.clientY < scrollMargin) {
            // Scroll up
            scrollFactor = e.clientY < scrollMarginFast ? -4 : -1;
        } else if (e.clientY > window.innerHeight - scrollMargin) {
            // Scroll down
            scrollFactor = e.clientY > window.innerHeight - scrollMarginFast ? 4 : 1;
        } else {
            // Dragging but not/no longer near the top or bottom; stop scrolling.
            scrollFactor = 0;
            stopScroll();
        }

        if (scrollFactor && !scrollIntervalHandler) {
            startScroll();
        }
    }

    $(document).on('mousemove', handleScroll);

    // Keep track of whether an item is being dragged.
    $nestable.on('mousedown', () => (isDragging = true));
    $('body').on('mouseup', () => {
        if (isDragging) {
            isDragging = false;
            stopScroll();
        }
    });

    $nestable.data('scroll-initialized', true);
}

window.DashboardMenu = DashboardMenu;
