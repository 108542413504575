var ModalStack = function () {
    var self = this;
    self.refresh = false;
    self.stack = [];

    self.log = function (context) {
        var self = this;
        const modalCount = (self.stack && self.stack[0].length) ?? 0;
        var titles = $.map(self.stack, function ($modal) {
            return $modal.find('.modal-title').text();
        }).slice(0, modalCount);

        console.log(context + ' - Current modals: (' + titles.length + ') ' + titles.join(', '));
    };

    return {
        next: function ($modal) {
            var $last = self.stack[self.stack.length - 1];
            if ($last) {
                // Because of the Edit submodals showing the same content, we need to make
                // sure that the used main content ids are temporarily replaced.
                $('#content-form').attr('id', 'content-form-old');
                $('#content-settings').attr('id', 'content-settings-old');
                $('#manage-fields').attr('id', 'manage-fields-old');

                // Hide the last modal
                $last.modal('hide');
            }

            $modal.attr('on_stack', '1');
            self.stack.push($modal);
            $modal.modal('show');

            self.log(`New modal: ${$modal.frames[0].title}`);
        },
        refreshCurrent: function() {
            // Refresh the current modal with either the original content or the current frame.
            const $current = this.getCurrent();

            if ($current.frames.length > 1) {
                console.log(`Refreshing frame "${$current.frames[$current.frames.length - 1].title}"`);
            } else {
                self.log('Refresh');
            }
            const options = $current.frames[$current.frames.length - 1];

            // Tabbed modal?
            $currentTab = $current.find('#modal-header-tabs a.modal-tab-active');

            if ($currentTab.length) {
                // Refresh the current tab
                $currentTab.click();
            } else {
                // Refresh the modal or current frame
                createModal(options, 'refresh');
            }
        },
        back: function (suppressRefresh, dismissModal) {
            const $current = this.getCurrent();

            if (dismissModal && $current.frames.length > 1) {
                console.log(`Close modal: dismissing stacked frames.`);
                $current.frames = [ $current.frames[0] ];
            }

            if ($current.frames.length > 1) {
                // Have more than one frame; so rewind to previous instead of destroying the modal.
                const discard = $current.frames[$current.frames.length - 1];
                console.log(
                    `Rewinding to previous frame, discarding "${discard.title}". ` +
                    `${$current.frames.length > 2 ? `${$current.frames.length - 1} frames remaining.` : `Now showing the first frame (the original content of the modal).`}`
                );

                // Discard the options for the top frame
                $current.frames.pop();

                // Use the previous frame's options (now on top of the stack)
                const options = $current.frames[ $current.frames.length - 1 ];
                createModal(options, 'rewind');
            } else {
                // No previous frames to show
                self.log(`Back`);

                const executeRefresh = !suppressRefresh && self.refresh;

                if (executeRefresh && self.stack.length === 1) {
                    // If a refresh is going to occur, then clear the page immediately
                    // as the location.reload() seems to take too long and causes weirdnesses
                    $('.content-wrapper').empty();
                }

                // Hide all
                var $previous = self.stack.pop();
                if ($previous) {
                    $previous.attr('on_stack', '0');
                    $previous.modal('hide');
                }

                $previous = self.stack[self.stack.length - 1];
                if ($previous) {
                    $previous.modal('show');

                    // Revert potentially converted ids - see in 'next' method above
                    // for more explanation
                    $('#content-form-old').attr('id', 'content-form');
                    $('#content-settings-old').attr('id', 'content-settings');
                    $('#manage-fields-old').attr('id', 'manage-fields');

                    if (executeRefresh) {
                        // Modal refresh
                        $previous.refresh();
                    }

                    return $previous;
                }

                if (executeRefresh) {
                    // Do a location refresh once all the modals are done
                    location.reload();
                }

            }

            return null;
        },
        backToFirst: function () {
            var length = self.stack.length;

            if (length === 0) {
                return null;
            }

            if (length === 1) {
                return self.stack[1];
            }

            var $temp = null;
            for (var i = length; i > 1; i--) {
                // Suppress the refresh for all except the last (target) modal.
                $temp = this.back(i === 2 ? false : true);
            }

            return $temp;
        },
        getPrevious: function () {
            return self.stack[self.stack.length - 2];
        },
        getCurrent: function () {
            return self.stack[self.stack.length - 1];
        },
        clear: function () {
            $.each(self.stack, function (i, $item) {
                $item.attr('on_stack', '0');

                if (!$item.hasClass('in')) {
                    // Already hidden, can remove directly
                    $item.remove();
                }
                $item.modal('hide');
            });

            self.stack = [];

            // If the stack refresh flag is set, do a location refresh
            // once all the modals are done
            if (self.refresh) {
                location.reload();
            }
        },
        empty: function () {
            return !self.stack.length;
        },
        setRefresh: function (refresh) {
            self.refresh = refresh;
        },
    };
};

var modalStack = new ModalStack();

var createModal = function (options, modalMode) {
    var spinnerHtml =
        '<div class="form_spinner" style="display: block; height: 100px;">' +
        '<i class="fas fa-spinner fa-spin fa-1x fa-fw"></i>' +
        '<span class="sr-only">Loading...</span>' +
        '</div>';

    var hidden = false;
    var $modal;

    options = $.extend(
        {},
        {
            ajaxOptions: null,
            fullRefresh: false, // Refresh the entire modal body content. Not suitable for server side DataTables
            title: '',
            message: spinnerHtml,
            size: 'large',
            footerHtml: '',
            onSuccess: null,
            show: false,
            subModal: null,
            props: {},
        },
        options
    );

    if ($('.modal.in').length) {
        options.backdrop = false;
    }

    if (options.modalTabbed === '1') {
        var $modalBody = $('#modal-body-tab');
        $modal = $modalBody.closest('.modal');

        // Add in the Spinner HTML
        $modalBody.html(spinnerHtml);

        var $footer = $(options.footerHtml);
        if ($footer) {
            $modal.find('.modal-footer').remove();
            $modalBody.after($footer);

            $footer.find('[data-dismiss="modal"]').on('click', function () {
                modalStack.back();
            });
        }

        $modal.off('hidden.bs.modal');
        $modal.on('hidden.bs.modal', function () {
            // Ensure that back is not called when a modal
            // is hidden before another is added
            if (!parseInt($(this).attr('on_stack'))) {
                $(this).remove();
            } else {
                // Hide the contents, as they will be reloaded once
                // it is shown again. This is to ensure any changes
                // are updated.
                hidden = true;

                // Clear the content of the tab
                $modal.find('#modal-body-tab').empty();
            }
        });

        $modal.off('shown.bs.modal');
        $modal.on('shown.bs.modal', function () {
            if (hidden) {
                hidden = false;

                // Trigger a tab reload
                $modal.find('#modal-header-tabs a.modal-tab-active').click();
            }
        });
        //Ajax call: Invoke e.g. by looking at records in a module (->ModuleRecordsController@index)
        $.ajax(options.ajaxOptions)
            .then((res) => {
                if (!res.success) {
                    return Promise.reject(res);
                }
                options.onSuccess($modal, res);
            })
            .catch((res) => {
                const errMsgText =
                    res.error_message ??
                    res.message ??
                    'There was an error communicating with the backend.';
                const errMsgHtml = `<span id='ajax-error' class='text-danger'>${errMsgText}</span>`;
                $modalBody.html(errMsgHtml);
            });
    } else {
        if (!modalMode) {
            $modal = bootbox.dialog(options);

            // This is a new modal, so add the frame stack and put the current options as the first element.
            $modal.frames = [options];

            // Pass props directly into the modal
            $modal.data('props', options.props);

            // Remove Bootbox's stop propagation function
            // to allow for custom buttons
            $modal.off('click', '.modal-footer button');

            // Forms of modal navigation
            // 1) modalStack.back() will go back to the previous modal
            // 2) modalStack.clear() will close all the modals
            var $modalBody = $modal.find('.modal-body');

            var $footer = $(options.footerHtml);
            $footer.hide();
            $modalBody.after($footer);

            // X, Footer close btns
            $xBtn = $modal.find('[data-dismiss="modal"],.bootbox-close-button');

            // Overlay a transparent div on top of the x and close buttons to intercept clicks in order to decide whether the modal should be rewound or closed.
            $xBtn.css('position', 'relative');
            $xBtn.prepend('<div id="_clickInterceptor" style="z-index:999;width:100%;height:100%;top:0px;left:0px;position:absolute;"></div>');

            // Add the interceptor listener.
            $xBtn.find('#_clickInterceptor').on('click', (e) => {
                // Check if the cancel button is disabled; ignore the click if so.
                $cancelBtn = $modal.find('[data-dismiss="modal"]');
                if ($cancelBtn.prop('disabled')) {
                    console.log('Interceptor: The cancel button is disabled');
                    return;
                }

                $backBtn = $modal.find('#modal-back-btn').first();
                if ($modal.frames.length > 1) {
                    // There is a Back button; click it to just rewind a frame, and stop propagation to keep Bootbox from destroying the modal.
                    console.log('Interceptor: Rewind frame');
                    e.stopPropagation();
                    $backBtn.click();
                } else {
                    // No Back button present, go back on the modal stack (close modal).
                    console.log("Interceptor: Actually Close Modal");
                    modalStack.back(null, true);
                }
            })

            $modal.find('[data-action="back"]').on('click', function () {
                modalStack.back();
            });

            $modal.off('hidden.bs.modal'); // https://github.com/makeusabrew/bootbox/issues/179
            $modal.on('hidden.bs.modal', function () {
                // Ensure that back is not called when a modal
                // is hidden before another is added
                if (!parseInt($(this).attr('on_stack'))) {
                    $(this).remove();
                }

                resetModalScroll();
            });

            $modal.off('shown.bs.modal');
            $modal.on('shown.bs.modal', function () {
                // Ensure that the race condition between
                // hiding an showing modals on back is handled
                resetModalScroll();

                // Scroll the modal back to top
                $(this).scrollTop(0);
            });

            // Disable all normal submit routes, this ensures that the "enter"
            // button does not cause a submit in some cases.
            $modal.on('submit', function (e) {
                e.preventDefault();
                return false;
            });

            // Initial retrieval of data
            if (!options.fullRefresh) {
                refresh();
            }

            modalStack.next($modal);
        } else {
            // Reuse this modal by either refreshing the current view or replacing it with a new one or a previously shown one.
            $modal = modalStack.getCurrent();

            // Update the title
            $modal.find('.modal-title').html(options.title);

            switch (modalMode) {

                case 'replace':
                    // Replace the current view
                    console.log(`Adding frame: "${options.title}"`);

                    // Add the options to the frame stack within the modal so that
                    // they can be retrieved when the user dismisses/returns from the new content.
                    $modal.frames.push(options);

                    // Hide the close button
                    $footer = $modal.find('.modal-footer');
                    $cancelBtn = $footer.find('.cancel-btn').hide();

                    // Show (or create) the 'back' button instead
                    $backBtn = $('#modal-back-btn');

                    if (!$backBtn.length) {
                        $backBtn = $(`<button type="button" id="modal-back-btn" class="btn btn-primary btn-flat">Back</button>`);

                        // Attach a click handler that will rewind the current modal instead of destroying it.
                        $backBtn.on('click', (e) => {
                            modalStack.back();
                        })
                    } else {
                        $backBtn.show();
                    }

                    $footer.append($backBtn);
                    break;

                case 'rewind':
                    // Rewind to the previously shown content.
                    if ($modal.frames.length === 1) {
                        // This was the last rewindable frame - hide the back button, show the cancel button.
                        $('#modal-back-btn').hide();

                        $footer = $modal.find('.modal-footer');
                        $cancelBtn = $footer.find('.cancel-btn').show();
                    }
                    break;

                case 'refresh':
                    // Just refreshing the current view
                    break;

            }

            var $modalBody = $modal.find('.modal-body');
            var $footer = $(options.footerHtml);
            refresh(true);
        }
    }

    function refresh(forceAjax) {
        if (options.ajaxOptions) {

            function runAjax() {
                $.ajax(options.ajaxOptions)
                    .then(function (res) {
                        if (!res.success) {
                            return Promise.reject(res);
                        }
                        $footer.show();
                        options.onSuccess($modal, res);
                    })
                    .catch((res) => {
                        const errMsgText =
                            res.error_message ??
                            res.message ??
                            'There was an error communicating with the backend.';
                        const errMsgHtml = `<span id='ajax-error' class='text-danger'>${errMsgText}</span>`;
                        $modalBody.html(errMsgHtml);
                    });
            }

            if (!forceAjax) {
                // Clean up DataTables
                // https://datatables.net/forums/discussion/42438/does-jquery-empty-html-newcontent-remove-datatable-from-memory
                $modalBody.find('table').each(function (i, table) {
                    if ($.fn.DataTable.isDataTable(table)) {
                        $(table).DataTable().destroy();
                    }
                });

                $modalBody.empty().append(spinnerHtml);
                $footer.hide();
            } else {
                runAjax();
            }

            // One do the following event once
            $modal.one('shown.bs.modal', runAjax);

        }
    }

    function resetModalScroll(elem) {
        if (elem) {
            elem.on('hidden.bs.modal', addModalOpenClass);
        } else {
            addModalOpenClass();
        }

        function addModalOpenClass() {
            if ($('body').find('.modal.in').length) {
                $('body').addClass('modal-open');
            }
        }
    }

    function updateAjaxOptions(ajaxOptions) {
        $.extend(true, options.ajaxOptions, ajaxOptions);
    }

    $modal.setLanguage = function (language) {
        const previousDoption = options.ajaxOptions?.data?.doption;
        if (previousDoption) {
            const newDoption =
                previousDoption.substring(0, previousDoption.lastIndexOf('|') + 1) + language;
            updateAjaxOptions({ data: { doption: newDoption } });
        }
    };

    $modal.getFooter = function () {
        return $footer;
    };

    $modal.refresh = function () {
        refresh();
    };

    $modal.setFullRefresh = function (fullRefresh) {
        options.fullRefresh = fullRefresh;
    };

    return $modal;
};

var site = {
    init: function () {
        this.mainurl = location.protocol + '//' + location.host + '/';
        site.btnactions();
        site.commonactions();
    },
    loader: function (mode) {
        Utils.loader(mode);
    },
    // This will add a click handler to $linkElement that copies the text contained in
    // $elementWithText when $linkElement is being clicked. $linkElement will briefly indicate
    // that the text was copied.
    attachCopyToClipboardHandler: function($linkElement, $elementWithText) {
        const originalText = $linkElement.text();
        if ($linkElement.data('clipboard-handler-attached')) {
            return;
        }

        $linkElement.on('click', function(event) {
            event.preventDefault();
            navigator.clipboard
                .writeText($elementWithText.text())
                .then(() => {
                    $linkElement.text('Copied!');
                    setTimeout(() => $linkElement.text(originalText), 1000);
                })
                .catch(() => alert('Error: Could not copy to clipboard.'));
        }).data('clipboard-handler-attached', true);
    },
    // This will add a confirmation dialog to csv export buttons (#50364). Modules can pass in
    // a custom confirmation text; if they don't, the default will be used.
    attachCsvExportConfirmationHandler: function(text = '') {
        if (!text) {
            text = 'Notice: Media files that may be contained in the module data will not be exported.'
        }
        $('.btn-csv-export').click(function (event) {
            event.preventDefault();
            if (confirm(text)) {
                window.location.href = $(this).attr('href');
            }
        });
    },
    // This initializes and handles tabs similar to the .tabs() function in jQuery UI.
    tabs: function(containerElem, options = {
        initialTabIndex: 0,      // Which tab should be opened initially (defaults to the first one, overrides navigateToAnchor)?
        navigateToAnchor: true,  // If there's an #anchor in the current url that matches one of the tabs, should that tab be opened? (defaults to true)
        onActivateCallbacks: {}, // This object should have tabIndexes as keys and functions as values. If a callback is present for a tab it will be called when the tab opens.
    }) {
        if (!containerElem) {
            return;
        }

        // If it's a jquery element, pull the DOM element from it.
        if (containerElem.jquery) {
            containerElem = containerElem.get(0);
        }

        const tabContent = containerElem.querySelectorAll(':scope > :not(:first-child)');
        if (!tabContent.length) {
            // No content elements found.
            console.warn(
                'Soda tabs: Cannot initialize tabs because no elements with content were found. The first child of the tabs container should be an unordered '
                +'list of the form <ul><li><a href="#">Tab Link 1</a></li>...</ul>. It should be followed by <div>s with content for each tab.'
            );
            return null;
        }

        // Hydrate the links.
        const tabLinks = containerElem.querySelectorAll('ul > li > a');
        tabLinks.forEach((tabLink, index) => tabLink.addEventListener('click', () => {
            openTab(index)
        }));

        function openTab(tabIndex = 0) {
            if (tabIndex >= tabContent.length) {
                // Requested tab doesn't exist - show the last one that does.
                tabIndex = tabContent.length - 1;
            }

            // Hide all content except for the currently selected tab.
            tabContent.forEach((tabContentContainer, index) => {
                tabContentContainer.style.display = (index === tabIndex ? "block" : "none");
                tabContentContainer.classList.add('soda-tab-content');
            });

            // Markup the links accordingly.
            tabLinks.forEach((tabLink, index) => {
                if (index === tabIndex) {
                    tabLink.classList.remove('soda-tab-link-clickable');
                    tabLink.classList.add('soda-tab-link-active');
                } else {
                    tabLink.classList.remove('soda-tab-link-active');
                    tabLink.classList.add('soda-tab-link-clickable');
                }
            });

            const onActivateCallbacks = options?.onActivateCallbacks;
            if (onActivateCallbacks[tabIndex]) {
                // Have an activation callback for this tab. Execute it.
                onActivateCallbacks[tabIndex]();
            }
        }

        let tabIndexToOpen = null;

        if (options.hasOwnProperty('initialTabIndex')) {
            // Open the tab with the given index or default to the first one.
            tabIndexToOpen = parseInt(options.hasOwnProperty('initialTabIndex') ? options.initialTabIndex : 0);
        } else if (!options.hasOwnProperty('navigateToAnchor') || options.navigateToAnchor) {
            // Read the target anchor from the url and open the respective tab if it exists.
            const parts = window.location.href.split('#');
            const anchor = parts[parts.length - 1];

            for (let i = 0; i < tabLinks.length; i++) {
                if (tabLinks[i].getAttribute('href') === '#' + anchor) {
                    tabIndexToOpen = i;
                    break;
                }
            }

            if (tabIndexToOpen === null) {
                // No matching anchor found. Default to the first tab.
                tabIndexToOpen = 0;
            }
        }

        if (!Number.isInteger(tabIndexToOpen)) {
            // Unable to determine which tab to start with.
            console.warn('Soda tabs: Unable to determine what tab to open initially.');
        }

        openTab(tabIndexToOpen);
    },
    btnactions: function () {
        $('body').on('click', '.disabled', function (e) {
            e.preventDefault();
            return false;
        });

        // Show confirm box
        $('body').on('click', '.canSubmit', function (e) {
            e.preventDefault();
            var me = $(this);

            bootbox.confirm({
                message: me.attr('data-message') ?? 'Are you sure?',
                buttons: {
                    confirm: {
                        label: me.attr('data-btnlabel-confirm') ?? 'OK'
                    },
                    cancel: {
                        label: me.attr('data-btnlabel-cancel') ?? 'Cancel'
                    }
                },
                callback: function (result) {
                    if (result) {
                        $('#submitterForm').attr('action', me.attr('href'));
                        $('input[name=_method]').val(me.attr('data-method'));
                        $('#submitterForm').submit();
                    }
                }
            });
        });

        $('body').on('click', '.btn-confirm', function (e) {
            e.preventDefault();
            var me = $(this);
            bootbox.confirm('Are you sure?', function (result) {
                if (result) {
                    if ($('#autoform').length > 0) {
                        $('#autoform').submit();
                    } else {
                        me.closest('form').submit();
                    }
                }
            });
        });

        $('body').on('click', '.btn-modalconfirm', function (e) {
            e.preventDefault();
            var me = $(this);
            var dscript = me.attr('data-script');
            var doption = me.attr('data-option');
            var didval = me.attr('data-idval');
            var box = null;
            switch (dscript) {
                case 'module_clone_english':
                    site.checkModuleForClone(didval, doption, null).then(function (response) {
                        var message =
                            'English data will be imported. All current data will be removed.';
                        if (response.message) {
                            message += '<br><br>' + response.message;
                        }

                        message += '<br><br>Are you sure?';

                        box = bootbox.confirm(message, function (result) {
                            if (result) {
                                // Update action from button, and run click
                                me.removeClass('btn-modalconfirm').addClass('btn-action');
                                me.trigger('click');
                                me.removeClass('btn-action').addClass('btn-modalconfirm');

                                modalStack.setRefresh(true);
                            }
                        });
                    });
                    break;

                default:
                    box = bootbox.confirm('Are you sure?', function (result) {
                        if (result) {
                            if ($('#autoform').length > 0) {
                                $('#autoform').submit();
                            } else {
                                me.closest('form').submit();
                            }
                        }
                    });
                    break;
            }

            site.resetModalScroll(box);
        });

        $('body').on('click', '.btn-action-delete', function (e) {
            // Inline Delete (for sub tables) opened in a modal
            e.preventDefault();
            site.checkSession();

            var me = $(this);
            var modalelem = me.closest('.modal');
            var dscript = me.attr('data-script');
            var did = me.attr('data-id');
            var didval = me.attr('data-idval');
            var dcrud = me.attr('data-crud');
            var doption = me.attr('data-option');
            var route = me.attr('data-route') + '/' + didval;
            var confirmMessage = me.attr('data-confirm-message');

            if (!confirmMessage) {
                confirmMessage = 'Are you sure?';
            }

            if (me.attr('data-path')) {
                var path = me.attr('data-path');
                if (path != '') {
                    route = site.mainurl + '' + path + '/' + route;
                }
            }

            var requestData = {};
            if (me.attr('data-encoded')) {
                requestData = Utils.decodeData(elem.attr('data-encoded'));
            }

            const runDelete = () => {
                site.runAjaxDelete(
                    dscript,
                    did,
                    didval,
                    modalelem,
                    dcrud,
                    route,
                    doption,
                    requestData
                ).then(function (data) {
                    switch (dscript) {
                        case 'voting_question_delete':
                            $('.ajax_container').trigger(
                                CUSTOM_EVENTS.VOTING_QUESTIONS_REFRESH,
                                data
                            );
                            break;

                        case 'voting_question_item_delete':
                            $('.ajax_container').trigger(
                                CUSTOM_EVENTS.VOTING_QUESTION_ITEMS_REFRESH,
                                data
                            );
                            break;
                    }
                });
            }

            if (me.attr('data-no-confirm') !== undefined) {
                // The presence of data-no-confirm indicates that the confirmation dialog should be suppressed.
                runDelete();
            } else {
                // Show a confirmation dialog.
                var box = bootbox.confirm({
                    message: confirmMessage,
                    buttons: {
                        confirm: {
                            label: me.attr('data-btnlabel-confirm') ?? 'OK',
                        },
                        cancel: {
                            label: me.attr('data-btnlabel-cancel') ?? 'Cancel',
                        },
                    },
                    callback: function (result) {
                        if (result) {
                            runDelete();
                        }
                    },
                });

                // Prevent broken scroll when there are multiple modals
                site.resetModalScroll(box);
            }
        });

        $('body').on('click', '.btn-custom-action', function (e) {
            e.preventDefault();
            site.checkSession();
            var elem = $(this);
            var modalelem = elem.closest('.modal');
            var daction = elem.attr('data-action');

            switch (daction) {
                case 'columns_dynamic_settings_update':
                    // Do an ajax post request to add the custom field
                    var dscript = 'columns_dynamic_settings_update';
                    var dcrud = 0;
                    var dpath = elem.attr('data-path');
                    var droute = elem.attr('data-route');
                    var route = site.mainurl + '' + dpath + '/' + droute + '';
                    var doption = $("#settingsform input[name='doption']").val();
                    var did = '';
                    var didval = $('#settingsform #_id').val();

                    site.runAjaxActions(dscript, did, didval, modalelem, dcrud, route, doption);
                    break;

                case 'generate_api_key':
                    // Do an ajax post request to add the custom field
                    var dscript = daction;
                    var dcrud = 0;
                    var dpath = elem.attr('data-path');
                    var droute = elem.attr('data-route');
                    var route = site.mainurl + '' + dpath + '/' + droute + '';
                    var doption = '';
                    var did = elem.attr('data-id');
                    var didval = elem.attr('data-idval');

                    site.runAjaxActions(dscript, did, didval, modalelem, dcrud, route, doption);
                    break;

                case 'add_plain_list_media_file':
                    $target = $('#' + $(this).attr('data-target'));
                    $template = $target.find('.table_hidden tbody tr');
                    $target.find('tbody').append($template.get(0).outerHTML);
                    break;

                case 'add_plain_list_media_url':
                    $target = $('#' + $(this).attr('data-target'));
                    $template = $target.find('.table_hidden tbody tr');
                    $target.find('tbody').append($template.get(1).outerHTML);
                    break;

                case 'remove_plain_list_media':
                    var tr = elem.closest('tr');
                    tr.remove();
                    break;

                case 'enhanced_access_password_reset':
                    var enhancedAccessId = Utils.decodeData(elem.attr('data-encoded'))[
                        'enhanced_access_id'
                    ]; // Enhanced access module id
                    var didval = elem.attr('data-idval'); // Record id
                    var dscript = daction;
                    var dcrud = 0;
                    var dpath = elem.attr('data-path');
                    var droute = elem.attr('data-route');
                    var route =
                        site.mainurl +
                        dpath +
                        '/' +
                        droute +
                        '?' +
                        'enhanced_access_id=' +
                        enhancedAccessId;
                    var doption = elem.attr('data-option'); // Has data source module id
                    var did = '';
                    site.runAjaxActions(dscript, did, didval, modalelem, dcrud, route, doption);
                    break;

                case 'forums':
                    var dscript = daction;
                    var dcrud = 1;
                    var dpath = elem.attr('data-path');
                    var doption = '';
                    var did = elem.attr('data-id');
                    var didval = elem.attr('data-idval');
                    var droute = elem.attr('data-route') + '/' + didval + '/ajax';
                    var route = site.mainurl + '' + dpath + '/' + droute + '';
                    var customData = { triggerElement: elem };

                    // Force a modal refresh when the response comes back
                    const refreshModal = true;

                    if (elem.attr('data-target-action') === 'delete') {
                        // Get confirmation for delete.
                        bootbox.confirm(`Are you sure you want to remove this ${elem.attr('data-target-type') ?? 'content'}?`, (result) => {
                            if (result) {
                                site.runAjaxActions(dscript, did, didval, modalelem, dcrud, route, doption, customData, refreshModal);
                            }
                        });
                    } else {
                        // This is a non-delete action.
                        site.runAjaxActions(dscript, did, didval, modalelem, dcrud, route, doption, customData, refreshModal);
                    }
                    break;

                case 'reports_generate':
                    var didval = elem.attr('data-idval');
                    var route = site.mainurl + elem.attr('data-path') + '/' + elem.attr('data-route') + '/' + didval + '/';
                    var $modal = elem.closest(".modal");
                    var requestData = new FormData($modal.find('#adminform')[0]);
                    requestData.append('action', 'admin_save');
                    requestData.append('dscript', elem.data('script'));
                    requestData.append('did', elem.attr('data-id'));
                    requestData.append('didval', didval);

                    var ajaxOptions = {
                        url: route,
                        type: 'POST',
                        datatype: 'json',
                        processData: false,
                        contentType: false,
                        timeout: 300000,
                        data: requestData,
                    };
                    var modalOptions = {
                        ajaxOptions,
                        title: 'Report: ' + elem.data('report-template-name'),
                        footerHtml: '',
                        props: {},
                        onSuccess: function ($modal, response) {
                            const modalbody = $modal.find('.modal-body');
                            modalbody.html(response.data);
                        },
                    };
                    var modalelem = createModal(modalOptions);
                    break;
            }
        });

        $('body').on('click', '.btn-action', function (e) {
            e.preventDefault();
            $('div.tooltip').remove();

            var elem = $(this);
            if (elem.attr('data-nosession')) {
                // No session check
            } else {
                site.checkSession();
            }

            var daction = elem.attr('data-action');
            var dscript = elem.attr('data-script');
            var did = elem.attr('data-id');
            var didval = elem.attr('data-idval');
            var dmodal = elem.attr('data-modal');
            var dmodaltabbed = null;
            var dreadonly = 0;
            var dcrud = 0;
            var droute = dscript; // By default the route will be the script code (it can be overwritten if defined)
            var dpath = 'backend';
            var doption = '';
            var dModalTitle = elem.attr('data-modal-title');

            if (elem.attr('data-modal-tabbed')) {
                dmodaltabbed = elem.attr('data-modal-tabbed');
            }

            if (elem.attr('data-read-only')) {
                dreadonly = elem.attr('data-read-only');
            }

            if (elem.attr('data-option')) {
                doption = elem.attr('data-option');
            }

            if (elem.attr('data-crud')) {
                dcrud = elem.attr('data-crud');
            }

            if (elem.attr('data-route')) {
                droute = elem.attr('data-route');
            }

            if (elem.attr('data-path')) {
                dpath = elem.attr('data-path');
            }

            var route = site.mainurl + dpath + '/' + droute;

            var method = '';
            var dataMethod = '';
            if (dcrud == 1) {
                switch (daction) {
                    case 'edit':
                        method = 'GET';
                        route += '/' + didval + '/edit';
                        break;

                    case 'add':
                        var method = 'GET';
                        route += '/create';
                        break;

                    case 'view':
                        var method = 'GET';
                        route += '/' + didval;
                        break;
                }
            }

            if (elem.attr('data-method')) {
                method = elem.attr('data-method');
                dataMethod = method;
            }

            var customData = {};
            if (elem.attr('data-encoded')) {
                customData = Utils.decodeData(elem.attr('data-encoded'));
            }

            // Build the data required to send to the server.
            var requestData = {
                dscript: dscript,
                did: did,
                didval: didval,
                doption: doption,
                dreadonly: dreadonly,
                action: 'admin_' + daction,
                custom_data: {},
            };

            requestData = $.extend({}, requestData, customData);

            if (dataMethod) {
                requestData['_method'] = method;
            }

            var defaultModalOptions = {};

            switch (dscript) {
                case 'module_import_csv_view_edit':
                    var importType = elem.attr('data-custom-import-type');
                    if (importType) {
                        requestData.custom_data.import_type = importType;
                        var importKeys = elem.attr('data-custom-import-keys');
                        requestData.custom_data.import_keys = JSON.parse(importKeys);
                    }
                    break;
            }

            if (!method) {
                console.error(
                    'Missing HTTP method. Either use a CRUD operation or attach method as a data attribute'
                );
            }

            switch (daction) {
                case 'edit':
                case 'add':
                case 'view':
                    // Show footer btns
                    var footerHtml = null;

                    if (dreadonly) {
                        footerHtml = ModalFooter.getFooterHtml('view', null);
                    } else {
                        footerHtml = ModalFooter.getFooterHtml(daction, dscript);
                    }

                    var ajaxOptions = {
                        url: route,
                        type: method,
                        datatype: 'json',
                        data: requestData,
                    };

                    var props = {};
                    if (elem.attr('data-modal-props')) {
                        props = Utils.decodeData(elem.attr('data-modal-props'));
                    }

                    var modalOptions = $.extend(
                        {},
                        {
                            ajaxOptions: ajaxOptions,
                            title: elem.attr('data-modal-title'),
                            footerHtml: footerHtml,
                            props: props,
                            modalTabbed: dmodaltabbed,
                            onSuccess: function ($modal, response) {
                                onSuccess(
                                    response,
                                    requestData,
                                    $modal,
                                    dModalTitle,
                                    did,
                                    didval,
                                    dpath,
                                    daction,
                                    dscript,
                                    droute,
                                    doption,
                                    dmodaltabbed
                                );
                            },
                        },
                        defaultModalOptions
                    );

                    // Adding a data-replace-modal-content attribute to the link triggers re-use
                    // of the modal rather than creation of a new one
                    var modalelem = createModal(
                        modalOptions,
                        elem.attr('data-replace-modal-content') ? 'replace' : null
                    );

                    if (dmodal == '1') {
                        modalelem.attr('data-modroute', droute);
                        modalelem.attr('data-modcrud', dcrud);
                        modalelem.attr('data-modid', did);
                        modalelem.attr('data-modidval', didval);
                        modalelem.attr('data-moddscript', dscript);
                        modalelem.attr('data-modoption', doption);
                        modalelem.attr('data-modpath', dpath);
                    }
                    break;
            }

            function onSuccess(
                data,
                requestData,
                modalelem,
                title,
                did,
                didval,
                dpath,
                daction,
                dscript,
                droute,
                doption,
                dmodaltabbed
            ) {
                var modalbody =
                    dmodaltabbed === '1'
                        ? modalelem.find('#modal-body-tab')
                        : modalelem.find('.modal-body');

                if (data.success) {
                    modalbody.html(data['data']);
                } else {
                    // Error msg
                    const errMsgText =
                        data.error_message ??
                        data.message ??
                        'There was an error communicating with the backend.';
                    modalbody.html(site.getAlert('ALERT_WARNING', errMsgText));
                }

                if (dmodaltabbed === '1') {
                    tabHeader = $('<div class="modal-tab-header"></div>');
                    tabHeader.append('<span>' + title + '</span>');

                    if (
                        daction === 'edit' ||
                        dscript === 'module_records_view_edit' ||
                        dscript === 'module_records_view_sync'
                    ) {
                        tabHeader.append(
                            "<a class='btn btn-action btn-primary' data-action='" +
                                daction +
                                "'" +
                                "   data-crud='1' data-modal='1' data-path='" +
                                dpath +
                                "' data-route='" +
                                droute +
                                "'" +
                                "   data-modal-title='" +
                                title +
                                "' data-idval='" +
                                didval +
                                "' data-id='" +
                                did +
                                "'" +
                                "   data-script='" +
                                dscript +
                                "' data-option='" +
                                doption +
                                "'" +
                                "   href='#' data-toggle='tooltip' data-container='body' title='" +
                                title +
                                "'>" +
                                '  Edit' +
                                '</a>'
                        );
                    }

                    modalbody.prepend(tabHeader);
                }

                if (modalelem.find('#adminform #overwritepost').length > 0) {
                    didval = modalelem.find('#adminform #_id').val();
                    dpath = modalelem.find('#adminform #overwritepath').val();
                    modalelem.attr('data-modidval', didval);
                    modalelem.attr('data-modpath', dpath);
                }

                modalelem.find('#adminform').addClass('form-horizontal');
                modalelem.find('form :input:not([type=hidden],[readonly],:disabled):first').focus();
                site.tooltip();

                var $header = modalelem.find('.modal-header :header').first();
                $header.find('.help_container').remove();

                if (data.help_document_link) {
                    var $trigger = new HelpDocument(data.help_document_link, {});
                    $header.append(
                        $(
                            '<div class="pull-right help_container" style="margin-right: 10px;">'
                        ).append($trigger)
                    );
                }

                switch (dscript) {
                    case 'routes_with_submodals_': // View Umanksy for reference to this
                        site.subactions(did, didval, dscript, doption, daction);
                        break;

                    case 'tenant-companies_edit':
                        site.datachangeToogle();
                        site.colorPicker();
                        break;

                    case 'tenant-companies_view':
                        site.updateProv();
                        site.colorPicker();
                        break;

                    case 'tenant-subentities_view':
                    case 'tenant-subentities_edit':
                    case 'users_view':
                    case 'profile_edit':
                        site.updateProv();
                        break;

                    case 'module_records_view_edit':
                        site.expireDateOptions();
                        break;

                    case 'pushnotifications_view':
                        break;

                    case 'menu_format_edit':
                        site.menuControls();
                        break;

                    case 'dashboard_style_edit':
                        site.dashboardStyleEditControls();
                        break;

                    case 'app-access':
                    case 'data-source':
                    case 'module_records_nosave_edit':
                        break;

                    case 'module_types_available_all_add':
                    case 'module_types_available_latest_add':
                        var $previousModal = modalStack.getPrevious();
                        if ($previousModal) {
                            site.subDataTableReload($previousModal);
                        }
                        break;

                    case 'app-versions_edit':
                    case 'help_files_view':
                    case 'module_types_available_edit':
                    case 'integrations_edit':
                        break;

                    default:
                        site.dataclickToogle();

                        // Check for table rank, initialize sortable
                        site.subRecordsTable(modalelem);
                        site.subDataTableInit(modalelem);
                        break;
                }
            }
        });

        $('body').on('click', '.btn-footeraction', function (e) {
            e.preventDefault();
            site.performFooterAction($(this));
        });


        function handleButtonSubactions(elem, modalelem, daction) {
            var dscript = elem.attr('data-script');
            var did = elem.attr('data-id');
            var didval = elem.attr('data-idval');
            var dmodal = elem.attr('data-modal');
            var dcrud = 0;
            var droute = dscript; // By default the route will be the script code (it can be overwritten if defined)
            var dpath = 'backend';
            var doption = '';

            if (elem.attr('data-option')) {
                doption = elem.attr('data-option');
            }

            if (elem.attr('data-crud')) {
                dcrud = elem.attr('data-crud');
            }

            if (elem.attr('data-route')) {
                droute = elem.attr('data-route');
            }

            if (elem.attr('data-path')) {
                dpath = elem.attr('data-path');
            }

            var dsubmode = daction;
            if (elem.attr('data-dsubmode')) {
                dsubmode = elem.attr('data-dsubmode');
            }

            var method = 'POST';
            var route = '';

            if (droute != '' && droute != dscript) {
                // Use route
                route = site.mainurl + '' + dpath + '/' + droute;
            }

            var data = {
                custom_data: {},
            };

            var customData = {};
            if (elem.attr('data-encoded')) {
                customData = Utils.decodeData(elem.attr('data-encoded'));
            }

            if (daction === 'clone') {
                // Since the parameters are not on the clone button, grab them from the edit modal
                dscript = modalelem.attr('data-moddscript');
                didval = modalelem.attr('data-modidval');
                doption = modalelem.attr('data-modoption');
                dcrud = modalelem.attr('data-modcrud');
                did = modalelem.attr('data-modid');
                droute = modalelem.attr('data-modroute');
                dpath = modalelem.attr('data-modpath');
            }

            data = $.extend({}, data, customData);

            if (dcrud == 1) {
                switch (
                    daction // URL to present the form in the screen
                ) {
                    case 'edit':
                        method = 'GET';
                        route = site.mainurl + '' + dpath + '/' + droute + '/' + didval + '/edit';
                        break;

                    case 'add':
                        var method = 'GET';
                        route = site.mainurl + '' + dpath + '/' + droute + '/create';
                        break;

                    case 'clone':
                        var method = 'GET';
                        route = site.mainurl + '' + dpath + '/' + droute + '/' + didval + '/clone';
                        break;

                    case 'view':
                        var method = 'GET';
                        route = site.mainurl + '' + dpath + '/' + droute + '/' + didval;
                        break;
                }
            }

            if (elem.attr('data-method')) {
                method = elem.attr('data-method');
                data._method = method;
            }

            data.dscript = dscript;
            data.did = did;
            data.didval = didval;
            data.doption = doption;
            data.action = 'admin_' + daction;
            data.modalTitle = elem.attr('data-modal-title');

            data = $.extend({}, data, elem.data('ajax_data') || {});

            switch (daction) {
                case 'clone':
                    //Replace the current modal instead of stacking another one to avoid duplicate ids for the form fields.
                    const modalBaseTitle = elem.closest('.modal')?.attr('data-modal-base-title');
                    data.modalTitle = modalBaseTitle ? modalBaseTitle+ ': Edit Cloned Record' : 'Edit Cloned Record';
                    //Pass supressRefresh = true to the modalStack to avoid the header of the new modal to be replaced by the header of the parent.
                    modalStack.back(true);
                case 'edit':
                case 'add':
                case 'view':
                    var footerHtml = ModalFooter.getFooterHtml(daction, dscript);

                    var ajaxOptions = {
                        url: route,
                        type: method,
                        datatype: 'json',
                        data: data,
                    };

                    var props = {};
                    if (elem.attr('data-modal-props')) {
                        props = Utils.decodeData(elem.attr('data-modal-props'));
                    }

                    var modalOptions = {
                        ajaxOptions: ajaxOptions,
                        title: data.modalTitle,
                        footerHtml: footerHtml,
                        props: props,
                        onSuccess: function ($modal, response) {
                            onSuccess(response, null, $modal, didval, dpath, daction, dscript);
                        },
                    };

                    var modalelem = createModal(modalOptions);

                    if (dmodal == '1') {
                        modalelem.attr('data-modroute', droute);
                        modalelem.attr('data-modcrud', dcrud);
                        modalelem.attr('data-modid', did);
                        modalelem.attr('data-modidval', didval);
                        modalelem.attr('data-moddscript', dscript);
                        modalelem.attr('data-modoption', doption);
                        modalelem.attr('data-modpath', dpath);

                        //Attach the base title from the button so it can be passed on to the clone modal.
                        modalelem.attr('data-modal-base-title', elem.attr('data-modal-base-title'));
                    }

                    function onSuccess(
                        data,
                        requestData,
                        modalelem,
                        didval,
                        dpath,
                        daction,
                        dscript
                    ) {
                        var modalbody = modalelem.find('.modal-body');

                        if (data.success) {
                            modalbody.html(data['data']);
                            if (daction === 'clone' && data.record_id) {
                                // After cloning a record the request returns a different record_id than the one the edit dialog was first created with.
                                // Update the modal to ensure the cloned (and not the original) record gets saved to.
                                modalelem.attr('data-modidval', data.record_id);
                            }
                        } else {
                            // Error msg
                            const errMsgText =
                                data.error_message ??
                                data.message ??
                                'There was an error communicating with the backend.';
                            modalbody.html(site.getAlert('ALERT_WARNING', errMsgText));
                        }

                        modalelem.find('#adminform').addClass('form-horizontal');
                        modalelem
                            .find('form :input:not([type=hidden],[readonly],:disabled):first')
                            .focus();

                        var $header = modalelem.find('.modal-header :header').first();
                        $header.find('.help_container').remove();

                        if (data.help_document_link) {
                            var $trigger = new HelpDocument(data.help_document_link, {});
                            $header.append(
                                $(
                                    '<div class="pull-right help_container" style="margin-right: 10px;">'
                                ).append($trigger)
                            );
                        }

                        switch (dscript) {
                            case 'routes_with_submodals_': // View Umanksy for reference to this
                                site.subactions(did, didval, dscript, doption, daction);
                                break;

                            case 'module_records_sub_edit':
                            case 'module_records_sub_table_edit':
                                site.removephotoaction();
                                site.expireDateOptions();
                                break;

                            case 'app-versions_add':
                            case 'app-versions_edit':
                                site.checkboxModulesUncheckAll();
                                site.checkboxModulesCheckAll();
                                site.checkboxModulesCheckLatest();
                                break;

                            default:
                                site.dataclickToogle();
                                break;
                        }
                    }
                    break;
            }
        }

        $('body').on('click', '.btn-subaction', function (e) {
            e.preventDefault();
            e.stopPropagation();
            site.checkSession();
            const elem = $(this);
            const modalElem = elem.closest('.modal');
            const daction = elem.attr('data-action');

            if (daction === 'clone') {
                bootbox.confirm("If you have any unsaved changes they will be lost.", function(result) {
                    if (result) {
                        handleButtonSubactions(elem, modalElem, daction);
                    }
                });
            } else {
                handleButtonSubactions(elem, modalElem, daction);
            }
        });
    },
    performFooterAction: function ($el) {
        site.checkSession();

        var subelem = $el;
        var modalelem = $el.closest('.modal.in');
        var subaction = subelem.attr('data-action');
        var parentele = $('body');
        // Note that some of the btns can be also used as plain buttons. data-plain attr => No modal
        var buttonplain = 0;

        if (subelem.attr('data-plain')) {
            buttonplain = subelem.attr('data-plain');
        }

        // Clear any status box, if there is one
        modalelem.find('#status-box').html('');

        if (buttonplain == 1) {
            // Same modal likely to be already used by another data-script
            var dscript = subelem.attr('data-script');
            var didval = subelem.attr('data-idval');
            var did = subelem.attr('data-id');
            var dcrud = 0;
            var dpath = 'backend'; // Can be overwritten with a data-path param
            var droute = dscript; // By default the route will be the script code (it can be overwritten if defined)
            var doption = '';

            // For many to many relationship tables, it will have the model|id of the relation
            if (subelem.attr('data-option')) {
                doption = subelem.attr('data-option');
            }

            if (subelem.attr('data-crud')) {
                dcrud = subelem.attr('data-crud');
            }

            if (subelem.attr('data-route')) {
                droute = subelem.attr('data-route');
            }

            if (subelem.attr('data-path')) {
                dpath = subelem.attr('data-path');
            }
        } else {
            // Get details from modal. To handle stacked modals,
            // get the closest modal to the btn.
            var parentele = modalelem;

            if (!modalelem.length) {
                console.warn('Footer btn does not belong to a modal');
            }

            var modalbody = modalelem.find('.modal-body');
            var modalfooter = modalelem.find('.modal-footer');
            var dscript = modalelem.attr('data-moddscript');
            var didval = modalelem.attr('data-modidval');
            var did = modalelem.attr('data-modid');
            var dcrud = 0;
            var dpath = 'backend';
            var droute = dscript; //By default the route will be the script code (it can be overwritten if defined)
            var doption = '';

            if (modalelem.attr('data-modoption')) {
                doption = modalelem.attr('data-modoption');
            }

            if (modalelem.attr('data-modcrud')) {
                dcrud = modalelem.attr('data-modcrud');
            }

            if (modalelem.attr('data-modpath')) {
                dpath = modalelem.attr('data-modpath');
            }

            if (modalelem.attr('data-modroute')) {
                droute = modalelem.attr('data-modroute');
            }
        }

        var route = site.mainurl + dpath + '/' + droute;

        if (dcrud == 1) {
            if (didval != '0') {
                // Save from EDIT
                // nerds/{id}/edit
                route += '/' + didval;
            }
        }

        switch (subaction) {
            case 'back_refresh':
                var $previousModal = modalStack.back();
                if ($previousModal) {
                    $previousModal.refresh();
                }
                break;

            case 'save_local':
                // Do nothing. Close modal element
                modalStack.back();
                break;

            case 'ajax_action':
                // Move confirm
                site.runAjaxActions(dscript, did, didval, modalelem, dcrud, route, doption);
                break;

            case 'delete':
                bootbox.confirm('Are you sure?', function (result) {
                    if (result) {
                        site.runAjaxDelete(dscript, did, didval, modalelem, dcrud, route, doption);
                    }
                });
                break;

            case 'save':
                var handleFooterActionAddSuccess = function (
                    data,
                    parentele,
                    modalelem,
                    subelem,
                    dscript
                ) {
                    // Add
                    if (data['success'] == false || data['success'] == 'false') {
                        Utils.loader('hide');
                        subelem.removeClass('disabled');

                        const errMsgText =
                            data.error_message ??
                            data.message ??
                            'There was an error communicating with the backend.';
                        parentele
                            .find('#adminform')
                            .after(
                                "<div class='clear clearfix error-label'></div><div class='bg-warning margin-top-10 error-label alert'>" +
                                    errMsgText +
                                    '</div>'
                            );

                        if (data.hasOwnProperty('errors')) {
                            for (let prop in data['errors']) {
                                parentele
                                    .find('.error-label.alert')
                                    .append('<br>' + data['errors'][prop][0]);
                            }
                        }
                    } else {
                        switch (dscript) {
                            case 'module_clone_new_module':
                                modalStack.clear();
                                location.reload();
                                break;

                            case 'module_records_view_sync':
                                modalbody.html(data['message']);

                                // Sync the data
                                route = new URL(data.data.sync_url, site.mainurl).toString();
                                site.ajax_sync(route, modalelem);
                                break;

                            case 'module_records_nosave_edit':
                                modalStack.back();
                                break;

                            default:
                                console.log('Add - Subtable reload');
                                let $previousModal = modalStack.back();
                                if ($previousModal) {
                                    site.subDataTableReload($previousModal);
                                }
                                break;
                        }
                    }
                };

                var handleFooterActionEditSuccess = function (
                    data,
                    parentele,
                    modalelem,
                    subelem,
                    dscript
                ) {
                    // Edit
                    if (data['success'] == false) {
                        Utils.loader('hide');
                        subelem.removeClass('disabled');

                        const errMsgText =
                            data.error_message ??
                            data.message ??
                            'There was an error communicating with the backend.';
                        parentele
                            .find('#adminform')
                            .after(
                                "<div class='clear clearfix error-label'></div><div class='bg-warning margin-top-10 error-label alert'>" +
                                    errMsgText +
                                    '</div>'
                            );

                        if (data.hasOwnProperty('errors')) {
                            for (var prop in data['errors']) {
                                parentele
                                    .find('.error-label.alert')
                                    .append('<br>' + data['errors'][prop][0]);
                            }
                        }
                    } else {
                        switch (dscript) {
                            case 'module_records_view_sync':
                                modalbody.html(data['message']);

                                // Sync the data
                                route = new URL(data.data.sync_url, site.mainurl).toString();
                                site.ajax_sync(route, modalelem);
                                break;

                            case 'module_import_csv_view_edit':
                                $('#csv_process_progress').trigger('progress:csv_process', [data]);
                                break;

                            case 'module_import_ics_view_edit':
                                $('#ics_process_progress').trigger('progress:ics_process', [data]);
                                break;

                            default:
                                //If success message is present show it briefly before closing
                                if (data.message) {
                                    modalbody.html(
                                        `<div class="clear clearfix error-label"></div><div class="bg-success margin-top-10 error-label alert">${data.message}</div>`
                                    );
                                }
                                const delay = data.message ? 3000 : 0;
                                setTimeout(() => {
                                    subelem.removeClass('disabled');

                                    let $previousModal = modalStack.back();
                                    if ($previousModal) {
                                        site.subDataTableReload($previousModal);
                                    }
                                }, delay);
                                break;
                        }
                    }
                };

                var handleFooterActionSave = function (
                    modalelem,
                    parentele,
                    subelem,
                    did,
                    didval,
                    dscript
                ) {
                    Utils.loader('shown');
                    subelem.addClass('disabled');
                    var formValidator = new FormValidator();
                    var valid = formValidator.validate(parentele, subelem);

                    if (!valid) {
                        let message = formValidator.getErrors()[0];
                        console.log('Validation error ' + message);

                        parentele.find('#adminform .form_spinner').hide();
                        Utils.loader('hide');
                        subelem.removeClass('disabled');
                        parentele
                            .find('#adminform')
                            .after(
                                "<div class='clear clearfix error-label'></div><div class='bg-warning margin-top-10 error-label alert'>" +
                                    message +
                                    '</div>'
                            );
                        return;
                    }

                    parentele
                        .find('[data-id="media_table"], [data-id="upload_table"]')
                        .each(function () {
                            site.reorderRowInputNames($(this));
                        });

                    var requestData = new FormData(parentele.find('#adminform')[0]);
                    requestData.append('action', 'admin_save');
                    requestData.append('dscript', dscript);
                    requestData.append('did', did);
                    requestData.append('didval', didval);

                    if ($('textarea.ckeditor').length > 0) {
                        $.each(Utils.getEditorData(CKEDITOR, parentele), function (key, val) {
                            requestData.append(key, val);
                        });
                    }

                    var $vueJsonData = parentele.find('[name="__vue_json_data"]');
                    if ($vueJsonData.length) {
                        // Merge everything from Vue into the form data
                        requestData.append('__json_data', $vueJsonData.val());
                    }
                    $.ajax({
                        url: route,
                        type: 'POST',
                        datatype: 'json',
                        processData: false,
                        contentType: false,
                        timeout: 300000,
                        data: requestData,
                    })
                        .then(function (data) {
                            parentele.find('#adminform .form_spinner').hide();
                            parentele
                                .find('#adminform')
                                .find('.trigger_event')
                                .each(function () {
                                    $('.ajax_container').trigger($(this).val(), data);
                                });

                            modalStack.setRefresh(true);

                            var isAdd = didval == '0' || didval == '';
                            //The below handles the success===false scenario
                            if (isAdd) {
                                handleFooterActionAddSuccess(
                                    data,
                                    parentele,
                                    modalelem,
                                    subelem,
                                    dscript
                                );
                            } else {
                                handleFooterActionEditSuccess(
                                    data,
                                    parentele,
                                    modalelem,
                                    subelem,
                                    dscript
                                );
                            }
                        })
                        .catch(function (res) {
                            const errMsgText =
                                res.error_message ??
                                res.message ??
                                'There was an error communicating with the backend.';
                            parentele
                                .find('#adminform')
                                .after(
                                    "<div class='clear clearfix error-label'></div><div class='bg-warning margin-top-10 error-label alert errorMsge'>" +
                                        errMsgText +
                                        '</div>'
                                );
                        });
                };

                switch (dscript) {
                    case 'module_clone_other':
                        var cloneFrom = parentele.find('#adminform').find('#clonemodule').val();
                        site.checkModuleForClone(didval, doption, cloneFrom)
                            .then(function (response) {
                                if (!response.success) {
                                    return Promise.reject(response);
                                }
                                handleFooterActionSave(
                                    modalelem,
                                    parentele,
                                    subelem,
                                    did,
                                    didval,
                                    dscript
                                );
                            })
                            .catch((res) => {
                                const errMsgText =
                                    res.error_message ??
                                    res.message ??
                                    'There was an error communicating with the backend.';
                                const errMsgHtml = `<div id='ajax-error' class='errorMsge'>${errMsgText}</div>`;
                                const $modalBody = modalelem?.find('.modal-body');
                                if ($modalBody) {
                                    $modalBody.append(errMsgHtml);
                                }
                            });
                        break;
                    default:
                        handleFooterActionSave(modalelem, parentele, subelem, did, didval, dscript);
                        break;
                }

                break;
        }
    },
    expireDateOptions: function () {
        if ($("input[name='expire_date_type']").length > 0) {
            $('#expire_date').attr('data-validate', 'required');

            var me = $("input[name='expire_date_type']:checked");
            var merel = me.attr('data-expires');
            switch (me.val()) {
                case 'expired':
                    $('input[name=expire_date]').val(merel);
                    $('#expire_date').hide();
                    break;

                case 'never':
                    $('#expire_date').hide();
                    break;

                case 'on':
                    $('#expire_date').show();
                    break;
            }

            $("input[name='expire_date_type']").on('change', function () {
                var me = $(this);
                var merel = me.attr('data-expires');

                switch (me.val()) {
                    case 'expired':
                    case 'never':
                        $('input[name=expire_date]').val(merel);
                        $('#expire_date').hide();
                        break;
                    case 'on':
                        // If switching to on, clear all the current date information
                        // and make sure the date picker is picking from now.
                        $('input[name=expire_date]').val('');
                        $('#expire_date').data('DateTimePicker').date(null);
                        $('#expire_date').data('DateTimePicker').defaultDate(false);
                        $('#expire_date').show();
                        break;
                }
            });
        }
    },
    removephotoaction: function () {
        if ($('.currentphoto').length > 0) {
            $('.btn-delete-photo').click(function (e) {
                $('.currentphoto').remove();
                $('#deletephoto').val('1');
                $('.btn-delete-photo').remove();
            });
        }
    },
    addField: function (id, doption, fieldType, namesMap) {
        var $previousModal = modalStack.getPrevious();
        if ($previousModal) {
            $previousModal.setFullRefresh(true);
        }

        var data = {
            didval: id,
            doption: doption,
            type: fieldType,
            names: namesMap,
        };
        //Ajax call: maps to ModuleColumnsDynamicController@addField
        return $.ajax({
            url: site.mainurl + 'tenant-company/content/module-columns-dynamic-add',
            type: 'POST',
            datatype: 'json',
            data: data,
        });
    },
    runAjaxActions: function (dscript, did, didval, modalelem, dcrud, route, doption, customData, refreshModalAfter) {
        // if Crud, I have to define the right route, if not crud, just use the route with the did. for customization I can use dscript too
        // In some cases I need to add extra parameters to the ajax call.
        // EX> in csv import I need to give an extra
        var extra = '';
        var extraData = '';
        var method = 'POST';

        switch (dscript) {
            case 'module_import_csv':
                // Check if the flag for remove_existing exists
                // Hidden input updated on change
                var $input = modalelem.find('input[name="_remove_existing"]');
                var removeExisting = $input.val();
                if (removeExisting === '1' || removeExisting === 1) {
                    // Will remove existing data
                    extra = '1';
                }

                method = 'PUT';
                if (route.includes('module-csv-confirm')) {
                    var $confirmImportForm = $('#confirm_import_form');
                    extraData += '&import_type=' + $confirmImportForm.find('#import_type').val();
                    extraData += '&import_keys=' + $confirmImportForm.find('#import_keys').val();
                    method = 'POST';
                }

                site.disableButtons();
                break;

            case 'module_import_ics':
                // Check if the flag for remove_existing exists
                // Hidden input updated on change
                var $input = modalelem.find('input[name="_remove_existing"]');
                var removeExisting = $input.val();
                if (removeExisting === '1' || removeExisting === 1) {
                    // Will remove existing data
                    extra = '1';
                }

                method = 'PUT';
                if (route.includes('module-ics-confirm')) {
                    var $confirmImportForm = $('#confirm_import_form');
                    method = 'POST';
                }

                site.disableButtons();
                break;

            case 'columns_dynamic_settings_update':
                var extra = '';

                $('#settingsform')
                    .find('input')
                    .each(function () {
                        if ($(this).attr('type') !== 'hidden') {
                            if ($(this).attr('type') === 'checkbox') {
                                if ($(this).is(':checked')) {
                                    extra += '&' + $(this).attr('id') + '=true';
                                } else {
                                    extra += '&' + $(this).attr('id') + '=false';
                                }
                            } else {
                                extra += '&' + $(this).attr('id') + '=' + $(this).val();
                            }
                        }
                    });

                method = 'PUT';
                break;

            case 'enhanced_access_password_reset':
                site.disableButtons();
                break;

            case 'forums':
                const { triggerElement } = customData;

                extraData += `&targetType=${triggerElement.attr('data-target-type')}`
                    + `&targetId=${triggerElement.attr('data-target-id')}`
                    + `&targetAction=${triggerElement.attr('data-target-action')}`
                    + `&value=${triggerElement.attr('data-value')}`;

                break;
        }

        var dataStr =
            '_method=' +
            method +
            '&doption=' +
            doption +
            '&dscript=' +
            dscript +
            '&did=' +
            did +
            '&didval=' +
            didval +
            '&extra=' +
            extra +
            extraData;

        $.ajax({
            url: route,
            type: 'POST',
            datatype: 'json',
            data: dataStr,
        })
            .then(function (data) {
                if (data.success) {
                    if (refreshModalAfter) {
                        modalStack.refreshCurrent();
                    }
                    switch (dscript) {
                        case 'module_import_csv':
                            site.enableButtons();

                            $('#csv_migrate_progress').trigger('progress:csv_migrate', data);
                            break;

                        case 'module_import_ics':
                            site.enableButtons();

                            $('#ics_migrate_progress').trigger('progress:ics_migrate', data);
                            break;

                        case 'enhanced_access_password_reset':
                            $('#status-box').html(site.getAlert('ALERT_SUCCESS', data['message']));
                            if (data['refresh'] || data['refresh'] === 'true') {
                                site.disableButtons();
                                document.location = document.location;
                            } else {
                                site.enableButtons();
                            }
                            break;

                        case 'generate_api_key':
                            if (data['refresh'] || data['refresh'] === 'true') {
                                document.location = document.location;
                            }
                            break;
                    }
                } else {
                    const errMsgText =
                        data.error_message ??
                        data.message ??
                        'There was an error communicating with the backend.';
                    switch (dscript) {
                        case 'module_import_csv':
                            modalelem
                                .find('#status-box')
                                .html(site.getAlert('ALERT_WARNING', errMsgText));
                            site.enableButtons();
                            break;

                        case 'module_import_ics':
                            modalelem
                                .find('#status-box')
                                .html(site.getAlert('ALERT_WARNING', errMsgText));
                            site.enableButtons();
                            break;

                        case 'enhanced_access_password_reset':
                            $('#status-box').html(site.getAlert('ALERT_WARNING', errMsgText));
                            site.enableButtons();
                            break;

                        case 'generate_api_key':
                            modalelem
                                .find('#status-box')
                                .html(site.getAlert('ALERT_WARNING', errMsgText));
                            break;
                    }
                }
            })
            .catch(function (res) {
                const errMsgText =
                    res.error_message ??
                    res.message ??
                    'There was an error communicating with the backend.';
                modalelem.find('#status-box').html(site.getAlert('ALERT_WARNING', errMsgText));
            });
    },
    // If CRUD, define the correct route. If not CRUD, just use the route with the did. Use dscript for customization.
    runAjaxDelete: function (dscript, did, didval, modalelem, dcrud, route, doption, requestData) {
        site.checkSession();
        // Crud will use the DELETE method (set in the parent form)
        Utils.loader('show');
        var requestData = {};
        requestData['_method'] = 'DELETE';
        requestData['doption'] = doption;
        requestData['dscript'] = dscript;
        requestData['did'] = did;
        requestData['didval'] = didval;

        return $.ajax({
            url: route,
            type: 'POST',
            datatype: 'json',
            data: requestData,
        })
            .then(function (data) {
                if (data.success) {
                    if (!modalelem.length) {
                        site.disableButtons();
                        location.reload();
                    } else {
                        // Reload the data in the datatable after delete
                        var $dataTable = modalelem.find('.dataTables-table');
                        if (!$dataTable.length) {
                            var $previousModal = modalStack.back();
                            if ($previousModal) {
                                $dataTable = $previousModal.find('.dataTables-table');
                            } else {
                                location.reload();
                            }
                        }

                        if ($dataTable.length) {
                            var dataTable = $dataTable.DataTable();
                            var pageInfo = dataTable.page.info();
                            if (pageInfo && pageInfo.serverSide) {
                                // Server side DataTable
                                dataTable.ajax.reload();
                            }
                        }

                        modalStack.setRefresh(true);
                    }
                } else {
                    return Promise.reject(data);
                }
                return data;
            })
            .catch(function (res) {
                const errMsgText =
                    res.error_message ??
                    res.message ??
                    'There was an error communicating with the backend.';
                var box = bootbox.alert(errMsgText, function () {
                    // Remove loader
                    Utils.loader('hide');
                });
                site.resetModalScroll(box);
            });
    },
    menuControls: function () {
        // If radio mode is sandwich hide
        if ($("#adminform input[name='display_mode']:checked").val() === 'grid') {
            $('.buttons_style_holder').show();
        } else {
            $('.buttons_style_holder').hide();
        }

        // On change?
        $("#adminform input[name='display_mode']").change(function (e) {
            if ($("#adminform input[name='display_mode']:checked").val() === 'grid') {
                $('.buttons_style_holder').show();
            } else {
                $('.buttons_style_holder').hide();
            }
        });
    },
    dashboardStyleEditControls: function () {
        $('.form-colorpicker').colorpicker();
    },
    enableButtons: function ($root) {
        var $el = !$root || ($root && !$root.length) ? $('body') : $root;
        $el.find('.btn:not(.btn-keep-disabled)').removeClass('disabled').removeAttr('disabled');
    },
    disableButtons: function ($root) {
        var $el = !$root || ($root && !$root.length) ? $('body') : $root;
        $el.find('.btn:not(.btn-keep-disabled)').addClass('disabled').attr('disabled', 'disabled');
    },
    enableInputs: function ($root) {
        var $el = !$root || ($root && !$root.length) ? $('body') : $root;
        $el.find(':input,select')
            .not(':button, :submit, :reset, :input[type=hidden]')
            .removeClass('disabled')
            .removeAttr('disabled');
    },
    disableInputs: function ($root) {
        var $el = !$root || ($root && !$root.length) ? $('body') : $root;
        $el.find(':input,select')
            .not(':button, :submit, :reset, :input[type=hidden]')
            .addClass('disabled')
            .attr('disabled', 'disabled');
    },
    clearInput: function ($el) {
        Utils.clearInput($el);
    },
    clearInputs: function ($root) {
        Utils.clearInputs($root);
    },
    colorPicker: function () {
        if ($('.colorpicker').length > 0) {
            $('body').find('.colorpicker').colorpicker({});
        }
    },
    checkboxModulesUncheckAll: function () {
        var $button = $('.checkbox_modules_uncheckall');
        if ($button.length > 0) {
            $button.click(function (e) {
                e.preventDefault();
                $('.checkbox_modules_group')
                    .find('input[type="checkbox"]:not([disabled])')
                    .prop('checked', false);
            });
        }
    },
    checkboxModulesCheckAll: function () {
        var $button = $('.checkbox_modules_checkall');
        if ($button.length > 0) {
            $button.click(function (e) {
                e.preventDefault();
                $('.checkbox_modules_group')
                    .find('input[type="checkbox"]:not([disabled])')
                    .prop('checked', true);
            });
        }
    },
    checkboxModulesCheckLatest: function () {
        var $button = $('.checkbox_modules_checklatest');
        if ($button.length > 0) {
            $button.click(function (e) {
                e.preventDefault();
                $('.checkbox_modules_group').each(function () {
                    $(this)
                        .find('input[type="checkbox"]:not([disabled])')
                        .last()
                        .prop('checked', true);
                });
            });
        }
    },
    radiomodulescheckall: function () {
        if ($('.radio_modules_checkall').length > 0) {
            $('.radio_modules_checkall').click(function (e) {
                e.preventDefault();
                $('.radio_modules_group').find("input[type='radio']:last").prop('checked', true);
            });
        }
    },
    checkSession: function () {
        // Synchronous call (blocking)
        var routesession = site.mainurl + 's-control/check-session';
        $.ajax({
            url: routesession,
            type: 'GET',
            async: false,
            datatype: 'json',
            success: function (data) {
                if (data.success) {
                    return true;
                } else {
                    // Redirect to /logout
                    site.disableButtons();
                    document.location = site.mainurl + 'logout';
                    return false;
                }
            },
            error: function (jqxhr, textStatus, errorThrown) {
                site.disableButtons();
                document.location = site.mainurl + 'logout';
                return false;
            },
        });
    },
    ajax_sync: function (route, modalelem) {
        // Show loading in the screen
        var modalbody = modalelem.find('.modal-body');

        $.ajax({
            url: route,
            type: 'get',
            datatype: 'json',
            data: '_method=GET',
        })
            .then(function (data) {
                if (data.success) {
                    modalbody.html('<div class="alert bg-success">' + data.message + '</div>');

                    setTimeout(function () {
                        let $previousModal = modalStack.back();

                        if ($previousModal) {
                            site.subDataTableReload($previousModal);
                        } else {
                            location.reload();
                        }
                    }, 3000);
                } else {
                    return Promise.reject(data);
                }
            })
            .catch(function (res) {
                const errMsgText =
                    res.error_message ??
                    res.message ??
                    'There was an error communicating with the backend.';
                modalbody.html('<div class="alert bg-warning">' + errMsgText + '</div>');
            });
    },
    subDataTableReload: function ($modal) {
        if ($modal) {
            var dataTable = $modal.find('.dataTables-table').DataTable();
            if (dataTable) {
                var pageInfo = dataTable.page.info();

                if (pageInfo && pageInfo.serverSide) {
                    // Server side DataTable
                    dataTable.ajax.reload();
                }
            }
        }
    },
    getDataTablesData: function (url, data) {
        return function (dtData) {
            return $.ajax({
                url: url + '?format=dt',
                type: 'POST',
                dataType: 'json',
                data: (function () {
                    var baseData = {
                        custom_data: { datatable: dtData },
                    };

                    return $.extend(true, baseData, data);
                })(),
            });
        };
    },
    getModuleCsvDataTablesData: function (moduleId, doption, data) {
        var requestData = {
            doption: doption,
            module_id: moduleId,
        };

        requestData = $.extend(true, requestData, data);

        return site.getDataTablesData(
            site.mainurl + 'tenant-company/content/module-csv-index' + '/' + moduleId,
            requestData
        );
    },
    getHelpFilesDataTablesData: function (data) {
        var requestData = {};

        requestData = $.extend(true, requestData, data);

        return site.getDataTablesData(site.mainurl + 'backend/help-files/index', requestData);
    },
    getIntegrationsDataTablesData: function (doption, data) {
        var requestData = {
            doption: doption,
        };

        requestData = $.extend(true, requestData, data);

        return site.getDataTablesData(
            site.mainurl + 'tenant-company/integrations/index',
            requestData
        );
    },
    getAppVersionsDataTablesData: function (doption, data) {
        var requestData = {
            doption: doption,
        };

        requestData = $.extend(true, requestData, data);

        return site.getDataTablesData(site.mainurl + 'backend/app-versions/index', requestData);
    },
    getModuleTypesAvailableDataTablesData: function (doption, data) {
        var requestData = {
            doption: doption,
        };

        requestData = $.extend(true, requestData, data);

        return site.getDataTablesData(
            site.mainurl + 'tenant-company/module-types-available/index',
            requestData
        );
    },
    getModuleRecordsDataTablesData: function (moduleId, doption, data) {
        var requestData = {
            doption: doption,
        };

        requestData = $.extend(true, requestData, data);

        return site.getDataTablesData(
            site.mainurl + 'tenant-company/content/module-records/index',
            requestData
        );
    },
    getAppAccessModuleRecordsDataTablesData: function (moduleId, doption, data) {
        var requestData = {
            doption: doption,
        };

        requestData = $.extend(true, requestData, data);

        return site.getDataTablesData(
            site.mainurl + 'tenant-company/content/app-access/index',
            requestData
        );
    },
    subDataTableInit: function ($elem) {
        // Note: Sort by element 1 if rank is enabled
        var orderinit = [];
        var $rankDragger = $elem.find('.dataTables-table .rankdragger');
        var $rank = $elem.find('.dataTables-table .rank');
        if ($rankDragger.length > 0 || $rank.length > 0) {
            // Find which column the rank field is in
            var $el =
                $rank.length > 0
                    ? $rank.first().closest('td, th')
                    : $rankDragger.first().closest('td, th');
            var colIndex = $el.closest('tr').children().index($el);
            colIndex = colIndex !== -1 ? colIndex : 1;
            orderinit = [[colIndex, 'asc']];
        }

        const $dataTable = $elem.find('.dataTables-table');

        // Must pass {retrieve: true} if the table was already initialized. See: https://datatables.net/manual/tech-notes/3
        const retrieve = $.fn.dataTable.isDataTable($dataTable);

        $dataTable.DataTable({
            retrieve,
            lengthMenu: [
                [25, 100, 250],
                [25, 100, 250],
            ],
            paging: false,
            order: orderinit,
            bAutoWidth: false,
            aoColumnDefs: [
                {
                    bSortable: false,
                    aTargets: ['nosort'],
                },
            ],
            initComplete: function (settings, json) {
                $elem.find('.dataTables-table').removeClass('my_soda_table_hidden');
                $elem.find('.dataTables-table tbody').fadeIn();
                $elem.find('.list-view-toogle').show();
                $elem.find('.soda-spinner').remove();
            },
        });
    },
    subRecordsTable: function ($modal) {
        var $table = $modal.find('.dataTables-table.table-sortable');

        $table.find('tbody').sortable({
            handle: '.rankdragger', // Note that the field must have a span .rankdragger icon Update controller to add this,
            start: function (e, ui) {
                // Fix the placeholder so that the appropriate placeholder columns remain hidden
                var $ths = $table.find('th');
                var $placeholder = ui.placeholder;

                $tds = $placeholder.find('td');
                $ths.each(function (i) {
                    if (!$(this).is(':visible')) {
                        $tds.eq(i).css({ display: 'none' });
                    }
                });
            },
            change: function (e, ui) {
                // Triggered when rows flip position in the UI.
                // Perform validation if the data-validate-sortable-drop attribute is present.
                var validate = !!$table.attr('data-validate-sortable-drop');

                if (validate) {
                    // Check if an element was dragged to an invalid position (below an unranked element) and cancel the drag if so.
                    var $sortable = $table.find('tbody');
                    var verticalOffset = $sortable.position().top;
                    var $firstUnrankedRow = $table.find('tr > td > .__unranked').first().closest('tr');
                    var rowHeight = $firstUnrankedRow.height();
                    var rankedRowsCount = $table.find('tbody > tr .rankdragger').length;
                    var lowestValidDrop = verticalOffset + (rankedRowsCount * rowHeight) + rowHeight;
                    var currentDrop = ui.position.top;

                    if (currentDrop > lowestValidDrop) {
                        $table.find('tbody').sortable("cancel");
                    }
                }
            },
            stop: function (e, ui) {
                // Triggered when the user stopped sorting and the DOM position has changed.
                site.sortablerank($modal.find('.dataTables-table.table-sortable'));
                return;
            },
        });
    },
    getDataTableSpinnerHtml: function () {
        return '<i class="fas fa-spinner fa-spin fa-1x fa-fw dataTables-spinner"></i>';
    },
    subactions: function (did, didval, dscript, doption, daction) {
        // Scripts that require to open another modal (see Umanksy for reference on this function)
    },
    datachangeToogle: function () {
        if ($('.toogle_another').length > 0) {
            $('.toogle_another').change(function () {
                var metoogler = $(this);
                var targetelms = metoogler.attr('data-toogle_target');
                if (metoogler.val() == 0) {
                    $(targetelms).hide();
                    $(targetelms).addClass('hidden');
                } else {
                    $(targetelms).show();
                    $(targetelms).removeClass('hidden');
                }
            });
        }
    },
    dataclickToogle: function () {
        if ($('.toogle_another_click').length > 0) {
            $('.toogle_another_click').click(function (e) {
                e.preventDefault();
                var metoogler = $(this);
                var targetelms = metoogler.attr('data-toogle_target');
                if ($(targetelms).hasClass('hidden')) {
                    $(targetelms).show();
                    $(targetelms).removeClass('hidden');
                    //add required tag
                    $(targetelms).find('.when_visible_required').addClass('required');
                } else {
                    $(targetelms).hide();
                    $(targetelms).addClass('hidden');
                    //remove required tab
                    $(targetelms).find('.required').removeClass('required');
                }
            });
        }
    },
    commonactions: function () {
        $('body').on('click', '.table-tr-clickable tbody tr', function (e) {
            let $target = $(e.target);
            let $closestLink = $target.closest('a');
            if ($closestLink.is('.btn-action-delete') || $closestLink.is('.validation-message-text')) {
                e.preventDefault();
                return false;
            }

            // Since the full row is able to be clicked on, it can conflict with the
            // sortablejs/draggable functionality and in some browsers (FF) open the
            // edit dialog after a drag, the code below ensures is ignored.
            let $sorting = $target.find('.sorting_1');

            // Sometimes the target is the tr, but in FF it can also be the td which is the
            // same as the what we are looking for
            if (!$sorting.length) {
                if ($target.hasClass('sorting_1')) {
                    $sorting = $(e.target);
                }
            }

            if ($sorting.length) {
                if (
                    e.clientX > $sorting.position().left &&
                    e.clientX < $sorting.position().left + $sorting.offset().left
                ) {
                    // Ignore if it is in the sorting bounding box
                    return;
                }
            }

            if ($target.children('a,.btn').length) {
                // Do not do anything from this function
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            var tritem = $(this);
            var tridattr = tritem.attr('data-id');
            tritem
                .find(".btn-subaction[data-action='edit'][data-idval='" + tridattr + "']")
                .trigger('click');
        });

        if ($('#table_default').length > 0) {
            var table = $('#table_default').DataTable({
                lengthMenu: [
                    [25, 100, 250],
                    [25, 100, 250],
                ],
                bAutoWidth: false,
                paging: true,
                order: [[0, 'desc']],
            });
        }

        if ($('#dataTableList').length > 0) {
            var mode = $('#dataTableList').attr('data-mode');
            switch (mode) {
                case 'permissions':
                    $('#dataTableList').DataTable({
                        lengthMenu: [
                            [25, 100, 250],
                            [25, 100, 250],
                        ],
                        order: [[3, 'desc']],
                    });
                    break;

                default:
                    $('#dataTableList').DataTable({
                        lengthMenu: [
                            [25, 100, 250]
                        ],
                    });
                    break;
            }
        }

        site.tooltip();
        site.initValidationMessagesSupport();
    },
    tooltip: function () {
        $.each($('[data-toggle="tooltip"]'), function (i, el) {
            var $el = $(el);
            $el.tooltip().data('bs.tooltip').tip().addClass($el.data('tooltip-container-class'));
        });
    },
    sortablerank: function ($el) {
        // This function sorts a table or records that belown to a module
        // Get all the td elements, so I get the ids. Generate a key:value set and post
        // Put data-module on the table element
        var index = 1;
        if ($el.hasClass('dataTables-table')) {
            var dataTable = $el.DataTable();
            var pageInfo = dataTable.page.info();
            if (pageInfo && pageInfo.serverSide) {
                index = pageInfo.start + 1;
            }
        }

        var module = $el.attr('data-module');
        var language = $el.attr('data-language');

        var sets = new Array();
        $el.find('tbody tr').each(function () {
            me = $(this);
            sets.push(me.attr('data-id') + ':' + index);
            index++;
        });

        var routecoords = site.mainurl + 'tenant-company/content/module-reorder-rank';

        // Used by forums to indicate what is being reordered
        var sortType = $el.attr('data-sort-type');
        var parentId = $el.attr('data-parent-id');

        $.ajax({
            url: routecoords,
            type: 'POST',
            datatype: 'json',
            data: 'module_id=' + module + '&language=' + language + '&sort_type=' + sortType + '&parent_id=' + parentId + '&new_reorder=' + sets.join(),
        })
            .then(function (data) {
                if (!data.success) {
                    return Promise.reject(data);
                }
            })
            .catch(function (res) {
                const errMsgText =
                    res.error_message ??
                    res.message ??
                    'There was an error communicating with the backend.';
                alert(errMsgText);
            });
    },
    sortablerankmodules: function (element, moduleId, language) {
        // This function sorts a table of modules (when no moduleId is passed) or module records (when moduleId is present)
        var index = 1;
        var $el = $(element);
        if ($el.hasClass('dataTables')) {
            var dataTable = $el.DataTable();
            var pageInfo = dataTable.page.info();
            if (pageInfo && pageInfo.serverSide) {
                index = pageInfo.start + 1;
            }
        }

        var sets = new Array();
        $el.find('tbody tr').each(function () {
            me = $(this);
            sets.push(me.attr('data-id') + ':' + index);
            index++;
        });

        var routecoords = site.mainurl + 'tenant-company/content/module-reorder-rank';
        $.ajax({
            url: routecoords,
            type: 'POST',
            datatype: 'json',
            data: `module_id=${moduleId ? moduleId : 'module'}&language=` + language + `&new_reorder=` + sets.join(),
        })
            .then(function (data) {
                if (!data.success) {
                    return Promise.reject(data);
                }
            })
            .catch(function (res) {
                const errMsgText =
                    res.error_message ??
                    res.message ??
                    'There was an error communicating with the backend.';
                alert(errMsgText);
            });
    },
    clearAllPerms: function () {
        $('.perm:checked').each(function (index, element) {
            $(this).prop('checked', false);
            $(this).removeAttr('checked');
        });
    },
    addPerms: function (perms) {
        site.clearAllPerms();
        var permsloop = perms.split(',');
        for (i = 0; i < permsloop.length; i++) {
            $('#' + permsloop[i] + '.perm').prop('checked', true);
            $('#' + permsloop[i] + '.perm').attr('checked', 'checked');
        }
    },
    updateProv: function () {
        // If country is not selected, select Canada
        if ($('#country').length > 0) {
            if ($("#country option[selected='selected']").val() === '') {
                $('#country').val('canada');
            }
        }

        $('#country').change(function (e) {
            site.getProv();
        });
        site.getProv();
    },
    getProv: function () {
        if ($('#state_province').length > 0) {
            var selectedpro = $('#state_province').val();

            $.getJSON('/data/provinces', { country: $('#country').val() }).done(function (data) {
                $('#state_province')[0].options.length = 1;
                $.each(data, function (key, val) {
                    if (selectedpro === key) {
                        $('#state_province').append(
                            '<option value="' + key + '" selected="selected">' + val + '</option>'
                        );
                    } else {
                        $('#state_province').append($('<option>').text(val).attr('value', key));
                    }
                });
            });
        }
    },
    getTimezones: function (country) {
        return $.getJSON('/data/timezones', {
            country: country,
        });
    },
    limitchars: function () {
        $('.charscounter').each(function () {
            var maxChars = $(this).attr('data-maxchars');

            // First to see if charsleft is a sibling, if not then check for the closest
            var $charsLeft = $(this).siblings('.charsleft');
            if (!$charsLeft.length) {
                $charsLeft = $(this).closest('.charsleft');
            }

            // If there is a charsleft element then add a callback
            if ($charsLeft.length) {
                $(this).on('input change keyup', function () {
                    var $el = $(this);
                    var numChars = $el.val().length;
                    var numCharsRemaining = maxChars - numChars;

                    if (numCharsRemaining < 0) {
                        $el.val($.trim($el.val()).substring(0, maxChars));
                        $charsLeft.html('0 characters out of ' + maxChars + '.');
                    } else {
                        $charsLeft.html(numCharsRemaining + ' characters out of ' + maxChars + '.');
                    }
                });

                $(this).trigger('change');
            }
        });
    },
    previewImage: function (input, target) {
        var $el = target instanceof jQuery ? target : $(target);

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $el.attr('src', e.target.result);
            };

            reader.readAsDataURL(input.files[0]);
        }
    },
    reorderRowInputNames: function ($root) {
        $root.find('tbody tr').each(function (rowIndex, _row) {
            $(this)
                .find(':input')
                .each(function (_i, input) {
                    var currName = $(input).attr('name');

                    if (currName) {
                        var newName = currName.replace(/\[-?[0-9]+\]/, '[' + rowIndex + ']');
                        $(input).attr('name', newName);
                    }
                });
        });
    },
    resetModalScroll: function (elem) {
        if (elem) {
            elem.on('hidden.bs.modal', addModalOpenClass);
        } else {
            addModalOpenClass();
        }

        function addModalOpenClass() {
            if ($('body').find('.modal.in').length) {
                $('body').addClass('modal-open');
            }
        }
    },
    resizeModal: function ($modal) {
        if ($modal.length) {
            var $modalContent = $modal.find('.modal-content');
            $modalContent.css('height', 'auto');
        }
    },
    setModalToTop: function ($modal) {
        var zIndex = 1040;
        $('.modal.in').each(function () {
            zIndex = Math.max(zIndex, $(this).css('z-index'));
        });

        $modal.css('z-index', zIndex + 5);
    },
    attachDropdownToBody: function ($el) {
        // Attach dropdown to body so it doesn't get hidden by parent overflow hidden
        // https://stackoverflow.com/questions/31029300/how-to-append-a-single-dropdown-menu-to-body-in-bootstrap
        // Hold onto the drop down menu
        var $root = $el.find('.dropdown-menu').parent();
        var $dropdownMenu;

        // And when you show it, move it to the body
        $root.on('show.bs.dropdown', function (e) {
            // Grab the menu
            $dropdownMenu = $root.find('.dropdown-menu');
            $dropdownMenu.data('hidden', false);

            // Detach it and append it to the body
            $('body').append($dropdownMenu.detach());

            // Grab the new offset position
            var $toggle = $root.find('[data-toggle="dropdown"]');

            positionDropdown($dropdownMenu, $toggle);

            $modal = $root.closest('.modal.in');
            if ($modal.length) {
                $modal.on('hide.bs.modal', function (e) {
                    removeDropdown();
                });

                $modal.on('scroll', function () {
                    positionDropdown($dropdownMenu, $toggle);
                });
            }
        });

        // And when you hide it, reattach the drop down, and hide it normally
        $root.on('hide.bs.dropdown', function (e) {
            removeDropdown();
        });

        function positionDropdown($dropdownMenu, $toggle) {
            if ($dropdownMenu.data('hidden')) {
                return;
            }

            var offset = $toggle.offset();

            if ($dropdownMenu.hasClass('dropdown-menu-right')) {
                $dropdownMenu.css({
                    display: 'block',
                    top: offset.top + $toggle.outerHeight(),
                    right: $(window).width() - offset.left - $toggle.outerWidth(),
                    zIndex: 10000,
                });
            } else {
                $dropdownMenu.css({
                    display: 'block',
                    top: offset.top + $toggle.outerHeight(),
                    left: offset.left,
                    zIndex: 10000,
                });
            }
        }

        function removeDropdown() {
            $root.append($dropdownMenu.detach());
            $dropdownMenu.hide();
            $dropdownMenu.data('hidden', true);
        }
    },
    createDisabledBtnWithTooltip: function (btnSelector, wrapAttrs) {
        var $btn = $(btnSelector).clone().end().remove();
        $btn.prop('disabled', true).addClass('disabled').attr('data-toggle', 'tooltip');

        var $container = $('<div style="display: inline-block">');

        var cssProps = ['marginTop', 'marginRight', 'marginBottom', 'marginLeft'];
        $.each(cssProps, function (i, prop) {
            $btn.css(prop, '0px');
        });

        $.each(wrapAttrs, function (key, value) {
            $container.attr(key, value);
        });

        $container.append($btn.show());
        $container.tooltip();

        return $container;
    },
    getAlert: function (alertType, message) {
        message = message || '';
        switch (alertType) {
            case 'ALERT_SUCCESS':
                return [
                    '<div class="clear clearfix error-label"></div>',
                    '<div class="bg-success margin-top-10 error-label alert">' + message + '</div>',
                ].join('');
            case 'ALERT_WARNING':
                return [
                    '<div class="clear clearfix error-label"></div>',
                    '<div class="bg-warning margin-top-10 error-label alert">' + message + '</div>',
                ].join('');
            default:
                return '';
        }
    },
    checkModuleForClone: function (didval, doption, cloneFrom) {
        return $.ajax({
            url: '/tenant-company/content/module-clone-check',
            type: 'GET',
            data: {
                clonemodule: cloneFrom,
                doption: doption,
                didval: didval,
            },
        });
    },
    initValidationMessagesSupport: function () {
        // Init info for already loaded validation messages.
        site.initValidationMessagesPopoversTooltips($('body'));

        // Add support for sleeping/reactivating, and info for dynamically added messages.
        $('body').on('click', '.btn-sleep-validation-message', function (e) {
            e.stopPropagation();
            const route = site.mainurl + 'tenant-company/validation-messages/';
            const $container = $(this).parent();
            const dataAttrs = $(this).data();
            const requestData = {
                moduleId: dataAttrs.moduleId,
                recordId: dataAttrs.recordId,
                messageCode: dataAttrs.messageCode,
                language: dataAttrs.language,
                sleepMessage: dataAttrs.active ? 1 : 0,
                reactivateAll: dataAttrs.reactivateAll,
            };

            function showResponseError(err) {
                // Briefly indicate that we weren't able to sleep or reactivate the message.
                $container.hide();
                $container.after(
                    `<div class="error-message-temp text-danger">Sorry, currently unable to ${
                        dataAttrs.active ? 'hide' : 'reactivate'
                    } this.</div>`
                );
                setTimeout(() => {
                    $container.show();
                    $container.parent().find('.error-message-temp').remove();
                }, 3000);
            }

            $.post(route + 'sleep-reactivate', requestData)
                .then((response) => {
                    if (response.success) {
                        $validationMessagesContainer = $container.parent();
                        $validationMessagesContainer.html($(response.all_validation_messages_html).html());
                        site.initValidationMessagesPopoversTooltips($validationMessagesContainer);
                        if (modalStack.getCurrent()) {
                            // This action occurred in a modal; make sure the page will refresh after leaving the modal (#49731)
                            modalStack.setRefresh(true);
                        }
                    } else {
                        showResponseError();
                    }
                })
                .catch(showResponseError);
        });
    },
    initValidationMessagesPopoversTooltips: function ($containerElem) {
        $containerElem.find('.btn-sleep-validation-message').tooltip();
        $containerElem.find('.validation-message-text').popover({
            html: true,
            placement: 'bottom',
            container: 'body',
        });
    },
    getModuleLinksFromModuleTypes: function () {
        return [
            site.moduleTypes.CAMPAIGNS,
            site.moduleTypes.PLAIN_LIST,
            site.moduleTypes.SCHEDULE_LIST,
        ];
    },
    getMenuAddRecordModuleTypes: function () {
        return [
            site.moduleTypes.CAMPAIGNS,
            site.moduleTypes.CONTACT_LIST,
            site.moduleTypes.LOCATIONS,
            site.moduleTypes.PDF_LIST,
            site.moduleTypes.PLAIN_LIST,
            site.moduleTypes.SCHEDULE_LIST,
        ];
    },
    moduleTypes: {
        CAMPAIGN_ACTIONS: 'campaign_actions',
        CAMPAIGNS: 'campaigns',
        CONTACT_LIST: 'contactlist',
        LOCATIONS: 'locations',
        PDF_LIST: 'pdflist',
        PLAIN_LIST: 'plainlist',
        SCHEDULE_LIST: 'schedulelist',
    },
};

$(function () {
    site.init();

    $.fn.modal.Constructor.prototype.enforceFocus = function () {
        $(document)
            .off('focusin.bs.modal') // guard against infinite focus loop
            .on(
                'focusin.bs.modal',
                $.proxy(function (e) {
                    if (
                        this.$element[0] !== e.target &&
                        !this.$element.has(e.target).length &&
                        // CKEditor compatibility fix start.
                        !$(e.target).closest('.cke_dialog, .cke').length
                        // CKEditor compatibility fix end.
                    ) {
                        this.$element.trigger('focus');
                    }
                }, this)
            );
    };

    // Fix padding added when modal is closed
    $('body').on('hide.bs.modal', function () {
        $(this).css('padding-right', '0');
    });

    $('a[data-open-link]').on('click', function () {
        // Ensure that some links cannot be pressed twice (assume).
        var href = $(this).attr('href');
        if (href) {
            site.disableButtons();
            window.location.href = href;
        }
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
    });
});

window.modalStack = modalStack;
window.site = site;
