var CUSTOM_EVENTS = {
    CAMPAIGN_ACTIONS_RECORD_SAVED: 'campaign_actions:record_saved',
    VOTING_QUESTIONS_REFRESH: 'voting:questions_refresh',
    VOTING_QUESTION_ITEMS_REFRESH: 'voting:question_items_refresh',
    KNOW_WHO_CATEGORIES_SAVE: 'know_who:categories_save',
    MODULE_FIELDS_ADD: 'module_fields:add',
    DATA_SOURCE_FIELD_ATTRIBUTES_SAVE: 'data_source_field_attributes_save',
    DATA_SOURCE_FIELD_VALUES_SAVE: 'data_source_field_values_save'
};

window.CUSTOM_EVENTS = CUSTOM_EVENTS;
