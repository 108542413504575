var StripePayment = (function ($, Promise) {
    var StripePayment = function (apiKey, paymentUrl) {
        return {
            submit: function (paymentData) {
                var number = paymentData.card_number || null;
                var expMonth = paymentData.expiry_month || null;
                var expYear = paymentData.expiry_year || null;
                var cvc = paymentData.card_cvd || null;
                var name = paymentData.card_owner || null;

                var amount = paymentData.amount || null;
                var email = paymentData.email || null;
                var phone = paymentData.phone || null;

                var ajaxOptions = {
                    url: 'https://api.stripe.com/v1/tokens',
                    type: 'POST',
                    dataType: 'json',
                    contentType: 'application/x-www-form-urlencoded',
                    data: {
                        card: {
                            number: number,
                            exp_month: expMonth,
                            exp_year: expYear,
                            cvc: cvc,
                            name: name,
                        },
                    },
                    headers: {
                        Authorization: 'Bearer ' + apiKey,
                    },
                };

                var transactionData = {
                    amount: amount,
                    name: name,
                    email: email,
                    phone: phone,
                    source: 'web',
                };

                var transactionAjaxOptions = {
                    url: paymentUrl,
                    type: 'POST',
                    dataType: 'json',
                    contentType: 'application/json',
                };

                return $.ajax(ajaxOptions)
                    .then(
                        function (response) {
                            return response.id;
                        },
                        function (error) {
                            var message = error.responseJSON.error.message;
                            transactionData.notes = message;
                            transactionData.approved = false;

                            transactionAjaxOptions.data = JSON.stringify(transactionData);

                            $.ajax(transactionAjaxOptions);
                            alert(message);
                            return Promise.reject(error);
                        }
                    )
                    .then(function (token) {
                        transactionData.token = token;

                        transactionAjaxOptions.data = JSON.stringify(transactionData);
                        return $.ajax(transactionAjaxOptions);
                    })
                    .then(function (response) {
                        if (response.success) {
                            var redirectUrl = response.data.redirect_url;
                            if (redirectUrl) {
                                window.location.href = redirectUrl;
                            }
                        } else {
                            alert(response.error_message ?? response.message ?? "There was an error communicating with the backend.");
                        }
                    });
            },
        };
    };

    return StripePayment;
})(jQuery, Promise);

var PaymentForm = (function ($, moment, Promise, StripePayment) {
    var baseUrl = '/web-payments/';

    var PaymentForm = function ($form, companyDbName) {
        this.$form = $form;
        this.$purchaseBtn = $form.find('#submit_btn');

        this.productId = $form.find('#product_id').val();
        this.totalAmount = $form.find('#amount').val().replace(",", "");
        this.currency = $form.find('#currency').val();

        this.companyDbName = companyDbName;
    };

    PaymentForm.validateProductChange = function (product, totalAmount, currency) {
        if (product) {
            if (totalAmount === product.total_amount && currency === product.currency) {
                return true;
            }
        }

        return false;
    };

    PaymentForm.validateProducts = function (companyDbName, productId, totalAmount, currency) {
        var validateUrl =
            baseUrl +
            companyDbName +
            '?product_info[0][product_id]=' +
            productId +
            '&product_info[0][quantity]=1';

        return $.ajax({
            url: validateUrl,
            type: 'GET',
            dataType: 'json',
            contentType: 'application/json',
        }).then(
            function (response) {
                var productsMap = response.products_map;
                var product = productsMap[productId];

                if (!PaymentForm.validateProductChange(product, totalAmount, currency)) {
                    var message = 'Products have changed.';
                    alert(message);
                    window.location.reload(true);
                    return Promise.reject(new Error(message));
                }

                return {
                    productId: productId,
                    totalAmount: totalAmount,
                    currency: currency,
                    provider: response.provider,
                    product: product,
                };
            },
            function (error) {
                console.log(error);
                alert(error);
            }
        );
    };

    PaymentForm.prototype.handleCreateTransactionSuccessStripe = function (
        paymentUrl,
        paymentData,
        apiKey
    ) {
        var stripePayment = new StripePayment(apiKey, paymentUrl);

        return stripePayment.submit(paymentData);
    };

    PaymentForm.prototype.createTransaction = function (
        provider,
        productId,
        productDescription,
        currency,
        totalAmount,
        paymentData
    ) {
        var self = this;
        var data = $.extend(
            {},
            {
                currency: currency,
                product_info: [
                    {
                        product_id: productId,
                        quantity: 1,
                        description: productDescription,
                    },
                ],
                provider_id: provider._id,
                total_amount: totalAmount,
                datetime: moment().utc().format(),
                name: paymentData.card_owner,
                phone: paymentData.phone,
                email: paymentData.email,
                source: 'web',
            }
        );

        // Create the transaction
        return $.ajax({
            url: baseUrl + self.companyDbName,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data),
        }).then(
            function (response) {
                if (response.success) {
                    var paymentUrl = response.data.payment_url;

                    switch (provider.type) {
                        case 'stripe':
                            var apiKey = provider.configuration.publishable_key;
                            return self.handleCreateTransactionSuccessStripe(
                                paymentUrl,
                                paymentData,
                                apiKey
                            );

                        default:
                            return Promise.reject(new Error('Missing provider type'));
                    }
                } else {
                    alert(response.error_message ?? response.message ?? "There was an error communicating with the backend.");
                }
            },
            function (error) {
                alert(error);
            }
        );
    };

    PaymentForm.prototype.submitPaymentForm = function (productId, totalAmount, currency) {
        var self = this;

        return PaymentForm.validateProducts(
            self.companyDbName,
            productId,
            totalAmount,
            currency
        ).then(function (response) {
            var $form = self.$form;

            var $number = $form.find('#card_number');
            var $expMonth = $form.find('#expiry_month');
            var $expYear = $form.find('#expiry_year');
            var $name = $form.find('#card_owner');
            var $email = $form.find('#email');
            var $cvc = $form.find('#card_cvd');

            var valid = true;
            var message = '';

            $.each([$name, $number, $expMonth, $expYear, $cvc, $email], function (i, $el) {
                if (!$el.val()) {
                    valid = false;
                    message = $('[for="' + $el.attr('id') + '"]').text() + ' must not be blank';
                    return false;
                }
            });

            if (!valid) {
                alert(message);
                return Promise.reject("validation");
            }

            var paymentData = {};
            $form.serializeArray().map(function (x) {
                paymentData[x.name] = x.value;
            });

            var provider = response.provider;
            var product = response.product;
            return self.createTransaction(
                provider,
                response.productId,
                product.description,
                response.currency,
                response.totalAmount,
                paymentData
            );
        });
    };

    PaymentForm.prototype.submit = function () {
        var self = this;
        self.$purchaseBtn.prop('disabled', true);
        this.submitPaymentForm(this.productId, this.totalAmount, this.currency).then(
            function () {
                self.$purchaseBtn.prop('disabled', false);
            },
            function () {
                self.$purchaseBtn.prop('disabled', false);
            }
        );
    };

    return PaymentForm;
})(jQuery, moment, Promise, StripePayment);

window.PaymentForm = PaymentForm;
