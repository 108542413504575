/**
 * DataGridTable
 * A wrapper around the DataTables jQuery library.
 */
(function ($, Cookies, SessionManager) {
    // The idea is to do a standard comparison after normalizing the terms.
    function compareCaseAccentInsensitive(a, b, inverse = false) {
        const aNormalized = normalizeString(a);
        const bNormalized = normalizeString(b);

        let result = 0;
        if (aNormalized < bNormalized) {
            result = -1;
        } else if (aNormalized > bNormalized) {
            result = 1;
        }

        // Pass inverse = true for descending order.
        if (inverse) {
            result = result * -1;
        }

        return result;
    }

    // Prepare a string for search/sort, return input if it's not a string.
    function normalizeString(s) {
        return typeof s !== 'string' ? s : s
            .normalize('NFD') // Decompose diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
            .toLowerCase();
    }


    /**
     * Create an instance of a DataTable. Defaults can be
     * overwritten by passing your own options object with
     * the properties to overwrite.
     *
     * @param {Object} options - Any set of options that DataTable uses
     * @returns {Object} - The DataTable instance
     */
    $.fn.DataGridTable = function (options, columns) {
        let $table = $(this);
        let dataTable;
        let cookiePath = window.location.href;
        let cookieExpiry = 2 / 24;

        // Restore the dataTables 1 behavior where clicking the column header only changes between ascending and descending order.
        $.fn.dataTable.defaults.column.orderSequence = ['asc', 'desc'];

        // Register a custom sort function to enable case and diacritic/accent-insensitive sorting.
        $.fn.dataTable.ext.type.order['case-accent-insensitive-asc'] = compareCaseAccentInsensitive;
        $.fn.dataTable.ext.type.order['case-accent-insensitive-desc'] = (a, b) => compareCaseAccentInsensitive(a, b, true);

        var initializeDataTable = function ($table, options, columns) {
            let tableId = $table.attr('id');
            let columnDefs = [];

            // Resize columns with buttons
            $table.find('tr:first-child td').each(function (i) {
                if ($(this).find('.btn').length > 0) {
                    columnDefs.push({
                        width: '10px',
                        targets: i
                    });
                }
            });

            let defaults = {
                bAutoWidth: false,
                columnDefs: columnDefs,
                initComplete: function (settings, json) {
                    // Remove the label that dataTables 2 puts in front of the searchbox and replace it with a placeholder in the input
                    const $wrapper = $table.closest('.dt-container');
                    $wrapper.find('.dt-search > label').remove();
                    $wrapper.find('.dt-search > input').attr('placeholder', 'Search');

                    const $searchBox = $wrapper.find('.dt-search input[type="search"]');

                    // Search box: remove default listeners to disable builtin default search
                    // and add a listener with a custom filtering function (case/accent insensitive).
                    $searchBox.off().on('keyup', function () {
                        const searchTerm = normalizeString($(this).val());
                        dataTable.search((d) => normalizeString(d).includes(searchTerm)).draw();
                    });

                    $table.removeClass('my_soda_table_hidden');
                    $table.find('tbody').show();
                    $table.closest('.soda-grid-container').find('.list-view-toogle').show();
                    $table.closest('.soda-grid-container').find('.soda-spinner').remove();

                    if (options.treegrid && 'treeColumn' in options.treegrid) {
                        $table.on('order.dt', () => ensureChildrenFollowParent($table));
                        $table.treegrid({
                            ...options.treegrid,
                            onCollapse: () => ensureChildrenFollowParent($table),
                            onExpand: () => ensureChildrenFollowParent($table),
                        });

                        setTreeInitialState(tableId);
                        ensureChildrenFollowParent($table);
                    }

                    if (columns) {
                        new ColumnSettingsDropdown(tableId, columns);
                    }

                    // Do the filtering last. It should operate after the tree is initialized.
                    setFilterInitialState(tableId);
                },
                language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'Search...'
                },
                lengthChange: false,
                lengthMenu: [
                    [25, 100, 250],
                    [25, 100, 250],
                ],
                paging: false,
                order: []
            };

            if ($.fn.DataTable.isDataTable($table)) {
                console.warn('DataGridTable already exists: ' + tableId);
                return $table.DataTable();
            } else {
                // Note: the merge is shallow(!) but passing $.extend(true,...) causes issues.
                let mergedOptions = $.extend({}, defaults, options);

                // Default all columns to use the custom sort.
                mergedOptions.columnDefs.unshift({
                    type: 'case-accent-insensitive',
                    targets: '_all'
                });

                return $table.DataTable(mergedOptions);
            }
        };

        // This is a fix for bug 49555 which seems due to a treegrid bug.
        function ensureChildrenFollowParent($table) {
            const $allChildRows = $table.find('.treegrid-ischild');
            const $parentRows = $table.find('tr').filter(function () {
                return $(this).hasClass('is_parent_Y');
            });

            $parentRows.each(function() {
                if (!$(this).hasClass('treegrid-expanded')) {
                    // The node isn't expanded, ignore.
                    return;
                }

                // The row following the parent row should be one of its children. If it's not:
                // - Find and detach children
                // - Insert them after the parent

                const parentModuleId = $(this).data('key');

                $childRows = $allChildRows.filter(function () {
                    return $(this).hasClass(`treegrid-parent-${parentModuleId}`);
                });

                if (!$childRows.length) {
                    return;
                }

                // This node has children. Are they following right after their parent?
                $rowFollowingParent = $(this).next();
                if (!$rowFollowingParent.hasClass(`treegrid-parent-${parentModuleId}`)) {
                    // One or more unrelated rows are following the parent, move the children there.
                    $childRows.detach();
                    $(this).after($childRows);
                }
            });
        }

        var setTreeInitialState = function (tableId) {
            let cookieKey = tableId + '_tree';
            let storedValue = Cookies.get(cookieKey);
            let storedData = storedValue ? JSON.parse(storedValue) : {};

            if (storedData) {
                for (var key in storedData) {
                    if (!key) {
                        continue;
                    }

                    if (!storedData[key].expanded) {
                        // Default is expand - collapse if collapsed previously
                        $table.find('[data-key="' + key + '"]').find('.treegrid-expander').click();
                    }
                }
            }

            $table.find('.treegrid-expanded .treegrid-expander').on('click', function () {
                handleTreegridChange($(this));
            });

            $table.find('.treegrid-collapsed .treegrid-expander').on('click', function () {
                handleTreegridChange($(this));
            });

            function handleTreegridChange($toggle) {
                let $tr = $toggle.closest('tr');
                let key = $tr.attr('data-key');

                if (key) {
                    storedData[key] = storedData[key] || {};
                    storedData[key].expanded = $tr.hasClass('treegrid-expanded');

                    Cookies.set(cookieKey, storedData, {
                        expires: cookieExpiry,
                        path: cookiePath
                    });
                }
            }
        }

        var setFilterInitialState = function (tableId) {
            var key = tableId + '_search';

            var $filter = $('#' + tableId + '_filter');
            if ($filter.length) {
                var $input = $filter.find('input');
                $input.attr('placeholder', 'Search');
                $filter.find('label').get(0).firstChild.nodeValue = "";

                var storedData = sessionStorage.getItem(key);
                if (storedData) {
                    $input.val(storedData).trigger('input');
                }

                $input.on('input', function () {
                    sessionStorage.setItem(key, $(this).val());
                });
            }
        };

        dataTable = initializeDataTable($table, options, columns);
        return dataTable;
    };
})(jQuery, Cookies, SessionManager);
