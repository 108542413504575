function StringUtils () {};

/**
 * Formats a string with {n} placeholders with a list of optional parameter values
 * https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
 *
 * @param {string} string
 * @returns {string}
 */
StringUtils.format = function (string) {
    var args = Array.prototype.slice.call(arguments, 1);
    return string.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
    });
};

StringUtils.isBlank = function (string) {
    return string === null || string.trim() === '';
}

window.StringUtils = StringUtils;
