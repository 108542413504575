/**
 * This file is part of Mfa handling. See documentation in MfaInterface.php.
 */

const getTotpFunctions = function (customRequestBody, $__mfaDataFormGroup, $hiddenContextField) {
    return {
        // Adds a click handler to the 'Get Backup Codes' button and gets/displays info about remaining backup codes.
        totpPrepareReissue() {
            const $reissue = $__mfaDataFormGroup.find(Form.getFieldKeyLookup('#__mfa_data.reissue'));
            const $remainingBackupCodesInfo = $reissue.find('#totp_remaining_backup_codes_info');
            const $requestBackupCodesButton = $('#topt_request_backup_codes');
            const $reissueResponse = $__mfaDataFormGroup.find(Form.getFieldKeyLookup('#__mfa_data.reissue_response'));
            const $totpBackupCodes = $reissueResponse.find('.totp_backup_codes');
            const $copyBackupCodesButton = $reissueResponse.find('.copy_backup_codes_button');
            site.attachCopyToClipboardHandler($copyBackupCodesButton, $totpBackupCodes);

            if (!$requestBackupCodesButton.data('prepared')) {
                // Add the click handler on the 'Get Backup Codes' button.
                $requestBackupCodesButton
                    .click(() => {
                        // Request backup codes.
                        customRequestBody.custom_request.request_type = 'totp_issue_backup_codes';
                        $.post('/data/dynamic-data/', customRequestBody)
                            .then((response) => {
                                const { success, error } = response;
                                if (success) {
                                    $totpBackupCodes.html(response.data.totp_backup_codes.join(', '));
                                    $hiddenContextField.val('reissue_response').trigger('change');
                                } else {
                                    console.warn(error ?? 'An unknown error occurred');
                                }
                            })
                            .catch(console.error);
                    })
                    .data('prepared', true);

                // Request and show info about remaining backup codes.
                customRequestBody.custom_request.request_type = 'totp_get_remaining_backup_codes_info';
                $.post('/data/dynamic-data/', customRequestBody)
                    .then((response) => {
                        const { success, error } = response;
                        if (success) {
                            const remaining = response.data.totp_remaining_backup_codes;
                            if (remaining === null) {
                                $remainingBackupCodesInfo.html(`<span class="text-danger">Unable to retrieve the number of remaining backup codes.</span>`);
                                return;
                            }

                            // Color code the message if there are fewer codes left than the set thresholds.
                            let className = '';
                            if (remaining <= 1) {
                                className = 'text-danger';
                            } else if (remaining <= 3) {
                                className = 'text-orange';
                            }

                            $remainingBackupCodesInfo
                                .html(`You have ${remaining} backup code${remaining !== 1 ? 's' : ''} left.`)
                                .addClass(className);

                        } else {
                            console.warn(error ?? 'An unknown error occurred');
                        }
                    })
                    .catch(console.error);
            }
        },
        // Requests initialization for TOTP and displays the newly generated credentials.
        totpInit() {
            const $totpKey = $__mfaDataFormGroup.find(Form.getFieldKeyLookup('#__mfa_data.init.totp_key'));
            const $totpKeyQrCode = $__mfaDataFormGroup.find(Form.getFieldKeyLookup('#__mfa_data.init.totp_key_qrcode'));
            const $totpBackupCodesInfo = $__mfaDataFormGroup.find(Form.getFieldKeyLookup('#__mfa_data.init.totp_backup_codes_info'));
            const $totpBackupCodes = $totpBackupCodesInfo.find('.totp_backup_codes');
            const $copyBackupCodesButton = $totpBackupCodesInfo.find('.copy_backup_codes_button');
            site.attachCopyToClipboardHandler($copyBackupCodesButton, $totpBackupCodes);

            // Request initialization (key, qrcode, backup codes).
            customRequestBody.custom_request.request_type = 'totp_generate_credentials';
            $.post('/data/dynamic-data/', customRequestBody)
                .then((response) => {
                    const { success, error, data } = response;
                    if (success && data) {
                        const {totp_key, totp_key_qrcode, totp_backup_codes} = data;
                        $totpKey.val(totp_key);
                        $totpKeyQrCode.attr('src', totp_key_qrcode);
                        $totpBackupCodes.html(totp_backup_codes.join(', '));
                    } else {
                        console.warn(error ?? 'An unknown error occurred');
                        $__mfaDataFormGroup.find(Form.getFieldKeyLookup('#__mfa_data.init')).html(
                            '<div class="text-danger">We were unable to generate Authenticator credentials.</div>'
                        );
                    }
                })
                .catch(console.error);
        },
    };
};

/**
 * This exposes a single method, initFields, and is called from the views (user_create_edit.blade.php)
 * in the Backend and TenantCompany UserControllers.
 */
const MfaConfig = (function(){
    let capturePhaseListenerAdded = false;
    let userRecord;
    let editToken;
    let isBackendUser;
    let $__mfaDataFormGroup;
    let $hiddenContextField;

    function mfaMethodShowConfig(newlySelectedMfaMethodKey) {
        // This object is the skeleton of a dynamic data request to the MfaHelper
        // and will be shared with all the Mfa method functions.
        const customRequestBody = {
            data_type: 'custom',
            custom_request_target_helper: 'MfaHelper',
            custom_request: {
                user_id: userRecord._id,
                is_backend_user: isBackendUser,
                token: editToken,
                request_type: null, // Mfa method functions need to set this when making the request.
            },
        };

        switch (newlySelectedMfaMethodKey) {
            case 'totp':
                const { totpPrepareReissue, totpInit } = getTotpFunctions(customRequestBody, $__mfaDataFormGroup, $hiddenContextField);
                if (newlySelectedMfaMethodKey === userRecord.mfa_method) {
                    // Totp is currently enabled for the user; show the 'Get Backup Codes' view.
                    $hiddenContextField.val('reissue');
                    totpPrepareReissue();
                } else {
                    // Totp is not currently enabled for the user; show the init view which triggers generating of credentials.
                    $hiddenContextField.val('init');
                    totpInit();
                }

                break;
        }
    }

    function handleMfaMethodChange() {
        const newlySelectedMfaMethodKey = $('#mfa_method').val();

        if (newlySelectedMfaMethodKey !== '__none') {
            mfaMethodShowConfig(newlySelectedMfaMethodKey);
        }
    }

    return {
        initFields(record, sessionUserId, sessionUserIsBackendUser) {
            // Store these in the closure.
            userRecord = record;
            isBackendUser = sessionUserIsBackendUser;
            $hiddenContextField = $(Form.getFieldKeyLookup('#__mfa_data.context'));
            $__mfaDataFormGroup = $('#__mfa_data_form_group');

            if (!$__mfaDataFormGroup.length) {
                // Fields not found.
                return false;
            }

            if (sessionUserId !== record._id) {
                // The user is editing a record that's not their own; remove the config fields for privacy reasons.
                $__mfaDataFormGroup.remove();
                $('#mfa_method option:not([value="__none"])').prop('disabled', true);
                $('#mfa_method').removeClass('required');
                return true;
            }

            // Hide the form group around the context field; it will never be shown.
            $hiddenContextField.parent().hide();

            // Hide the entire container, then trigger an mfa_method field change to decide whether it needs to be shown
            // based on the current selection and the session user.
            $__mfaDataFormGroup.hide();
            $('#mfa_method').trigger('change');

            // If there's currently an MFA method enabled switch to the reissue view.
            if (userRecord.mfa_method && userRecord.mfa_method !== '__none') {
                $hiddenContextField.val('reissue').trigger('change');
            }

            // Store a token with the current time; this is used to avoid multiple init requests
            // to the API in the event that the user switches the mfa_method field several times before
            // saving the record.
            editToken = Date.now();

            // We want to remove the credentials fields before submitting the form to the server
            // for security reasons; so bind a listener in the capturing phase that will get executed before
            // the jQuery listener processing the saving of the form.
            if (!capturePhaseListenerAdded) {
                document.querySelector('.btn-footeraction').addEventListener(
                    'click',
                    function (event) {
                        $('#__mfa_data_form_group').remove();
                    },
                    true // The `true` argument here uses capturing instead of bubbling
                );
                capturePhaseListenerAdded = true;
            }

            $('#mfa_method').on('change', handleMfaMethodChange);
            handleMfaMethodChange();

        },
    };
})()

window.MfaConfig = MfaConfig;
