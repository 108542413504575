var AjaxContainer = (function ($) {

    var spinnerHtml = '<div class="form_spinner" style="display: block; height: 100px;">'
            + '<i class="fas fa-spinner fa-spin fa-1x fa-fw"></i>'
            + '<span class="sr-only">Loading...</span>'
            + '</div>';

    var AjaxContainer = function (ajaxOptions, render, updatedEvent) {
        var self = this;

        self.ajaxOptions = ajaxOptions;
        self.$root = $('<div class="ajax_container">');
        self.render = render;

        if (updatedEvent) {
            self.$root.on(updatedEvent, function (e, response) {
                console.log(updatedEvent + " Triggered");
                self.refresh(ajaxOptions);
            });
        }

        self.refresh(ajaxOptions);
    };

    AjaxContainer.prototype.getRoot = function () {
        return this.$root;
    };

    AjaxContainer.prototype.refresh = function (ajaxOptions) {
        var self = this;
        var $root = self.$root;
        var render = self.render;

        $root.empty();
        $root.append(spinnerHtml);
        return $.ajax(ajaxOptions)
                .then(function (res) {
                    if (res.success) {
                        var $el = render(res);
                        return $root.empty().append($el);    
                    } else {
                        return Promise.reject(res);
                    }
                }).catch(res => {
                    const errMsg = res.error_message ?? res.message ?? "There was an error communicating with the backend.";
                    $root.html(`<div class="errorMsge">${errMsg}</div>`);
                });
    };

    return AjaxContainer;
})(jQuery);

window.AjaxContainer = AjaxContainer;
